import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-apollo-hooks";
import gql from "graphql-tag";
import SubHeaderText from "./SubHeaderText";
const PATHWAYS_BY_COURSE_QUERY = gql`
  query PATHWAYS_BY_COURSE_QUERY($codeUnique: String!) {
    pathways(
      orderBy: totalDuration_ASC
      where: { goalCourse: { codeUnique: $codeUnique } }
    ) {
      id
      slug
      goalCourse {
        id
        title
        codeUnique
        level
      }
      goalDuration
      steps {
        id
        course {
          id
          title
          codeUnique
          level
        }
        duration
      }
      guaranteedPathway
      VEPathway
    }
  }
`;

function PathwaysByCourse({
  codeUnique,
  component,
  courseLevel,
  coursePathwayLoadCallback,
  className,
  gpFilter,
  match,
}) {
  const { data, loading, error } = useQuery(PATHWAYS_BY_COURSE_QUERY, {
    variables: { codeUnique },
  });

  useEffect(() => {
    // If callback is undefined, exit
    if (!coursePathwayLoadCallback) {
      return;
    }
    // If no error, call the callback function with true, otherwise with false
    let success = false;
    if (!error && data.hasOwnProperty("pathways")) {
      success = true;
    }
    coursePathwayLoadCallback(success);
  });
  if (loading) return "Loading...";
  if (error) return `Error: ${error.message}`;
  const guaranteedPathways = data.pathways.filter(
    (pathway) => pathway.guaranteedPathway === true
  );
  const notGuaranteedPathways = data.pathways.filter(
    (pathway) => pathway.guaranteedPathway !== true
  );
  const Component = component;

  return (
    <>
      {gpFilter && guaranteedPathways.length <= 0 && (
        <>
          <SubHeaderText className="beta red">
            No guaranteed pathway options
          </SubHeaderText>
          <p>
            There are currently no guaranteed pathways available for this
            course. Use the filter above to explore other pathway options.
          </p>
        </>
      )}
      {gpFilter === false && notGuaranteedPathways.length <= 0 && (
        <>
          <SubHeaderText className="beta red">
            No other pathway options
          </SubHeaderText>
          <p>
            There are currently only guaranteed pathways available for this
            course. Use the filter above to explore guaranteed pathway options.
          </p>
        </>
      )}

      {data.pathways.map((pathway, index) => (
        <Component
          index={index}
          key={pathway.id}
          pathway={pathway}
          className={className + " gp-filter--" + pathway.guaranteedPathway}
          match={match}
        />
      ))}
    </>
  );
}

PathwaysByCourse.propTypes = {
  codeUnique: PropTypes.string.isRequired,
  component: PropTypes.func.isRequired,
};

export default PathwaysByCourse;
