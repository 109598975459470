import React from "react";
import { useMutation } from "react-apollo-hooks";

import { TOGGLE_FAQS_MUTATION } from "./FaqsModal";
import Button from "./Button";

function FaqsButton() {
  const toggleFaqs = useMutation(TOGGLE_FAQS_MUTATION, {
    variables: { faqsActive: true },
  });
  return (
    <Button type="button" className="white" onClick={toggleFaqs}>
      <span className="text visible-sm-down">FAQs</span>
      <span className="text visible-md-up">
        Need help understanding Pathways?
      </span>
    </Button>
  );
}

export default FaqsButton;
