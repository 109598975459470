import React from 'react';
import Button from './LinkAsButton';


function FaqsHomeButton() {
  return (
      <Button className="white" target="_blank" href="https://www.rmit.edu.au/contact">
        <span className="text">Contact RMIT</span>
      </Button>
  );
}

export default FaqsHomeButton;
