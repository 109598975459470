import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useMutation } from "react-apollo-hooks";
import gql from "graphql-tag";
import SVG from "react-inlinesvg";
import Tile from "./Tile";

const CURRENT_INTEREST_MUTATION = gql`
  mutation updateCurrentInterest($currentInterest: String!) {
    updateCurrentInterest(currentInterest: $currentInterest) @client
  }
`;

function InterestTile({ ...props }) {
  const updateCurrentInterest = useMutation(CURRENT_INTEREST_MUTATION);
  let history = useHistory();
  const handleClick = (e) => {
    e.preventDefault();
    updateCurrentInterest({
      variables: { currentInterest: props.interest.slug },
    });
    history.push("/study-level");
  };
  return (
    <Tile
      className={`tile--${props.type} tile--${props.color} ${
        props.currentInterest === props.interest.slug ? "active" : ""
      } ${props.className}`}
    >
      <a href="/study-level" onClick={(e) => handleClick(e)}>
        <SVG
          src={`${process.env.PUBLIC_URL}/icons/icon-${props.interest.slug}.svg`}
        />
        <span className="text">{props.interest.title}</span>
      </a>
    </Tile>
  );
}

InterestTile.propTypes = {
  interest: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  type: PropTypes.string,
  color: PropTypes.string,
  currentInterest: PropTypes.string,
};

InterestTile.defaultProps = {
  type: "",
  color: "",
  currentInterest: "",
};

export default InterestTile;
