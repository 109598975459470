import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import Tile from "./Tile";
import gql from "graphql-tag";
import { useQuery, useMutation } from "react-apollo-hooks";
const CURRENT_STUDY_LEVEL_QUERY = gql`
  query {
    currentStudyLevel @client
  }
`;

const CURRENT_STUDY_LEVEL_MUTATION = gql`
  mutation updateCurrentStudyLevel($currentStudyLevel: String!) {
    updateCurrentStudyLevel(currentStudyLevel: $currentStudyLevel) @client
  }
`;
function StudyLevelTile({ ...props }) {
  const url = props.interest ? `/interest/${props.interest}` : "/interests";
  const updateCurrentStudyLevel = useMutation(CURRENT_STUDY_LEVEL_MUTATION);
  const currentStudyLevelQuery = useQuery(CURRENT_STUDY_LEVEL_QUERY);
  return (
    <Tile
      className={`tile--${props.type} tile--${props.color} ${
        currentStudyLevelQuery.data.currentStudyLevel === props.studyLevel.slug
          ? "active"
          : ""
      } ${props.className}`}
    >
      <Link
        to={url}
        onClick={() =>
          updateCurrentStudyLevel({
            variables: { currentStudyLevel: props.studyLevel.slug },
          })
        }
      >
        <SVG
          src={`${process.env.PUBLIC_URL}/icons/icon-${props.studyLevel.slug}.svg`}
        />
        <span className="text">{props.studyLevel.title}</span>
      </Link>
    </Tile>
  );
}

StudyLevelTile.propTypes = {
  type: PropTypes.string,
  color: PropTypes.string,
  currentInterest: PropTypes.string,
  currentStudyLevel: PropTypes.string,
};

StudyLevelTile.defaultProps = {
  type: "",
  color: "",
  currentInterest: "",
  currentStudyLevel: "",
};

export default StudyLevelTile;
