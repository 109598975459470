import React from 'react';
import { useMutation } from 'react-apollo-hooks';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';

import { TOGGLE_SEARCH_MUTATION } from './SearchModal';
import Button from './Button';

const StyledIconWrap = styled.span`
  position: relative;
  top: 3px;
  padding-left: 10px;
  svg {
    * [id*='search'] {
      fill: ${props => props.theme.highlightRed};
    }
  }
`;

function SearchButton() {
  const toggleSearch = useMutation(TOGGLE_SEARCH_MUTATION, {
    variables: { searchActive: true }
  });
  return (
    <Button type="button" className="white" onClick={toggleSearch}>
      <span className="text visible-sm-down">Search</span>
      <span className="text visible-md-up">Search courses</span>
      <StyledIconWrap>
        <SVG src={process.env.PUBLIC_URL + '/icons/search.svg'} />
      </StyledIconWrap>
    </Button>
  );
}

export default SearchButton;
