import React from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';
import { Controller, Scene } from 'react-scrollmagic';

import SubHeaderText from './SubHeaderText';

const USER_TYPE_MUTATION = gql`
  mutation updateUserType($userType: String!) {
    updateUserType(userType: $userType) @client
  }
`;

const StyledHomeUserSelection = styled.div`
position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 50%;
    display: inline-block;
    background-image: linear-gradient(
      180deg,
      #ebfaff 0%,
      rgb(211, 244, 255, 0) 84%
    );
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    @media only screen and (min-width: 700px) {
      width: 50%;
      height: 100%;
    }
  }

`;
const StyledHomeUserSelectionBoxes = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 60px 20px 20px;
  position: relative;
  flex-direction: column;
  @media only screen and (min-width: 500px) {
	padding: 15vw 20px 10vw;
  }
  @media only screen and (min-width: 700px) {
    padding: 100px 0;
    flex-direction: row;
    justify-content: space-between;
  }
  @media only screen and (min-width: 900px) {
    padding: 150px 0;
    margin: 0 auto;
    max-width: none;
  }
  @media only screen and (min-width: 1200px) {
    padding: 150px 0 180px;
  }
  &.fade-down__items {
    &--in {
      .fade-down__item {
        opacity: 1;
        transform: translateY(0px);
      }
    }
  }
`;

const AnimFadeDown = styled.div`
  opacity: 0;
  transform: translateY(-25px);
  &.fade-down__item--1 {
    transition: all 400ms 100ms cubic-bezier(0.3, 0, 0.3, 1);
  }
  &.fade-down__item--2 {
    transition: all 400ms 200ms cubic-bezier(0.3, 0, 0.3, 1);
  }
`;

const UserSelectionBox = styled.div`
  position: relative;
  flex: 0 1 50%;
  @media only screen and (max-width: 699px) {
    padding: 0;
    &:first-child {
      padding: 0 0 60px;
    }
  }
`;

const UserSelectionBoxInner = styled.div`
  position: relative;
  max-width: 400px;
  margin: 0 auto;
  @media only screen and (min-width: 700px) {
	max-width: 340px;
	padding-right: 50px;
	padding-left: 20px;
  }
  @media only screen and (min-width: 900px) {
	max-width: 440px;
	padding-right: 50px;
	padding-left: 20px;
  }
  p {
    font-family: 'HelveticaNeue-Medium', Helvetica, Arial, sans-serif;
    font-weight: 300;
    line-height: 1.4;
	@media only screen and (min-width: 900px) {
      width: 300px;
    }
  }
  h2 {
    max-width: 280px;
    @media only screen and (max-width: 699px) {
      padding-top: 0.5em;
      padding-bottom: 0;
      margin-bottom: 0;
      &:before {
        top: -0.5em;
      }
    }
    @media only screen and (min-width: 900px) {
      max-width: none;
      width: 300px;
    }
    @media only screen and (min-width: 1200px) {
      width: 375px;
    }
  }
`;

const UserSelectionHeader = styled.div`
  @media only screen and (max-width: 699px) {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  @media only screen and (min-width: 700px) {
    padding-left: 0;
  }
`;

const StyledPathwaySVG = styled.div`
  width: 60px;
  flex: 0 0 60px;
  margin-right: 20px;
  @media only screen and (min-width: 700px) {
    width: 120px;
    margin-bottom: 30px;
  }
  @media only screen and (min-width: 900px) {
    width: 160px;
  }
  svg {
    width: 100%;
    height: auto;
  }
`;

function HomeUserSelection () {
	const updateUserType = useMutation(USER_TYPE_MUTATION);
	return (
		<StyledHomeUserSelection id="trigger__user-selection">
			<Controller>
				<Scene
					triggerElement={'#trigger__user-selection'}
					reverse={false}
					triggerHook={0}
					offset={-400}
					duration="100%"
				>
					{(progress, event) => (
						<StyledHomeUserSelectionBoxes
							className={[
								'fade-down__items',
								progress > 0 ? 'fade-down__items--in' : ''
							]}
						>
							<UserSelectionBox>
								<UserSelectionBoxInner>
									<UserSelectionHeader>
										<AnimFadeDown className="fade-down__item fade-down__item--1">
											<StyledPathwaySVG>
												<SVG
													src={`${process.env.PUBLIC_URL}/icons/pathways-blue.svg`}
												/>
											</StyledPathwaySVG>
										</AnimFadeDown>
										<AnimFadeDown className="fade-down__item fade-down__item--2">
											<SubHeaderText className="gamma gamma--home red">
												Pathways for high school students
                      </SubHeaderText>
										</AnimFadeDown>
									</UserSelectionHeader>
									<AnimFadeDown className="fade-down__item fade-down__item--2">
										<p className="delta">
                    Even if you miss the mark on your ATAR, you still have plenty of great options to reach the degree you want.
                    </p>
										<Link
											to="/interests"
											className="delta chevron-link"
											onClick={() =>
												updateUserType({
													variables: { userType: 'highschool' }
												})
											}
										>
											<SVG
												src={`${process.env.PUBLIC_URL}/icons/arrow-right.svg`}
											/>
											<span className="text">Find student pathways</span>
										</Link>
									</AnimFadeDown>
								</UserSelectionBoxInner>
							</UserSelectionBox>
							<UserSelectionBox>
								<AnimFadeDown className="fade-down__item fade-down__item--2">
									<UserSelectionBoxInner>
										<UserSelectionHeader>
											<AnimFadeDown className="fade-down__item fade-down__item--1">
												<StyledPathwaySVG className="purple">
													<SVG
														src={`${process.env.PUBLIC_URL}/icons/pathways-lavender.svg`}
													/>
												</StyledPathwaySVG>
											</AnimFadeDown>
											<AnimFadeDown className="fade-down__item fade-down__item--2">
												<SubHeaderText className="gamma gamma--home red">
													Pathways for adults &amp; mature age students
                        </SubHeaderText>
											</AnimFadeDown>
										</UserSelectionHeader>
										<AnimFadeDown className="fade-down__item fade-down__item--2">
											<p className="delta">
                      If you're looking for a career change or to gain extra qualifications, RMIT can help get you on the right path.
                      </p>
											<Link
												to="/interests"
												className="delta chevron-link"
												onClick={() =>
													updateUserType({ variables: { userType: 'mature' } })
												}
											>
												<SVG
													src={`${process.env.PUBLIC_URL}/icons/arrow-right.svg`}
												/>
												<span className="text">Find adults pathways</span>
											</Link>
										</AnimFadeDown>
									</UserSelectionBoxInner>
								</AnimFadeDown>
							</UserSelectionBox>
						</StyledHomeUserSelectionBoxes>
					)}
				</Scene>
			</Controller>
		</StyledHomeUserSelection>
	);
}

export default HomeUserSelection;
