import React from "react";
import SVG from "react-inlinesvg";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { useQuery } from "react-apollo-hooks";
import gql from "graphql-tag";
import Main from "../components/Main";
import HeaderText from "../components/HeaderText";
import ContentCard from "../components/ContentCard";
import TileWrap from "../components/TileWrap";
import StudyLevels from "../components/StudyLevels";
import StudyLevelTile from "../components/StudyLevelTile";
import SectionBreak from "../components/SectionBreak";
import StudyLevelModalButton from "../components/StudyLevelModalButton";

const StyledTileWrap = styled(TileWrap)`
  margin-bottom: 15px;
  @media only screen and (min-width: 900px) {
    max-width: 630px;
  }
`;

const CURRENT_INTEREST_QUERY = gql`
  query {
    currentInterest @client
  }
`;

function StudyLevelPage({ studyLevelTilesLoadCallback }) {
  const currentInterestQuery = useQuery(CURRENT_INTEREST_QUERY);
  const loading = false;

  return (
    <>
      {!loading && (
        <Helmet>
          <title>Study Level | RMIT Pathways</title>
          <meta
            name="description"
            content="Select a preferred level of study to view your course options and their pathways"
          />
        </Helmet>
      )}
      <Main id="study-level-page" gridArea="main" className="content-wrap">
        <HeaderText id="study-level-page-header-text">
          <span className="text no-icon">Select a study level.</span>
        </HeaderText>
        <SectionBreak id="study-level-page-section-break">
          <span>
            <SVG
              src={`${process.env.PUBLIC_URL}/icons/icon-step-study-level-nav-blue.svg`}
            />
          </span>
        </SectionBreak>
        <ContentCard id="study-level-page-content-card">
          <p id="study-level-page-content-p">
            Select a preferred level of study.
          </p>
          <StyledTileWrap>
            <StudyLevels
              component={StudyLevelTile}
              interest={currentInterestQuery.data.currentInterest}
              studyLevelTilesLoadCallback={studyLevelTilesLoadCallback}
            />
          </StyledTileWrap>
          <StudyLevelModalButton id="study-level-page-content-link">
            Learn more about study levels
          </StudyLevelModalButton>
        </ContentCard>
      </Main>
    </>
  );
}

export default StudyLevelPage;
