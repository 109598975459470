import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledItem = styled.div`
  border: none;
  box-shadow: none;

  a {
    border: none;
    box-shadow: none;
    padding: 18px 20px;
    font-size: 14px;
    position: relative;
    display: inline-block;
    border: 1px solid ${(props) => props.theme.highlightMidBlue};
    border-top: none;
    color: ${(props) => props.theme.textDark};
    width: 100%;
    line-height: 20px;
    &:hover {
      text-decoration: underline;
    }
    &:focus {
      outline: none;
      &:after {
        content: "";
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        position: absolute;
        top: -1px;
        left: -1px;
        border: 2px solid ${(props) => props.theme.highlightBlue};
      }
    }
    &:active {
      background: ${(props) => props.theme.highlightBlue};
      &:after {
        content: "";
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        position: absolute;
        top: -1px;
        left: -1px;
        border: 1px solid ${(props) => props.theme.highlightBlue};
      }
    }
  }
  &.active {
    a {
      background: ${(props) => props.theme.highlightBlue};
      pointer-events: none;
      &:after {
        content: "";
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        position: absolute;
        top: -1px;
        left: -1px;
        border: 1px solid ${(props) => props.theme.highlightBlue};
      }
    }
  }
`;

function CourseSidebarItem({ course, match, className }) {
  return (
    <StyledItem
      className={
        (match.params.codeUnique === course.codeUnique ? "active" : "") +
        " " +
        className
      }
    >
      <Link to={`/course/${course.codeUnique}`} className="omega">
        {course.title}
      </Link>
    </StyledItem>
  );
}

CourseSidebarItem.propTypes = {
  course: PropTypes.shape({
    codeUnique: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      codeUnique: PropTypes.string.isRequired,
    }),
  }), //.isRequired
};

export default CourseSidebarItem;
