import React, { useState } from "react";
import styled from "styled-components";
import SVG from "react-inlinesvg";

import { useMutation } from "react-apollo-hooks";
import {
  TOGGLE_FAQS_MUTATION,
  ACTIVE_QUESTION_IS_GP_MUTATION,
} from "./FaqsModal";

const StyledGPTooltipBtnWrapper = styled.div`
  position: relative;
  z-index: 20;
  left: -40px;
  @media only screen and (min-width: 700px) {
    left: 0;
  }
`;
const StyledGPTooltipBtn = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  color: white;
  width: 23px;
  padding: 0;
  @media only screen and (min-width: 700px) {
    color: ${(props) => props.theme.highlightRed};
    margin-left: 10px;
    &:hover {
      color: ${(props) => props.theme.darkRed};
    }
  }
`;

const StyledGPTooltipWrapper = styled.div`
  display: flex;
  flex: 1 0 100%;
  align-items: center;
  position: relative;
  @media only screen and (min-width: 700px) {
    flex: 1 0 auto;
  }
  &.right {
    padding: 0 5px;
    order: -1;
    margin-bottom: 10px;
    @media only screen and (min-width: 700px) {
      justify-content: flex-end;
      margin-bottom: 0px;
      order: 1;
    }
  }
  &.left {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;
const StyledGPLabel = styled.div`
  display: flex;
  flex: 1 0 100%;
  background: ${(props) => props.theme.highlightRed};
  width: 100%;
  border-radius: 15px;
  height: 50px;
  max-width: 335px;
  color: white;
  @media only screen and (min-width: 700px) {
    max-width: 223px;
  }
`;

const StyledGPTooltipBox = styled.div`
  position: absolute;
  width: 230px;
  color: ${(props) => props.theme.textPrimary};
  text-align: left;
  &.right {
    @media only screen and (max-width: 1799px) {
      bottom: 50px;
      right: -12px;
    }
    @media only screen and (min-width: 1800px) {
      bottom: -12px;
      left: calc(100% + 30px);
    }
  }
  &.left {
    @media only screen and (max-width: 699px) {
      bottom: 45px;
      right: -18px;
    }
    @media only screen and (min-width: 700px) {
      bottom: -12px;
      left: calc(100% + 25px);
    }
  }
`;
const StyledGPTooltipBoxInner = styled.div`
  position: relative;
  padding: 15px;
  border-radius: 10px;
  background: white;
  border: 2px solid ${(props) => props.theme.highlightRed};
  box-shadow: 0 2px 7px 0 #ebebeb;
  &:after {
    content: "";
    width: 18px;
    height: 18px;
    position: absolute;
  }
  &:before {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;
  }
  &.left {
    &:after {
      @media only screen and (max-width: 699px) {
        bottom: -10px;
        right: 12px;
        border-bottom: 2px solid ${(props) => props.theme.highlightRed};
        border-left: 2px solid ${(props) => props.theme.highlightRed};
        border-radius: 0 10px 0 4px;
        background: white;
        transform: rotate(-45deg);
      }
      @media only screen and (min-width: 700px) {
        bottom: 15px;
        left: -10px;
        border-bottom: 2px solid ${(props) => props.theme.highlightRed};
        border-left: 2px solid ${(props) => props.theme.highlightRed};
        border-radius: 0 10px 0 4px;
        background: white;
        transform: rotate(45deg);
      }
    }
  }
  &.right {
    &:after {
      @media only screen and (max-width: 1799px) {
        bottom: -10px;
        right: 12px;
        border-bottom: 2px solid ${(props) => props.theme.highlightRed};
        border-left: 2px solid ${(props) => props.theme.highlightRed};
        border-radius: 0 10px 0 4px;
        background: white;
        transform: rotate(-45deg);
      }
      @media only screen and (min-width: 1800px) {
        bottom: 15px;
        left: -10px;
        border-bottom: 2px solid ${(props) => props.theme.highlightRed};
        border-left: 2px solid ${(props) => props.theme.highlightRed};
        border-radius: 0 10px 0 4px;
        background: white;
        transform: rotate(45deg);
      }
    }
  }
`;
const StyledGPTooltipBoxText = styled.div`
  .button-link {
    background: transparent;
  }
  p {
    margin-bottom: 0;
  }
`;
const StyledGPTooltipBoxClose = styled.button`
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 40px;
  height: 40px;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.highlightRed};
  cursor: pointer;
  &:hover,
  &:focus {
    color: ${(props) => props.theme.highlightRedActive};
  }
`;

function GuaranteedPathwaysTooltip({ tooltipPosition }) {
  const [tooltipHidden, setTooltipHidden] = useState(true);
  const toggleFaqs = useMutation(TOGGLE_FAQS_MUTATION, {
    variables: { faqsActive: true },
  });
  const updateActiveQuestionIsGP = useMutation(ACTIVE_QUESTION_IS_GP_MUTATION, {
    variables: { activeQuestionIsGP: true },
  });
  return (
    <StyledGPTooltipWrapper className={tooltipPosition}>
      <StyledGPLabel>
        <SVG src={process.env.PUBLIC_URL + "/icons/icon-gp.svg"} />
      </StyledGPLabel>
      <StyledGPTooltipBtnWrapper>
        <StyledGPTooltipBtn onClick={() => setTooltipHidden(!tooltipHidden)}>
          <SVG src={process.env.PUBLIC_URL + "/icons/icon-info.svg"} />
        </StyledGPTooltipBtn>
        {!tooltipHidden && (
          <StyledGPTooltipBox className={tooltipPosition}>
            <StyledGPTooltipBoxInner className={tooltipPosition}>
              <StyledGPTooltipBoxText>
                <p classname="delta">
                  A{" "}
                  <button
                    onClick={() => {
                      toggleFaqs();
                      updateActiveQuestionIsGP();
                    }}
                    className="button-link"
                  >
                    Guaranteed Pathway
                  </button>{" "}
                  allows you to enrol in both a preliminary qualification and
                  your goal degree at the same time.
                </p>
              </StyledGPTooltipBoxText>
              <StyledGPTooltipBoxClose onClick={() => setTooltipHidden(true)}>
                <SVG src={process.env.PUBLIC_URL + "/icons/icon-close.svg"} />
              </StyledGPTooltipBoxClose>
            </StyledGPTooltipBoxInner>
          </StyledGPTooltipBox>
        )}
      </StyledGPTooltipBtnWrapper>
    </StyledGPTooltipWrapper>
  );
}

export default GuaranteedPathwaysTooltip;
