import styled from 'styled-components';

const PathwayDetailWrap = styled.div`
  position: relative;
  h2 {
    font-family: 'Museo700';
  }
  @media only screen and (min-width: 900px) {
    padding-right: 120px;
  }
`;

export default PathwayDetailWrap;
