import styled from 'styled-components';

const ShareButtonWrap = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  display: none;
  @media only screen and (min-width: 900px) {
    display: inline-block;
  }
`;

export default ShareButtonWrap;
