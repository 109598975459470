/**
 * This class handles animations of course page.
 *
 */
import anime from "animejs";

export default class courseAnimation {
  constructor(animationService) {
    this.animationService = animationService;
    this.coursePageId = "course-page";
  }

  _hideCoursePage() {
    this.animationService._hideItemById(this.coursePageId);
  }

  _showCoursePage() {
    this.animationService._showItemById(this.coursePageId);
  }

  _initCoursePageEnterAnimation() {
    // Set indexes based on their position in the animation queue.
    this.coursePageFirstEnterAnimIndex = 0;

    this.coursePageEnterAnimQueue = [
      {
        animObject: anime.timeline({
          easing: "cubicBezier(0.3, 0, 0.3, 1)",
        }),
        addAnimation: this._addCoursePageFirstAnimation.bind(this),
        readyFlags: {
          headers: false,
          other: false,
          pathways: false,
        },
        domReady: false,
        began: false,
        added: false,
      },
    ];
  }

  runCoursePageExitAnimation(match) {
    // Set this flag, so no entering animation is added anymore
    this.animationService.exitAnimationRunning = true;
    // Init enter animation object
    this.animationService.animationsByPath[match.path].initEnterAnimation(true);
    // Hide entering page
    this.animationService.animationsByPath[match.path].hidePage();
    this.coursePageExitAnime = anime.timeline({
      easing: "cubicBezier(0.3, 0, 0.3, 1)",
    });
    let courseSidebarItems = document.getElementsByClassName(
      "course-page-sidebar-item"
    );
    let targetPrefix = "";
    // If the initial URL has been changed by user by clicking on an item in the sidebar, element ids and classes will have an "exit-" prefix
    if (!courseSidebarItems.length) {
      targetPrefix = "exit-";
      courseSidebarItems = document.getElementsByClassName(
        `${targetPrefix}course-page-sidebar-item`
      );
    }

    // Build an array of elements for sidebar animation
    courseSidebarItems =
      this.animationService._getReverseArrayOfHTMLCollection(
        courseSidebarItems
      );
    let sidebarHeader = document.getElementById(
      `${targetPrefix}course-page-sidebar-header`
    );
    if (sidebarHeader) {
      courseSidebarItems.push(sidebarHeader);
    }

    // Build an array of elements for main content animation
    let courseContentTargetArray = [];
    let coursePathwayItems = document.getElementsByClassName(
      `${targetPrefix}course-page-pathway-item`
    );
    coursePathwayItems =
      this.animationService._getReverseArrayOfHTMLCollection(
        coursePathwayItems
      );
    let contentContact = document.getElementById(
      `${targetPrefix}course-page-content-contact`
    );
    let contentDisclaimer = document.getElementById(
      `${targetPrefix}course-page-content-disclaimer`
    );
    let contentGPFilter = document.getElementById(
      `${targetPrefix}course-page-content-gp-filter`
    );
    let contentGPNote = document.getElementById(
      `${targetPrefix}course-page-content-gp-notification`
    );
    let contentP = document.getElementById(
      `${targetPrefix}course-page-content-p`
    );
    let contentHeader = document.getElementById(
      `${targetPrefix}course-page-content-header`
    );
    if (contentContact) {
      courseContentTargetArray.push(contentContact);
    }
    if (contentDisclaimer) {
      courseContentTargetArray.push(contentDisclaimer);
    }
    courseContentTargetArray =
      courseContentTargetArray.concat(coursePathwayItems);
    if (contentGPFilter) {
      courseContentTargetArray.push(contentGPFilter);
    }
    if (contentGPNote) {
      courseContentTargetArray.push(contentGPNote);
    }
    if (contentP) {
      courseContentTargetArray.push(contentP);
    }
    if (contentHeader) {
      courseContentTargetArray.push(contentHeader);
    }

    this.coursePageExitAnime
      //Nav bar - unhighlight the item
      .add({
        targets: "#exit-vertical-nav-bar-course",
        backgroundColor: this.animationService.textDark,
        color: "#fff",
        duration: 300,
      })
      // Sidebar items
      .add(
        {
          targets: courseSidebarItems,
          translateY: [0, -25],
          opacity: [1, 0],
          duration: 300,
          delay: anime.stagger(100),
        },
        0
      )
      // Content items
      .add(
        {
          targets: courseContentTargetArray,
          translateY: [0, -25],
          opacity: [1, 0],
          duration: 300,
          delay: anime.stagger(100),
        },
        0
      )
      // Page header
      .add(
        {
          targets: `#${targetPrefix}course-page-header-text, #${targetPrefix}course-page-header-icon`,
          translateY: [0, -25],
          opacity: [1, 0],
          duration: 300,
        },
        "-=200"
      );
    // Run the new page's enter animation when exit is finished
    this.coursePageExitAnime.finished.then(() => {
      this.animationService.exitAnimationRunning = false;
      this.animationService.animationsByPath[match.path].runEnterAnimation(
        true
      );
    });
  }

  endCoursePageAnimation(node, done) {
    // // If animation object doesn't exist, call done and exit
    if (!this.coursePageExitAnime) {
      done();
      return;
    }
    // Call done when exit animation is finished
    this.coursePageExitAnime.finished.then(() => {
      done();
    });
  }

  runCoursePageEnterAnimation(pageTransition = false) {
    // If it's not a page transition (it's a page load), init the animation object because it hasen't been already.
    if (!pageTransition) {
      this._initCoursePageEnterAnimation();
    } else {
      this._showCoursePage();
    }
    this.animationService._tryToAddAnimation(
      this.coursePageEnterAnimQueue,
      this.coursePageFirstEnterAnimIndex
    );
  }

  _addCoursePageFirstAnimation() {
    this.coursePageEnterAnimQueue[this.coursePageFirstEnterAnimIndex].animObject
      // Page header comes in
      .add({
        targets: "#course-page-header-text, #course-page-header-icon",
        translateY: [-25, 0],
        opacity: [0, 1],
        duration: 300,
      })
      // Nav bar - button comes in
      .add(
        {
          targets: "#vertical-nav-bar-course",
          opacity: 1,
          duration: 300,
        },
        0
      )
      // Sidebar items come in
      .add(
        {
          targets: "#course-page-sidebar-header, .course-page-sidebar-item",
          translateY: [-25, 0],
          opacity: [0, 1],
          duration: 300,
          delay: anime.stagger(100),
        },
        100
      )
      // Pathway items come in
      .add(
        {
          targets:
            "#course-page-content-header, #course-page-content-p, #course-page-content-gp-notification, #course-page-content-gp-filter, .course-page-pathway-item," +
            " #course-page-content-disclaimer, #course-page-content-contact",
          translateY: [-25, 0],
          opacity: [0, 1],
          duration: 300,
          delay: anime.stagger(100),
        },
        100
      )
      // Nav bar - highlight the active item
      .add(
        {
          targets: ".nav-item--active",
          backgroundColor: this.animationService.themeHighlightBlue,
          color: this.animationService.textDark,
          duration: 300,
        },
        300
      );
  }

  addItemsToCoursePageEnterAnimation(success, itemKey) {
    if (!success) {
      return;
    }
    // Exit if the animation has already been added
    if (
      this.coursePageEnterAnimQueue[this.coursePageFirstEnterAnimIndex].added
    ) {
      return;
    }
    this.animationService._tryToSetDomReady(
      this.coursePageEnterAnimQueue[this.coursePageFirstEnterAnimIndex],
      itemKey
    );
    // If an exit animation is running, exit
    if (this.animationService.exitAnimationRunning) {
      return;
    }
    this.animationService._tryToAddAnimation(
      this.coursePageEnterAnimQueue,
      this.coursePageFirstEnterAnimIndex
    );
  }
}
