import React from 'react';
import { withRouter } from 'react-router-dom';

function ContentNotFound({history}) {
  history.push('/404');
  return (
    <></>
  );
}

export default withRouter(ContentNotFound);
