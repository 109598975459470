import React from "react";
import SVG from "react-inlinesvg";

function ContactInnerForm() {
  return (
    <div>
      <h3>Contact us online</h3>
      <p>Use our online chat or contact form to get in touch</p>
      <br />
      <a
        className="chevron-link"
        href="https://www.rmit.edu.au/contact/contact-us"
        target="_blank"
        rel="noopener noreferrer"
      >
        <SVG src={`${process.env.PUBLIC_URL}/icons/arrow-right.svg`} />
        <span className="text">Contact us online</span>
      </a>
    </div>
  );
}

export default ContactInnerForm;
