import styled from 'styled-components';

const PageButtonWrap = styled.div`
  text-align: center;
  margin: 0 -5px;
  z-index: 25;
  @media only screen and (max-width: 699px) {
    position: absolute;
    top: 90px;
    left: auto;
    right: auto;
    width: 100%;
    align-items: center;
    padding: 0 20px;
    margin: 0;
    > button {
      width: 100%;
      max-width: 340px;
      flex: 1 0 1px;
      margin: 5px 0;
    }
  }
  @media only screen and (min-width: 700px) and (max-width: 899px) {
    grid-area: nav;
    margin-top: 5px;
    margin-right: 0px;
    margin-left: auto;
    text-align: right;
    padding-left: 320px;
    padding-right: 5px;
    position: static;
    width: 100%;
    > button {
      width: 160px;
    }
  }
  @media only screen and (max-width: 899px) {
    z-index: 26;
  }
  @media only screen and (min-width: 900px) {
    text-align: left;
    margin: 0;
    position: absolute;
    top: 15px;
    right: 20px;
  }
  @media only screen and (min-width: 1400px) {
    top: 30px;
    right: 70px;
  }
`;

export default PageButtonWrap;
