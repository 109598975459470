import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import gql from "graphql-tag";
import SVG from "react-inlinesvg";
import NavWrap from "./styles/NavWrap";
import Logo from "./Logo";
import BackButton from "./BackButton";

const CURRENT_COURSE_QUERY = gql`
  query {
    currentCourse @client
  }
`;

const CURRENT_INTEREST_QUERY = gql`
  query {
    currentInterest @client
  }
`;

//
function setPrefixForId(match, itemPath, itemId) {
  if (match.path !== itemPath) {
    return "exit-" + itemId;
  }
  return itemId;
}

//const Nav = React.forwardRef((props, ref) => {
function Nav({ location, match }) {
  const currentCourseQuery = useQuery(CURRENT_COURSE_QUERY);
  const currentInterestQuery = useQuery(CURRENT_INTEREST_QUERY);
  let interestsId = "vertical-nav-bar-interests";
  let studyLevelId = "vertical-nav-bar-study-level";
  let itrsId = "vertical-nav-bar-itrs";
  let courseId = "vertical-nav-bar-course";
  let pathwayId = "vertical-nav-bar-pathway";
  interestsId = setPrefixForId(match, "/interests", interestsId);
  studyLevelId = setPrefixForId(match, "/study-level", studyLevelId);
  itrsId = setPrefixForId(match, "/interest/:slug", itrsId);
  courseId = setPrefixForId(match, "/course/:codeUnique", courseId);
  pathwayId = setPrefixForId(match, "/pathway/:slug", pathwayId);

  return (
    <NavWrap id="vertical-nav-bar">
      <Logo />
      <BackButton />
      <nav>
        <Link
          id={interestsId}
          to="/interests"
          className={`nav-item nav-item--interest ${
            match.path === "/interests"
              ? "nav-item--active"
              : "nav-item--disabled"
          }`}
        >
          <span className="nav-item__icon">
            <SVG
              src={process.env.PUBLIC_URL + "/icons/icon-step-1-nav-blue.svg"}
            />
          </span>
          <span className="nav-item__text">Interest area</span>
        </Link>
        <Link
          id={studyLevelId}
          to="/study-level"
          className={`nav-item nav-item--study-level ${
            match.path === "/study-level"
              ? "nav-item--active"
              : "nav-item--disabled"
          }`}
        >
          <span className="nav-item__icon">
            <SVG
              src={
                process.env.PUBLIC_URL +
                "/icons/icon-step-study-level-nav-blue.svg"
              }
            />
          </span>
          <span className="nav-item__text">Study Level</span>
        </Link>
        <Link
          id={itrsId}
          // This doesnt always pick up the current interest and I dont know why. Bug documented in Notion.
          to={
            currentInterestQuery.data.currentInterest
              ? `/interest/${currentInterestQuery.data.currentInterest}`
              : location.pathname
          }
          className={`nav-item nav-item--course-options ${
            match.path === "/interest/:slug"
              ? "nav-item--active"
              : "nav-item--disabled"
          }`}
        >
          <span className="nav-item__icon">
            <SVG
              src={process.env.PUBLIC_URL + "/icons/icon-step-2-nav-blue.svg"}
            />
          </span>
          <span className="nav-item__text">Course options</span>
        </Link>
        <Link
          id={courseId}
          to={
            currentCourseQuery.data.currentCourse
              ? `/course/${currentCourseQuery.data.currentCourse}`
              : location.pathname
          }
          className={`nav-item nav-item--pathways-options ${
            match.path === "/course/:codeUnique"
              ? "nav-item--active"
              : "nav-item--disabled"
          }`}
        >
          <span className="nav-item__icon">
            <SVG
              src={process.env.PUBLIC_URL + "/icons/icon-step-3-nav-blue.svg"}
            />
          </span>
          <span className="nav-item__text">Pathway options</span>
        </Link>
        <Link
          id={pathwayId}
          to={location.pathname}
          className={`nav-item nav-item--chosen-pathway ${
            match.path === "/pathway/:slug"
              ? "nav-item--active"
              : "nav-item--disabled"
          }`}
        >
          <span className="nav-item__icon">
            <SVG
              src={process.env.PUBLIC_URL + "/icons/icon-step-4-nav-blue.svg"}
            />
          </span>
          <span className="nav-item__text">Chosen pathway</span>
        </Link>
      </nav>
    </NavWrap>
  );
}

//export default withRouter(Nav);
export default Nav;
