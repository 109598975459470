/* eslint-disable quotes */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import SVG from "react-inlinesvg";

const StyledFaqsByUserType = styled.div`
  position: relative;
  box-shadow: 0 2px 7px 0 #ebebeb;
  margin-bottom: 25px;
`;

const FaqItem = styled.div`
  .faq-question {
    display: flex;
    padding: 20px 15px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border: none;
    background-color: white;
    text-align: left;
    cursor: pointer;
    border-top: 1px solid #d3f4ff;
    @media only screen and (min-width: 900px) {
      padding: 30px;
    }
    &:hover {
      h4 {
        text-decoration: underline;
      }
    }
    &:focus {
      outline: none;
      &:before {
      }
      h4 {
        text-decoration: underline;
      }
    }
    .q-letter {
      flex: 1 0 26px;
      margin-right: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      height: 26px;
      width: 26px;
      line-height: 16px;
      font-size: 16px;
      font-family: "Museo700", serif;
      color: ${(props) => props.theme.highlightBlue};
      background-color: ${(props) => props.theme.highlightLightBlue};
      @media only screen and (min-width: 900px) {
        flex: 1 0 48px;
        margin-right: 30px;
        padding: 10px;
        height: 48px;
        width: 48px;
        line-height: 26px;
        font-size: 26px;
      }
    }
    svg {
      path {
        fill: ${(props) => props.theme.highlightBlue};
      }
    }
    .svg-wrap {
      margin-left: 15px;
      svg {
        width: 20px;
      }
      @media only screen and (min-width: 900px) {
        margin-left: 30px;
        svg {
          width: 24px;
        }
      }
    }
    h4 {
      margin: 0;
      flex: 1 1 100%;
    }
  }
  .faq-answer {
    padding: 30px;
    display: none;
    width: 100%;
    background-color: ${(props) => props.theme.sidebarBg};
    box-shadow: inset 0 1px 0 0 ${(props) => props.theme.highlightLightBlue},
      inset 0 1px 3px 0 ${(props) => props.theme.highlightLightBlue};
    a {
      text-decoration: underline;
    }
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &.active {
    .svg-wrap {
      svg {
        transform: rotate(180deg);
      }
    }
    .faq-answer {
      display: block;
    }
    .faq-question {
      h4 {
        text-decoration: none;
      }
    }
  }
  &.currenttype--highschool {
    .faq-question {
      border-top: 1px solid #d3f4ff;
    }
    .q-letter {
      color: ${(props) => props.theme.highlightBlue};
      background-color: ${(props) => props.theme.highlightLightBlue};
    }
    svg {
      path {
        fill: ${(props) => props.theme.highlightBlue};
      }
    }
    .faq-answer {
      background-color: ${(props) => props.theme.sidebarBg};
      box-shadow: inset 0 1px 0 0 ${(props) => props.theme.highlightLightBlue},
        inset 0 1px 3px 0 ${(props) => props.theme.highlightLightBlue};
    }
  }
  &.currenttype--mature {
    .faq-question {
      border-top: 1px solid #f1d8f1;
    }
    .q-letter {
      color: ${(props) => props.theme.midPurple};
      background-color: #f1d8f1;
    }
    svg {
      path {
        fill: ${(props) => props.theme.midPurple};
      }
    }
    .faq-answer {
      background-color: #fcf7fc;
      box-shadow: inset 0 1px 0 0 #f1d8f1, inset 0 1px 3px 0 #f1d8f1;
    }
  }
  &.currenttype--advisor {
    .faq-question {
      border-top: 1px solid #f9bfc9;
    }
    .q-letter {
      color: ${(props) => props.theme.highlightRed};
      background-color: #f9bfc9;
    }
    svg {
      path {
        fill: ${(props) => props.theme.highlightRed};
      }
    }
    .faq-answer {
      background-color: #fdeff1;
      box-shadow: inset 0 1px 0 0 #f9bfc9, inset 0 1px 3px 0 #f9bfc9;
    }
  }

  &:first-of-type {
    .faq-question {
      border-top: none;
    }
  }
`;

function FaqsByUserType({ userType, activeQuestionIsGP }) {
  let gpQuestionIndex = 0;
  const [activeQuestion, setActiveQuestion] = useState(
    activeQuestionIsGP ? "answer-" + gpQuestionIndex : ""
  );

  useEffect(() => {
    setActiveQuestion(activeQuestionIsGP ? "answer-" + gpQuestionIndex : "");
  }, [userType, activeQuestionIsGP, gpQuestionIndex]);

  const faqs = [];
  switch (userType) {
    case "highschool":
      gpQuestionIndex = 0;
      faqs.unshift(
        {
          question: `What's the difference between Guaranteed Pathways and Other Pathways?`,
          answer: `<p>All RMIT pathways allow students to gain credit towards and entry into a higher level of study.</p><p>Guaranteed Pathways (or packaged pathways) allow you to enrol in both the initial qualification and the bachelor degree at the same time, securing your place in the bachelor degree. Guaranteed Pathways eliminate the need to apply for the bachelor degree after finishing the initial qualification.</p>`,
        },
        {
          question: "What is a pathway and how do they work?",
          answer:
            "<p>A pathway is an alternative way to get into a course. RMIT's flexible pathways can help you move through different levels of study to reach the qualification you're aiming for. Some courses provide guaranteed entry and credit, which can help you reduce the time it takes to complete your studies.</p>",
        },
        {
          question: "How much time will I save off my degree?",
          answer:
            "<p>This will depend on which path of study you choose, and if you qualify for any credit transfers. Most pathways will provide credit, reducing the time of the degree. Please check each pathway for more details.</p>",
        },
        {
          question:
            "Will I be guaranteed entry to my degree after completing a pathway?",
          answer:
            "<p>Some courses provide guaranteed entry and credit if you meet the requirements. This can help you to reduce the time it takes to complete your studies. Please check the course page for more details on entry requirements.</p>",
        },
        {
          question: "How do I apply for a pathway?",
          answer:
            '<p>To apply, visit the course page for the first course in the pathway and follow the <a href="https://www.rmit.edu.au/study-with-us/applying-to-rmit/local-student-applications/how-to-apply">application process</a>.</p><p>Note that you’ll need to apply for each course in a pathway as you progress along the journey.</p>',
        },
        {
          question: "How do I choose a pathway that’s right for me?",
          answer:
            '<p>Different pathways suit different people. You can explore your options  by study area by using our pathways tool. In the pathway details, you’ll be able to find entry requirements and see which courses you’re eligible to apply for.</p><p>If you need more information about pathways to tertiary study, we can help. <a href="https://www.rmit.edu.au/contact/online-enquiries/local-student-enquiries">Contact the team</a> for advice on what to study.</p>',
        },
        {
          question:
            "Are there ways I can increase my chance of getting into RMIT?",
          answer:
            "<p>The best way to increase your chances is to ensure you have the best grades possible. Other options include gaining relevant work experience, writing a personal statement, and meeting all the entry requirements. Please check the course page for more details.</p>",
        },
        {
          question:
            "What if I don’t meet the requirements for the first course in the pathway?",
          answer:
            "<p>RMIT tries to provide accessible pathways for most courses wherever possible. If you don’t meet the entry requirements of the first course, you can consider alternative courses or pathways.</p>",
        },
        {
          question:
            "What are the differences between certificates, diplomas and advanced diploma etc.",
          answer:
            "<p>Each one is a higher academic qualification. For example, when entering the workforce, you might need different levels of qualifications depending on the job you are applying for. The higher the level of qualification, the more detailed your knowledge and skills will be.</p>",
        },
        {
          question:
            "If I complete the first course in a pathway, does it automatically mean I’m guaranteed entry to the next?",
          answer:
            "<p>Once you have successfully completed the first course in a pathway, you’ll be able to enrol into the next one, provided that you meet all the entry requirements. Please check the pathway details to find out more about the specific entry requirements to progress through a pathway.</p>",
        },
        {
          question: "What happens if I don’t get to the end of my pathway?",
          answer:
            "<p>If you don't complete all the pathway steps to get to your desired degree, you'll still graduate with a recognised stand alone qualification from RMIT. This means that if you decide later to resume your studies, you won’t have to go through another application process and you’ll still leave with a good qualification.</p>",
        },
        {
          question: "Why do certain courses not have a pathway?",
          answer:
            "<p>Unfortunately, we cannot offer pathways into all courses. Each will have particular requirements, and some courses have strict entry requirements. We encourage you to expand your options and see what alternative courses are suitable for you.</p>",
        },
        {
          question: `How can I check if I can get into my degree with just my ATAR?`,
          answer: `<p>Our <a href="https://atarcoursefinder.rmit.edu.au/">ATAR finder</a> is a handy tool for checking your ATAR for specific programs or via broader study areas.</p>`,
        },
        {
          question: `I think I can get into my degree with my ATAR, why should I also apply for a pathway?`,
          answer: `<p>It can’t hurt. It’s worth having fallback options. The best time to consider your pathways is once you’ve received your ATAR, and then you can  update your course selections during Change of Preference.</p>`,
        },
        {
          question: `Do I still need an ATAR to apply for a pathway?`,
          answer: `<p>It depends on the specific course you’re considering. Vocational courses (TAFE) don’t require an ATAR, while all Associate Degrees do require an ATAR</p>`,
        }
      );
      break;
    case "mature":
      gpQuestionIndex = 0;
      faqs.unshift(
        {
          question: `What's the difference between Guaranteed Pathways and Other Pathways?`,
          answer: `<p>All RMIT pathways allow students to gain credit towards and entry into a higher level of study.</p><p>Guaranteed Pathways (or packaged pathways) allow you to enrol in both the initial qualification and the bachelor degree at the same time, securing your place in the bachelor degree. Guaranteed Pathways eliminate the need to apply for the bachelor degree after finishing the initial qualification.</p>`,
        },
        {
          question: "What is a pathway and how do they work?",
          answer:
            "<p>A pathway is an alternative way to get into a course. RMIT's flexible pathways can help you move through different levels of study to reach the qualification you're aiming for. Some courses provide guaranteed entry and credit, which can help you reduce the time it takes to complete your studies.</p>",
        },
        {
          question: "How much time will I save off my degree?",
          answer:
            "<p>This will depend on which path of study you choose, and if you qualify for any credit transfers. Most pathways will provide credit, reducing the time of the degree. Please check each pathway for more details.</p>",
        },
        {
          question:
            "Will I be guaranteed entry to my degree after completing a pathway?",
          answer:
            "<p>Some courses provide guaranteed entry and credit if you meet the requirements. This can help you to reduce the time it takes to complete your studies. Please check the course page for more details on entry requirements.</p>",
        },
        {
          question: "How do I apply for a pathway?",
          answer:
            '<p>To apply, visit the course page for the first course in the pathway and follow the <a href="https://www.rmit.edu.au/study-with-us/applying-to-rmit/local-student-applications/how-to-apply">application process</a>.</p><p>Note that you’ll need to apply for each course in a pathway as you progress along the journey.</p>',
        },
        {
          question: "How do I choose a pathway that’s right for me?",
          answer:
            '<p>Different pathways suit different people. You can explore your options  by study area by using our pathways tool. In the pathway details, you’ll be able to find entry requirements and see which courses you’re eligible to apply for.</p><p>If you need more information about pathways to tertiary study, we can help. <a href="https://www.rmit.edu.au/contact/online-enquiries/local-student-enquiries">Contact the team</a> for advice on what to study.</p>',
        },
        {
          question:
            "Are there ways I can increase my chance of getting into RMIT?",
          answer:
            "<p>The best way to increase your chances is to ensure you have the best grades possible. Other options include gaining relevant work experience, writing a personal statement, and meeting all the entry requirements. Please check the course page for more details.</p>",
        },
        {
          question:
            "What if I don’t meet the requirements for the first course in the pathway?",
          answer:
            "<p>RMIT tries to provide accessible pathways for most courses wherever possible. If you don’t meet the entry requirements of the first course, you can consider alternative courses or pathways.</p>",
        },
        {
          question:
            "What are the differences between certificates, diplomas and advanced diploma etc.",
          answer:
            "<p>Each one is a higher academic qualification. For example, when entering the workforce, you might need different levels of qualifications depending on the job you are applying for. The higher the level of qualification, the more detailed your knowledge and skills will be.</p>",
        },
        {
          question:
            "If I complete the first course in a pathway, does it automatically mean I’m guaranteed entry to the next?",
          answer:
            "<p>Once you have successfully completed the first course in a pathway, you’ll be able to enrol into the next one, provided that you meet all the entry requirements. Please check the pathway details to find out more about the specific entry requirements to progress through a pathway.</p>",
        },
        {
          question: "What happens if I don’t get to the end of my pathway?",
          answer:
            "<p>If you don't complete all the pathway steps to get to your desired degree, you'll still graduate with a recognised stand alone qualification from RMIT. This means that if you decide later to resume your studies, you won’t have to go through another application process and you’ll still leave with a good qualification.</p>",
        },
        {
          question: "Why do certain courses not have a pathway?",
          answer:
            "<p>Unfortunately, we cannot offer pathways into all courses. Each will have particular requirements, and some courses have strict entry requirements. We encourage you to expand your options and see what alternative courses are suitable for you.</p>",
        },
        {
          question: `How do I know if I'm considered a mature age student?`,
          answer: `<p>For undergraduate study, a mature age student is anyone who is 21 or older by the 1st of January in the year in which they are applying. For certificates and diplomas, it's anyone who is over 18 and has been out of school for more than 12 months.</p>
          <p>Mature age students follow the same application process as other applicants and still need to meet the minimum entry requirements to be considered.</p>
          <p>If you are a mature age student you should take a look at our <a href="https://www.rmit.edu.au/content/rmit-ui/en/study-with-us/applying-to-rmit/local-student-applications/equity-access-schemes.html" target="_blank">equity and access guidelines</a> to see if you're eligible for special consideration.</p>`,
        },
        {
          question: `How long will the pathway option take me?`,
          answer: `<p>The duration of your pathway will depend upon a few factors, including what course you are taking. This is determined on a case-by-case basis</p>`,
        },
        {
          question: `Why is there a 10 year cut off for study?`,
          answer: `<p>Formal learning that is more than 10 years old and has been deemed non-equivalent can be used in support of an RPL application for a vocational education program.</p>`,
        },
        {
          question: `Are there ways I can reduce the pathway time?`,
          answer: `<p>Generally speaking, the pathway duration cannot be reduced unless the units have been completed elsewhere.</p>`,
        },
        {
          question: `Can I use existing credits or work experience to apply for a pathway?`,
          answer: `<p>The amount of credits will vary depending on which course you are studying.</p><p>You can apply for credit to reduce the length and cost of your studies, and to ensure your learning is not duplicated. Find out more about <a href="https://www.rmit.edu.au/students/student-essentials/enrolment/apply-for-credit">applying for credit</a> at RMIT.</p>`,
        },
        {
          question: `I haven’t studied in a long time. Is there any support for fitting into university life and studying successfully?`,
          answer: `<p>If you’re transferring to RMIT or returning to study after a break, we understand that study may not be the only thing on your mind. That’s why we offer a range of support services to ensure you don’t lose momentum while working towards a degree.</p>`,
        }
      );
      break;
    case "advisor":
      gpQuestionIndex = 0;
      faqs.unshift(
        {
          question: `What's the difference between Guaranteed Pathways and Other Pathways?`,
          answer: `<p>All RMIT pathways allow students to gain credit towards and entry into a higher level of study.</p><p>Guaranteed Pathways (or packaged pathways) allow you to enrol in both the initial qualification and the bachelor degree at the same time, securing your place in the bachelor degree. Guaranteed Pathways eliminate the need to apply for the bachelor degree after finishing the initial qualification.</p>`,
        },
        {
          question: `Why should I encourage students to investigate pathways?`,
          answer: `<p>Pathways are a useful alternative if a student doesn’t meet the ATAR requirements for a course or study area that they’re interested in.</p><p>Students are more likely to consider pathways during Change of Preference, after they’ve received their ATAR.</p>`,
        },
        {
          question: `At what point in the year should students start looking into pathways?`,
          answer: `<p>It’s most helpful to consider a pathway during Change of Preference, after they have received their ATAR.</p>`,
        },
        {
          question: `What is a pathway and how do they work?`,
          answer: `<p>A pathway is an alternative way to get into a course. 
          RMIT's flexible pathways can help students move through different levels of study to reach the qualification they’re aiming for. Some courses provide guaranteed entry and credit, which can help students reduce the time it takes to complete your studies.</p>`,
        },
        {
          question: `How much time will a student save off their degree?`,
          answer: `<p>This will depend on which path of study they choose, and if they qualify for any credit transfers. Most pathways will provide credit, reducing the time of the degree. Please check each pathway for more details.</p>`,
        },
        {
          question: `Will a student be guaranteed entry to their degree after completing a pathway?`,
          answer: `<p>Some courses provide guaranteed entry and credit if they meet the requirements. This can help them to reduce the time it takes to complete their studies. Please check the course page for more details on entry requirements.</p>`,
        },
        {
          question: `How does a student apply for a pathway?`,
          answer: `<p>To apply, students must visit the course page for the first course in the pathway and follow the <a href="https://www.rmit.edu.au/study-with-us/applying-to-rmit/local-student-applications/how-to-apply">application process</a>.</p><p>Note that they’ll need to apply for each course in a pathway as they progress along the journey.</p>`,
        },
        {
          question: `How do students choose a pathway that’s right for them?`,
          answer: `<p>Different pathways suit different people. Students can explore their options by study area by using our pathways tool. In the pathway details, they’ll be able to find entry requirements and see which courses they’re eligible to apply for.</p><p>If you need more information about pathways to tertiary study, we can help. <a href="https://www.rmit.edu.au/contact/online-enquiries/local-student-enquiries">Contact the team</a> for advice.</p>`,
        },
        {
          question: `Are there ways students can increase their chance of getting into RMIT?`,
          answer: `<p>The best way to increase their chances is to ensure they have the best grades possible. Other options include gaining relevant work experience, writing a personal statement, and meeting all the entry requirements. Please check the course page for more details.</p>`,
        },
        {
          question: `What if a student doesn’t meet the requirements for the first course in the pathway?`,
          answer: `<p>RMIT tries to provide accessible pathways for most courses wherever possible. If a student doesn’t meet the entry requirements of the first course, they can consider alternative courses or pathways.</p>`,
        },
        {
          question: `What are the differences between certificates, diplomas and advanced diploma etc.`,
          answer: `<p>Each one is a higher academic qualification. For example, when entering the workforce, you might need different levels of qualifications depending on the job you are applying for. The higher the level of qualification, the more detailed your knowledge and skills will be.</p>`,
        },
        {
          question: `If a student completes the first course in a pathway, does it automatically mean they’re guaranteed entry to the next?`,
          answer: `<p>Once a student has successfully completed the first course in a pathway, they’ll be able to enrol into the next one, provided that they meet all the entry requirements. Please check the pathway details to find out more about the specific entry requirements to progress through a pathway.</p>`,
        },
        {
          question: `What happens if a student doesn’t get to the end of their pathway?`,
          answer: `<p>Once a student has successfully completed the first course in a pathway, they’ll be able to enrol into the next one, provided that they meet all the entry requirements. Please check the pathway details to find out more about the specific entry requirements to progress through a pathway.</p>`,
        },
        {
          question: `Why do certain courses not have a pathway?`,
          answer: `<p>Unfortunately, we cannot offer pathways into all courses. Each will have particular requirements, and some courses have strict entry requirements. We encourage students to expand their options and see what alternative courses are suitable for them.</p>`,
        }
      );
      break;
    default:
  }
  return (
    <StyledFaqsByUserType id="faqs-by-user-type" className={userType}>
      {faqs.map((faq, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <FaqItem
          key={index}
          className={`currenttype--${userType} ${
            activeQuestion === `answer-${index}` ? "active" : ""
          }`}
        >
          <button
            className="faq-question"
            type="button"
            id={`question-${index}`}
            aria-controls={`answer-${index}`}
            onClick={() =>
              setActiveQuestion(
                activeQuestion === `answer-${index}` ? "" : `answer-${index}`
              )
            }
          >
            <span className="q-letter">Q</span>
            <h4 className="omega">{faq.question}</h4>
            <span className="svg-wrap">
              <SVG
                src={`${process.env.PUBLIC_URL}/icons/accordion-arrow.svg`}
              />
            </span>
          </button>
          <div
            className="faq-answer"
            id={`answer-${index}`}
            role="region"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: faq.answer }}
            aria-labelledby={`question-${index}`}
          />
        </FaqItem>
      ))}
    </StyledFaqsByUserType>
  );
}

FaqsByUserType.propTypes = {
  userType: PropTypes.string,
};

FaqsByUserType.defaultProps = {
  userType: "",
};

export default FaqsByUserType;
