import React from 'react';
import { useMutation } from 'react-apollo-hooks';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';

import { TOGGLE_SEARCH_MUTATION } from './SearchModal';
import Button from './Button';

const StyledSearchButton = styled.div`
  > * {
    @media only screen and (max-width: 399px) {
      padding: 0 25px;
      margin: 5px 0;
    }
  }
`;

const StyledIconWrap = styled.span`
  position: relative;
  top: 3px;
  padding-left: 10px;
  @media only screen and (max-width: 399px) {
    display: none;
  }
  svg {
    * [id*='search'] {
      fill: ${props => props.theme.highlightRed};
    }
  }
`;

function SearchButton() {
  const toggleSearch = useMutation(TOGGLE_SEARCH_MUTATION, {
    variables: { searchActive: true },
  });

  return (
    <StyledSearchButton>
      <Button type="button" className="white" onClick={toggleSearch}>
        <span className="text">Search courses</span>
        <StyledIconWrap>
          <SVG src={process.env.PUBLIC_URL + '/icons/search.svg'} />
        </StyledIconWrap>
      </Button>
    </StyledSearchButton>
  );
}

export default SearchButton;
