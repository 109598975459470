import styled from "styled-components";

const SectionBreak = styled.div`
  display: none;
  position: relative;
  margin: 15px -20px 20px;
  text-align: left;
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background: ${(props) => props.theme.highlightLightBlue};
    z-index: -1;
  }
  > span {
    margin-left: 15px;
    border-left: 5px solid white;
    border-right: 5px solid white;
    background-color: white;
  }
  @media only screen and (max-width: 899px) {
    display: block;
  }
`;

export default SectionBreak;
