import React from "react";
import SVG from "react-inlinesvg";
import { Helmet } from "react-helmet";

import Main from "../components/Main";
import HeaderText from "../components/HeaderText";
import ContentCard from "../components/ContentCard";
import TileWrap from "../components/TileWrap";
import Interests from "../components/Interests";
import InterestTile from "../components/InterestTile";
import SectionBreak from "../components/SectionBreak";

export default class InterestsPage extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Interests | RMIT Pathways</title>
          <meta
            name="description"
            content="Choose one of the following areas of interest to view your course options and their pathways"
          />
        </Helmet>
        <Main id="interests-page" gridArea="main" className="content-wrap">
          <HeaderText id="interests-page-header-text">
            <span className="text no-icon">Select an interest area</span>
          </HeaderText>
          <SectionBreak id="interests-page-section-break">
            <span>
              <SVG
                src={`${process.env.PUBLIC_URL}/icons/icon-step-1-nav-blue.svg`}
              />
            </span>
          </SectionBreak>
          <ContentCard id="interests-page-content-card">
            <p id="interests-page-content-p">
              Choose one of the following areas of interest to view your course
              options and their pathways
            </p>
            <TileWrap>
              <Interests
                component={InterestTile}
                interestTilesLoadCallback={this.props.interestTilesLoadCallback}
              />
            </TileWrap>
          </ContentCard>
        </Main>
      </>
    );
  }
}
