import React from "react";
import styled from "styled-components";
import { useMutation } from "react-apollo-hooks";

import { TOGGLE_FAQS_MUTATION } from "./FaqsModal";
import Button from "./Button";

const StyledFaqsButton = styled.div`
  @media only screen and (max-width: 899px) {
    display: none;
  }
`;

function FaqsHomeButton() {
  const toggleFaqs = useMutation(TOGGLE_FAQS_MUTATION, {
    variables: { faqsActive: true },
  });
  return (
    <StyledFaqsButton>
      <Button type="button" className="white" onClick={toggleFaqs}>
        <span className="text">Need help understanding Pathways?</span>
      </Button>
    </StyledFaqsButton>
  );
}

export default FaqsHomeButton;
