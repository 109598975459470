import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledMain = styled.section`
  position: relative;
  margin: 0 20px;
  grid-area: ${props => props.gridArea};
  @media only screen and (min-width: 900px) {
    padding: ${props => props.mainPadding};
    margin: 0;
  }
`;

const MainInner = styled.div`
  margin: 0 auto;
  max-width: ${props => props.maxWidth};
  @media only screen and (min-width: 1400px) {
    max-width: none;
    margin: 0 50px;
  }
`;

function Main({ children, gridArea, id, className }) {
  return (
    <StyledMain
      id={id}
      className={className}
      gridArea={gridArea}
      mainPadding={gridArea === 'main' ? '120px 80px' : '80px 25px'}
    >
      <MainInner maxWidth={gridArea === 'main' ? '' : '1000px'}>
        {children}
      </MainInner>
    </StyledMain>
  );
}

export default Main;

Main.propTypes = {
  gridArea: PropTypes.string,
  maxWidth: PropTypes.string,
  mainPadding: PropTypes.string
};
