import styled from 'styled-components';

const TileWrap = styled.div`
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  grid-gap: 10px;
  margin-top: 30px;
  @media only screen and (min-width: 399px) {
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  }
  @media only screen and (max-width: 899px) {
    padding: 30px 30px 40px;
    box-shadow: 0 2px 7px 0 #ebebeb;
  }
  @media only screen and (min-width: 900px) {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    grid-gap: 20px;
    margin-top: 50px;
    padding: 0;
  }
  &.grid--150 {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    margin-top: 20px;
    @media only screen and (min-width: 399px) {
      grid-template-columns: repeat(auto-fill, minmax(160px, 1.2fr));
      
    }
    @media only screen and (max-width: 899px) {
      padding: 0;
      box-shadow: none;
    }
    @media only screen and (min-width: 900px) {
      margin-top: 35px;
    }
  }
`;

export default TileWrap;
