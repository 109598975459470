import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-apollo-hooks";
import gql from "graphql-tag";
import styled from "styled-components";

import ContentCard from "./ContentCard";
import SubHeaderText from "./SubHeaderText";
import AlternativeOptions from "./AlternativeOptions";
import TileWrap from "./TileWrap";
import CourseInterests from "./CourseInterests";
import InterestTile from "./InterestTile";

const SEARCH_NO_PATHWAY_QUERY = gql`
  query {
    searchNoPathway @client {
      id
      title
      interests {
        id
        title
        slug
        relatedInterests {
          id
          title
          slug
        }
      }
    }
  }
`;

const StyledSearchNoResults = styled.div`
  position: relative;
  .text-button {
    background: none;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: ${(props) => props.theme.highlightRed};
    }
    &:focus {
      color: ${(props) => props.theme.textPrimary};
      outline: 1px solid ${(props) => props.theme.highlightRed};
      outline-offset: 2px;
    }
    &:active {
      color: ${(props) => props.theme.darkRed};
    }
  }
`;

const StyledExploreRelatedInterests = styled.div`
  margin-top: 35px;
  @media only screen and (min-width: 900px) {
    margin-top: 80px;
  }
`;

function SearchNoResults({ setSearchFocus }) {
  const searchNoPathway = useQuery(SEARCH_NO_PATHWAY_QUERY);
  if (!searchNoPathway.data.searchNoPathway.length) return "";
  return (
    <ContentCard>
      <StyledSearchNoResults>
        <SubHeaderText className="beta red">
          No pathways available for{" "}
          {searchNoPathway.data.searchNoPathway[0].title}
        </SubHeaderText>
        <p>
          {
            "Unfortunately we are unable to offer formal pathways into these courses."
          }
        </p>
        <p>
          {"Please try "}
          <button
            className="text-button"
            type="button"
            onClick={() => {
              setSearchFocus(true);
            }}
          >
            searching again
          </button>
          {
            ", or consider some of the alternative options below. If you have any questions, please contact the "
          }
          <a
            href="https://www.rmit.edu.au/contact/visit-us/info-corner"
            target="_blank"
            rel="noopener noreferrer"
          >
            Study@RMIT
          </a>
          .
        </p>
        {searchNoPathway.data.searchNoPathway.length > 0 && (
          <AlternativeOptions
            interestId={searchNoPathway.data.searchNoPathway[0].interests[0].id}
          />
        )}
        <StyledExploreRelatedInterests>
          <SubHeaderText className="beta blue">
            Explore by interest area
          </SubHeaderText>
          <TileWrap className="grid--150">
            <CourseInterests
              component={InterestTile}
              interests={searchNoPathway.data.searchNoPathway[0].interests}
            />
          </TileWrap>
        </StyledExploreRelatedInterests>
      </StyledSearchNoResults>
    </ContentCard>
  );
}

SearchNoResults.propTypes = {
  setSearchFocus: PropTypes.func.isRequired,
};

export default SearchNoResults;
