import styled from "styled-components";

const SearchFieldWrap = styled.div`
  .rbt {
    position: relative;
    display: flex;
    flex-direction: row;
    &:before {
      content: url(${process.env.PUBLIC_UR}/icons/search.svg);
      width: 44px;
      min-width: 44px;
      height: 44px;
      border-radius: 100%;
      border: none;
      line-height: 46px;
      text-align: center;
      background: ${(props) => props.theme.highlightBlue};
      box-shadow: 0 3px 7px 0 rgba(1, 153, 230, 0.5);

      color: white;
      position: absolute;
      left: 3px;
      top: 3px;
      z-index: 1;
      transition: background 100ms ease;
      svg {
        position: relative;
        path {
          fill: white;
        }
      }
    }
    &:hover {
      &:before {
        background: ${(props) => props.theme.otherBlue};
      }
    }
    &:focus-within {
      &:before {
        background: ${(props) => props.theme.darkBlue};
      }
    }
  }
  .rbt-input-hint-container {
    width: 100%;
  }
  .rbt-input-hint {
    display: none;
  }
  .rbt-input-main {
    background: #ffffff;
    padding: 15px 30px 15px 70px;
    width: 100%;
    border: none;
    border: 1px solid rgba(1, 153, 230, 0.5);
    box-shadow: 0 2px 8px 0 rgba(0, 0, 84, 0.17);
    border-radius: 25px;
    -webkit-appearance: none;
    font-family: HelveticaNeue-Medium, sans-serif;
    font-size: 16px;
    color: ${(props) => props.theme.textPrimary};
    height: 50px;
    transition: border 100ms ease;
    &:hover {
      border: 1px solid ${(props) => props.theme.highlightBlue};
    }
    &:focus {
      outline: none;
      border: 1px solid ${(props) => props.theme.highlightBlue};
      &::placeholder {
        opacity: 0;
      }
    }

    &::placeholder {
      color: ${(props) => props.theme.textPrimary};
    }
  }
  .rbt-menu {
    /* Add !important to override inline styles 
    applied by the Typeahead package */
    position: absolute;
    width: 100% !important;
    max-height: none !important;
    top: calc(100% + 10px) !important;
    border: 1px solid rgba(1, 153, 230, 0.5);
    box-shadow: 0 2px 8px 0 rgba(0, 0, 84, 0.17);
    border-radius: 25px;
    margin: 0;
    padding: 0;
    z-index: 10;
    background-color: white;
    transform: translate3d(0, 0, 0) !important;
    .dropdown-item {
      display: flex;
      padding: 15px 30px;
      width: 100%;
      @media only screen and (max-width: 899px) {
        padding: 10px 15px;
      }
      .pathways {
        text-align: center;
        flex: 0 0 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media only screen and (max-width: 899px) {
          flex: 0 0 40px;
        }
      }
      .title {
        flex: 1 1 100%;
        padding-left: 20px;
        @media only screen and (min-width: 900px) {
          padding-left: 60px;
        }
        .rbt-highlight-text {
          background: ${(props) => props.theme.highlightLightBlue};
          color: ${(props) => props.theme.highlightBlue};
        }
      }
      .item-gp {
        color: ${(props) => props.theme.highlightRed};
      }
      &:hover,
      &.active {
        background-color: ${(props) => props.theme.highlightBlue};
        span {
          color: white;
          &.rbt-highlight-text {
            background-color: ${(props) => props.theme.highlightBlue};
            color: white;
          }
        }
        .item-gp {
          color: white;
        }
      }
      &.no-results {
        color: ${(props) => props.theme.highlightRed};
        &:hover {
          background-color: white;
          span {
            color: ${(props) => props.theme.highlightRed};
          }
        }
      }
    }
  }
  .rbt-menu-header {
    position: absolute;
    display: flex;
    align-items: center;
    height: 65px;
    width: 100%;
    padding: 0 30px;
    margin: 0;
    border-bottom: 1px solid rgba(1, 153, 230, 0.5);
    background: white;
    .header-item {
      margin: 0;
      &.pathways {
        flex: 0 0 80px;
        @media only screen and (max-width: 899px) {
          flex: 0 0 40px;
          text-align: center;
          position: relative;
          top: 2px;
          .text {
            display: none;
          }
        }
        @media only screen and (min-width: 900px) {
          .isvg {
            display: none;
          }
        }
      }
      &.courses {
        flex: 1 0 auto;
        padding-left: 20px;
        @media only screen and (min-width: 900px) {
          padding-left: 60px;
        }
      }
    }
  }
  .inner-list {
    max-height: 490px;
    margin: 65px 0 0 0;
    padding: 0;
    overflow: scroll;
    list-style: none;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 2px solid white;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
`;

export default SearchFieldWrap;
