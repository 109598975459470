import styled from 'styled-components';

const ShareButton = styled.button`
  width: 120px;
  height: 50px;
  border-radius: 25px;
  border: none;
  margin-left: 10px;
  text-align: center;
  background: ${props => props.theme.highlightBlue};
  box-shadow: 0 2px 8px 0 rgba(0, 0, 84, 0.17);
  color: white;
  padding: 8px 30px;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Museo500';
  cursor: pointer;
  &:hover {
    background: ${props => props.theme.otherBlue};
    border-color: ${props => props.theme.otherBlue};
  }
  &:focus {
    background: ${props => props.theme.otherBlue};
    border-color: ${props => props.theme.otherBlue};
    outline: none;
  }
  &:active {
    background: ${props => props.theme.textPrimary};
    border-color: ${props => props.theme.textPrimary};
  }
`;

export default ShareButton;
