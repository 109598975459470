import styled from 'styled-components';

const Button = styled.a`
  display: inline-block;
  height: 50px;
  padding: 0 35px;
  margin: 5px;
  border: 0;
  border-radius: 25px;
  line-height: 48px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  @media only screen and (max-width: 899px) {
    max-width: 200px;
    padding: 0 25px;
    height: 48px;
    line-height: 46px;
  }
  &.white {
    font-family: 'Museo700', serif;
    background: white;
    color: ${props => props.theme.highlightRed};
    box-shadow: 0 2px 7px 0 #cdcdcd;
    border: 2px solid white;
    &:active {
      background: ${props => props.theme.highlightRed};
      color: white;
      border-color: ${props => props.theme.highlightRed};
      svg {
        path {
          fill: white;
        }
      }
    }
    &:focus {
      border-color: ${props => props.theme.highlightRed};
      outline: none;
      .text {
        text-decoration: underline;
      }
    }
  }
  &.blue {
    font-family: 'Museo500', serif;
    background: ${props => props.theme.textPrimary};
    color: white;
    svg {
      margin-left: 5px;
      position: relative;
      top: 2px;
      path {
        fill: white;
      }
    }
    &:active {
      background: ${props => props.theme.darkerBlue};
      color: white;
    }
    &:focus {
      background: ${props => props.theme.darkerBlue};
    }
  }
  &.red {
    font-family: 'Museo500', serif;
    background: ${props => props.theme.highlightRed};
    color: white;
    box-shadow: 0 2px 7px 0 #cdcdcd;
    svg {
      margin-left: 5px;
      position: relative;
      top: 2px;
      path {
        fill: white;
      }
    }
    &:active {
      background: ${props => props.theme.darkRed};
      color: white;
    }
    &:focus {
      background: ${props => props.theme.darkRed};
    }
  }
  &:hover {
    .text {
      text-decoration: underline;
    }
  }
  &.has-svg {
    @media only screen and (min-width: 900px) {
      svg {
        position: relative;
        top: 1px;
        left: 4px;
      }
    }
  }

  &.no-border {
    border: none;
    padding: 0;
    margin: 0;

    @media only screen and (max-width: 900px) {
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      .text {
        display: none;
      }
      .isvg {
        width: 16px;
        height: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    @media only screen and (min-width: 900px) {
      box-shadow: none;
      padding: 0 3vw;

      svg {
        position: relative;
        top: 2px;
        left: 2px;
      }
    }
    &:active {
      background: white;
      color: ${props => props.theme.highlightRed};
      svg {
        path {
          fill: ${props => props.theme.highlightRed};
        }
      }
    }
    &:focus {
      background: white;
    }
  }
`;

export default Button;
