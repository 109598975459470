import React from 'react';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';

import FooterNav from './FooterNav';
import SocialNav from './SocialNav';

const FooterWrap = styled.footer`
  grid-area: footer;
  background: ${props => props.theme.textDark};
  padding: 30px 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (min-width: 375px) {
    padding: 80px 40px;
  }
  @media only screen and (min-width: 1200px) {
    padding: 80px 0;
    align-items: flex-start;
  }
  &.footer--home {
    @media only screen and (min-width: 1200px) {
      padding: 80px 100px;
      align-items: flex-start;
    }
  }
  .footer-logo {
    margin-bottom: 20px;
    display: inline-block;
    @media only screen and (min-width: 1200px) {
      svg {
        width: 196px;
        height: 70px;
      }
    }
  }
`;

function Footer({ className, id }) {
  return (
    <FooterWrap className={className} id={id} >
      <a href="https://www.rmit.edu.au/" className="footer-logo">
        <SVG src={`${process.env.PUBLIC_URL}/logo.svg`} />
      </a>
      <SocialNav />
      <FooterNav />
    </FooterWrap>
  );
}
export default Footer;
