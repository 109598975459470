import React from "react";
import styled from "styled-components";

const StyledPathwaysByCourseList = styled.ul`
  list-style: none;
  margin: 35px 0;
  padding: 0;
  @media only screen and (min-width: 900px) {
    margin: 35px 0 50px;
  }
  @media only screen and (min-width: 1200px) {
    margin: 35px 0 85px;
  }
  &.gp-filtering-by--true {
    .gp-filter--true {
    }
    .gp-filter--false {
      display: none;
    }
  }
  &.gp-filtering-by--false {
    .gp-filter--true {
      display: none;
    }
  }
`;

function PathwaysByCourseList({ gpFilter, children }) {
  return (
    <StyledPathwaysByCourseList className={"gp-filtering-by--" + gpFilter}>
      {children}
    </StyledPathwaysByCourseList>
  );
}

export default PathwaysByCourseList;
