import React from "react";
import styled from "styled-components";

const StyledCardListItem = styled.li`
  box-shadow: 0 2px 7px 0 #ebebeb;
  margin-top: 20px;
  max-width: 900px;
  h3 {
    margin-top: 0;
    position: relative;
    margin-bottom: 5px;
  }
  p {
    position: relative;
    margin-bottom: 0;
  }
  > a {
    display: inline-block;
    padding: 20px 25px;
    width: 100%;
    position: relative;
    min-height: 125px;
    @media screen and (min-width: 1200px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 5px;
      height: 100%;
      transition: all 150ms ease-in;
      background: ${(props) => props.theme.highlightBlue};
    }
    &:hover {
      h3 {
        text-decoration: underline;
      }
    }
    &:focus {
      outline: none;
      h3 {
        text-decoration: underline;
      }
      &:before {
        background: ${(props) => props.theme.darkBlue};
      }
    }
  }
  .card-list-item--gp {
    color: ${(props) => props.theme.highlightRed};
    display: block;
    @media screen and (min-width: 1200px) {
      display: inline-block;
    }
  }
  &.course-item-clicked {
    > a {
      &:before {
        transition: all 250ms ease-in;
        width: 100%;
        background: ${(props) => props.theme.highlightBlue};
      }
    }
  }
  &.ve-course {
    > a {
      &:before {
        background: ${(props) => props.theme.orange};
      }
    }
    .study-level-tag {
      background-color: ${(props) => props.theme.lightOrange};
    }
  }
  &.he-course {
    > a {
      &:before {
        background: ${(props) => props.theme.lavender};
      }
    }
    .study-level-tag {
      background-color: ${(props) => props.theme.lightLavender};
    }
  }
`;

function CardListItem({ children, className }) {
  return (
    <StyledCardListItem className={className}>{children}</StyledCardListItem>
  );
}

export default CardListItem;
