import React from 'react';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';

const SocialNavWrap = styled.nav`
  margin: 0 -10px;
  display: flex;

  @media only screen and (min-width: 1200px) {
    position: absolute;
    top: 80px;
    right: 112px;
  }
  .social__item {
    position: relative;
    padding: 5px;
    @media only screen and (min-width: 800px) {
      padding: 10px;
    }
    a {
      width: 40px;
      height: 40px;
      svg {
        width: 40px;
        height: 40px;
      }
      @media only screen and (min-width: 800px) {
        width: 42px;
        height: 42px;
        svg {
          width: 42px;
          height: 42px;
        }
      }
      span:not(.isvg) {
        text-indent: -9999px;
        position: absolute;
        width: 1px;
      }
    }
  }
`;

function SocialNav() {
  return (
    <SocialNavWrap>
      <div className="social__item">
        <a href="https://www.facebook.com/RMITuniversity/">
          <SVG src={`${process.env.PUBLIC_URL}/icons/facebook.svg`} />
          <span>Facebook</span>
        </a>
      </div>

      <div className="social__item">
        <a href="https://twitter.com/rmit">
          <SVG src={`${process.env.PUBLIC_URL}/icons/twitter.svg`} />
          <span>Twitter</span>
        </a>
      </div>
      <div className="social__item">
        <a href="https://www.instagram.com/rmituniversity/">
          <SVG src={`${process.env.PUBLIC_URL}/icons/instagram.svg`} />
          <span>Instagram</span>
        </a>
      </div>
      <div className="social__item">
        <a href="https://www.linkedin.com/edu/rmit-university-10234">
          <SVG src={`${process.env.PUBLIC_URL}/icons/linkedin.svg`} />
          <span>LinkedIn</span>
        </a>
      </div>
      <div className="social__item">
        <a href="https://www.youtube.com/user/rmitmedia">
          <SVG src={`${process.env.PUBLIC_URL}/icons/youtube.svg`} />
          <span>Youtube</span>
        </a>
      </div>
      <div className="social__item">
        <a href="http://www.weibo.com/rmituni">
          <SVG src={`${process.env.PUBLIC_URL}/icons/weibo.svg`} />
          <span>Weibo</span>
        </a>
      </div>
    </SocialNavWrap>
  );
}
export default SocialNav;
