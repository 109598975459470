import styled from 'styled-components';

const SubHeaderText = styled.h2`
  position: relative;
  padding: 1em 0 0 0;
  margin: 0 0 0.5em 0;
  &:before {
    content: '—';
    position: absolute;
    top: 0;
    left: 0px;
    color: ${props => props.theme.highlightRed};
  }
  &.blue {
    &:before {
      color: ${props => props.theme.highlightBlue};
    }
  }
  &.red {
    &:before {
      color: ${props => props.theme.highlightRed};
    }
  }
`;

export default SubHeaderText;
