import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import { useMutation } from 'react-apollo-hooks';
import styled from 'styled-components';
import gql from 'graphql-tag';

import TileUserType from './TileUserType';

const USER_TYPE_MUTATION = gql`
  mutation updateUserType($userType: String!) {
    updateUserType(userType: $userType) @client
  }
`;

const TileExtended = styled(TileUserType)`
  button {
    h3 {
      margin-bottom: 0;
      position: relative;
      padding-top: 5px;
      margin-top: 0;
      text-align: left;
      @media only screen and (min-width: 900px) {
        margin-top: 30px;
        padding-top: 20px;
      }
      &:before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        background: ${props => props.theme.highlightRed};
        width: 15px;
        height: 2px;
        @media only screen and (min-width: 900px) {
          width: 20px;
          height: 3px;
        }
      }
    }
  }
`;

function FaqsTile({ userType, title, color, colorPrimary, colorBg, active }) {
  const updateUserType = useMutation(USER_TYPE_MUTATION, {
    variables: { userType }
  });
  return (
    <TileExtended
      className={`tile--${color} currenttype--${active} ${
        active === userType ? 'active' : ''
      }`}
    >
      <button
        type="button"
        onClick={() => {
          updateUserType();
          document
            .getElementById('faqs-by-user-type')
            .scrollIntoView({ behavior: 'smooth' });
        }}
      >
        <SVG src={`${process.env.PUBLIC_URL}/icons/pathways-${color}.svg`} />
        <h3 className="beta">
          <span className="text">{title}</span>
        </h3>
      </button>
    </TileExtended>
  );
}

FaqsTile.propTypes = {
  userType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
  colorPrimary: PropTypes.string,
  colorBg: PropTypes.string,
  active: PropTypes.string
};

FaqsTile.defaultProps = {
  color: 'blue',
  colorPrimary: '#0199E6',
  colorBg: '#D3F4FF',
  active: 'highschool'
};

export default FaqsTile;
