import React, { useRef, useState } from 'react';
import SVG from 'react-inlinesvg';

import Button from './Button';
import CircleButton from './CircleButton';
import ShareButtonWrap from './styles/ShareButtonWrap';
import SharePopUpModal from './styles/SharePopUpModal';
import SharePopup from './styles/SharePopup';
import ShareButton from './styles/ShareButton';

function Share(props) {
  const toCopyRef = useRef(null);
  const [sharePopupStatus, setSharePopup] = useState(false);
  function CopyShareLink(e) {
    toCopyRef.current.select();
    document.execCommand('copy');
    document.querySelector('.share__input-button').innerHTML = 'Copied!';
  }
  // listen for modal change -> if modal active -> listen for keydown -> if keydown -> close modal
  const checkModalStatus = event => {
    if(sharePopupStatus) {
      // if modal active
      addKeyDownListener();
    } else {
      // if modal not active
      removeKeyDownListener();
    }
  };

  const removeKeyDownListener = function() {
    setTimeout(() => {
      document.removeEventListener('keydown', checkDocumentKeyDown);
    });
  }

  const addKeyDownListener = function() {
    setTimeout(() => {
      document.addEventListener('keydown', checkDocumentKeyDown);
    });
  }

  const checkDocumentKeyDown = event => {
    if (
      (event.key === 'Escape' || event.key === 'Esc' || event.keyCode === 27)
    ) {
      setSharePopup(!sharePopupStatus);
      document.body.classList.remove('popup-active');
      document.querySelector('.share__input-button').innerHTML = 'Copy';
    }
  };

  checkModalStatus();
  return (
    <>
      <ShareButtonWrap id={props.id}>
        <CircleButton
          onClick={() => {
            setSharePopup(!sharePopupStatus);
            document.body.classList.add('popup-active');
          }}
          type="button"
          className="share no-border has-svg "
        >
          <span className="text sr-only">Share</span>
          <SVG src={process.env.PUBLIC_URL + '/icons/share.svg'} />
        </CircleButton>
      </ShareButtonWrap>
      <SharePopUpModal className={sharePopupStatus && 'active'}>
        <SharePopup>
          <Button
            type="button"
            className="white no-border has-svg"
            onClick={() => {
              setSharePopup(!sharePopupStatus);
              document.querySelector('.share__input-button').innerHTML = 'Copy';
              document.body.classList.remove('popup-active');
            }}
          >
            <span className="text">Close</span>
            <SVG src={process.env.PUBLIC_URL + '/icons/cross.svg'} />
          </Button>
          <h4 className="beta">Share this link</h4>
          <div className="share__input-group">
            <input
              type="text"
              className="share__input-field"
              data-autoselect=""
              value={window.location}
              aria-label="Share this link"
              readOnly
              ref={toCopyRef}
            />
            <div className="share__input-button-wrap">
              <ShareButton
                className="share__input-button"
                value={window.location}
                aria-label="Copy to clipboard"
                tabindex="0"
                role="button"
                onClick={CopyShareLink}
              >
               Copy
              </ShareButton>
            </div>
          </div>
        </SharePopup>
      </SharePopUpModal>
    </>
  );
}

export default Share;
