import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useQuery, useMutation } from "react-apollo-hooks";
import gql from "graphql-tag";
import SVG from "react-inlinesvg";
import { Helmet } from "react-helmet";

import ContentNotFound from "../components/ContentNotFound";
import ContentWithSidebar from "../components/ContentWithSidebar";
import Sidebar from "../components/Sidebar";
import SubHeaderText from "../components/SubHeaderText";
import PathwaySidebar from "../components/PathwaySidebar";
import Main from "../components/Main";
import HeaderText from "../components/HeaderText";
import SectionBreak from "../components/SectionBreak";
import ContentCard from "../components/ContentCard";
import Pathway from "../components/Pathway";
import ContactWrap from "../components/ContactWrap";
import ContactIntro from "../components/ContactIntro";
import ContactCards from "../components/ContactCards";
import ContactCard from "../components/ContactCard";
import ContactInnerInfo from "../components/ContactInnerInfo";
import ContactInnerForm from "../components/ContactInnerForm";
import ContactInnerLocation from "../components/ContactInnerLocation";
import usePrevious from "../lib/usePrevious";

const PATHWAY_QUERY = gql`
  query PATHWAY_QUERY($slug: String!) {
    pathway(where: { slug: $slug }) {
      id
      title
      goalCourse {
        id
        title
        campus
        code
        codeUnique
        description
        url
        selectionTasks
        notes
        interests(first: 1) {
          id
          title
          slug
        }
      }
      goalDuration
      goalPrerequisites
      steps {
        id
        course {
          id
          title
          atar
          campus
          code
          ncc
          description
          prerequisites
          url
          selectionTasks
          notes
          level
        }
        duration
        prerequisites
      }
      guaranteedPathway
    }
  }
`;

const CURRENT_COURSE_MUTATION = gql`
  mutation updateCurrentCourse($currentCourse: String!) {
    updateCurrentCourse(currentCourse: $currentCourse) @client
  }
`;

const CURRENT_INTEREST_MUTATION = gql`
  mutation updateCurrentInterest($currentInterest: String!) {
    updateCurrentInterest(currentInterest: $currentInterest) @client
  }
`;

const CURRENT_PATHWAY_QUERY = gql`
  query {
    currentPathway @client
  }
`;

function PathwayPage({
  match,
  pathwayDataLoadCallback,
  pathwayOptionsLoadCallback,
}) {
  const currentPathwayQuery = useQuery(CURRENT_PATHWAY_QUERY);
  const { data, loading, error } = useQuery(PATHWAY_QUERY, {
    variables: { slug: match.params.slug.toUpperCase() },
  });
  const updateCurrentCourse = useMutation(CURRENT_COURSE_MUTATION);
  const updateCurrentInterest = useMutation(CURRENT_INTEREST_MUTATION);

  const [urlChanged, setUrlChanged] = useState(false);
  const prevUrl = usePrevious(match.url);
  useEffect(() => {
    // If URL is changed, set the urlChanged flag and return. The flag changes the animation-related ids in the dom so the animation CSS won't effect them.
    if (prevUrl && prevUrl !== match.url) {
      setUrlChanged(true);
      return;
    }
    // If no error, call the callback function with true, otherwise with false
    let success = false;
    if (!error && data.hasOwnProperty("pathway")) {
      success = true;
    }
    pathwayDataLoadCallback(success);
  }, [prevUrl, match.url, error, data, pathwayDataLoadCallback]);

  if (error) return error.message;
  if (!loading && data.pathway) {
    updateCurrentCourse({
      variables: { currentCourse: data.pathway.goalCourse.codeUnique },
    });
    updateCurrentInterest({
      variables: { currentInterest: data.pathway.goalCourse.interests[0].slug },
    });
  }
  if (data.pathway === null) {
    return <ContentNotFound />;
  }
  // Set ids to be passed down to Pathway component
  let idPrefix = "";
  if (urlChanged) {
    idPrefix = "exit-";
  }
  let pathwayProps = {
    shareId: `${idPrefix}pathway-page-content-share`,
    topHeaderId: `${idPrefix}pathway-page-content-top-header`,
    topSubHeaderId: `${idPrefix}pathway-page-content-top-subheader`,
    tooltipId: `${idPrefix}pathway-page-content-top-gp`,
    topPId: `${idPrefix}pathway-page-content-top-p`,
    secondPId: `${idPrefix}pathway-page-content-second-p`,
    howToId: `${idPrefix}pathway-page-content-how-to`,
    pathwayItemClass: `${idPrefix}pathway-page-content-pathway-item`,
    disclaimerId: `${idPrefix}pathway-page-content-disclaimer`,
  };
  return (
    <>
      {!loading && (
        <Helmet>
          <title>
            {data.pathway.goalCourse.title}{" "}
            {currentPathwayQuery.data.currentPathway} | RMIT Pathways
          </title>
          <meta
            name="description"
            content={`Start the pathway to ${data.pathway.goalCourse.title}`}
          />
        </Helmet>
      )}
      <ContentWithSidebar>
        <Sidebar>
          <SubHeaderText className="beta blue" id="pathway-page-sidebar-header">
            Pathway Options
          </SubHeaderText>
          {!loading && (
            <PathwaySidebar
              codeUnique={data.pathway.goalCourse.codeUnique}
              pathwayItemsLoadCallback={pathwayOptionsLoadCallback}
              className={
                urlChanged
                  ? "exit-pathway-page-pathway-item"
                  : "pathway-page-pathway-item"
              }
              wrapperId={
                urlChanged
                  ? "exit-pathway-page-sidebar-wrapper"
                  : "pathway-page-sidebar-wrapper"
              }
              match={match}
            />
          )}
        </Sidebar>
        <Main id="pathway-page">
          <HeaderText>
            {loading ? (
              <span>Loading...</span>
            ) : (
              <>
                <span
                  className="icon"
                  id={
                    urlChanged
                      ? "exit-pathway-page-header-icon"
                      : "pathway-page-header-icon"
                  }
                >
                  <SVG
                    className="lg"
                    src={`${process.env.PUBLIC_URL}/icons/icon-${data.pathway.goalCourse.interests[0].slug}.svg`}
                  />
                </span>
                <span
                  className="text"
                  id={
                    urlChanged
                      ? "exit-pathway-page-header-text"
                      : "pathway-page-header-text"
                  }
                >
                  {currentPathwayQuery.data.currentPathway}
                </span>
              </>
            )}
          </HeaderText>
          {/* 
        <PageButtonWrap>
          <FaqsButton />
          <SearchButton />
        </PageButtonWrap>
        */}
          <SectionBreak>
            <span>
              <SVG
                src={`${process.env.PUBLIC_URL}/icons/icon-step-4-nav-blue.svg`}
              />
            </span>
          </SectionBreak>
          <ContentCard>
            {loading ? (
              <span>Loading...</span>
            ) : (
              <Pathway pathway={data.pathway} {...pathwayProps} />
            )}
            <hr id="pathway-page-content-hr" />
            <ContactWrap
              type="contact-wrap--footer"
              id="pathway-page-content-contact"
            >
              <ContactIntro icon="group-shapes">
                <h2>Contact us</h2>
                <p>
                  If you have any questions about pathways please contact
                  Study@RMIT. Our advisors are available with extended hours
                  during the Change of Preference period.
                </p>
              </ContactIntro>
              <ContactCards type="contact-cards--row">
                <ContactCard type="card--border" icon="communication">
                  <ContactInnerInfo />
                </ContactCard>
                <ContactCard type="card--border" icon="information-technology">
                  <ContactInnerForm />
                </ContactCard>
                <ContactCard type="card--border" icon="navy-global">
                  <ContactInnerLocation />
                </ContactCard>
              </ContactCards>
            </ContactWrap>
          </ContentCard>
        </Main>
      </ContentWithSidebar>
    </>
  );
}

PathwayPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string, //.isRequired
    }),
  }),
};

export default PathwayPage;
