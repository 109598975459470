import styled from "styled-components";

const PathwayOptionSteps = styled.div`
  position: relative;
  margin: 20px 0;
  padding-bottom: 0px;
  @media only screen and (min-width: 900px) {
    padding-bottom: 10px;
  }
  @media only screen and (min-width: 1200px) {
    padding-bottom: 30px;
  }
  .pathway-step {
    &__item {
      padding: 20px 0 0 25px;
      margin-bottom: 20px;
      position: relative;
      max-width: 660px;
      /* dotted line */
      &:before {
        content: "";
        position: absolute;
        left: 4px;
        top: 60px;
        width: 2px;
        height: calc(100% + 20px);
        border-left: 2px dotted ${(props) => props.theme.textDark};
        @media only screen and (min-width: 900px) {
          left: 5px;
          top: 60px;
        }
      }
      > a {
        display: inline-block;
        height: 50px;
        padding: 0 35px;
        border: 0;
        border-radius: 25px;
        line-height: 48px;
        font-size: 16px;
        text-align: center;
        font-family: "Museo500", serif;
        background: ${(props) => props.theme.textPrimary};
        color: white;
        cursor: pointer;
        @media only screen and (max-width: 899px) {
          width: 100%;
          max-width: 335px;
        }
        &:hover {
          span {
            text-decoration: underline;
          }
        }
        &:active {
          background: ${(props) => props.theme.darkerBlue};
          color: white;
        }
        &:focus {
          background: ${(props) => props.theme.darkerBlue};
        }
        svg {
          margin-left: 5px;
          position: relative;
          top: 2px;
          path {
            fill: white;
          }
        }
      }

      h5 {
        margin-bottom: 10px;
      }
    }
    &__inner {
      padding-bottom: 35px;
    }
    &__title {
      color: ${(props) => props.theme.texDark};

      /* circle */
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 48px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: white;
        border: 2px solid ${(props) => props.theme.textDark};
        @media only screen and (min-width: 900px) {
          width: 12px;
          height: 12px;
          top: 52px;
        }
      }
    }
    &__item:first-of-type {
      .pathway-step__title:before {
        background-color: ${(props) => props.theme.textDark};
      }
    }
    &__item:last-of-type {
      margin-bottom: 40px;
      &:before {
        height: calc(100% - 60px);
      }
      /* end line */
      &:after {
        content: "";
        position: absolute;
        left: 0px;
        bottom: 0px;
        width: 10px;
        height: 2px;
        background-color: ${(props) => props.theme.textDark};
        display: inline-block;
        @media only screen and (min-width: 900px) {
          width: 12px;
        }
      }
    }
    &__requirements {
      padding: 10px 15px;
      background-color: ${(props) => props.theme.sidebarBg};
    }
    &__details {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 0px -10px 0;
      &-item {
        flex: 1 0 1px;
        min-width: 200px;
        padding: 0 10px;
        .omega {
          margin-top: 4px;
        }
        p {
          margin-bottom: 0;
        }
      }
    }
  }
  p.disclaimer {
    font-style: italic;
  }
`;

export default PathwayOptionSteps;
