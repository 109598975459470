import React from "react";
import styled from "styled-components";

const StyledCardListWrap = styled.ul`
  list-style: none;
  margin: 35px 0;
  padding: 0;
  @media only screen and (min-width: 900px) {
    margin: 35px 0 50px;
  }
  @media only screen and (min-width: 1200px) {
    margin: 35px 0 85px;
  }
  &.filter-by--all-courses {
    .he-course,
    .ve-course {
      display: block;
    }
  }
  &.filter-by--higher-education {
    .he-course {
      display: block;
    }
    .ve-course {
      display: none;
    }
  }
  &.filter-by--vocational {
    .ve-course {
      display: block;
    }
    .he-course {
      display: none;
    }
  }
`;

function CardListWrap({ filterValue, children }) {
  return (
    <StyledCardListWrap className={`filter-by--${filterValue}`}>
      {children}
    </StyledCardListWrap>
  );
}

export default CardListWrap;
