/**
 * This class runs animations of pathway steps.
 *
 * Every page has an animation queue for entering. Every animation in the queue will be run only when:
 *   1- Its corresponding dom is ready. When data is successfully fetched in a component,
 *      a callback function is called with a "success" flag. That's how we know dom is ready.
 *   2- The previous animations in the queue have already began or completed.
 *
 * Page entering animation will be started when prevoius page's exiting animation is completed OR
 * when the page is just loaded, (there is no page transition)
 *
 * Note: Exit transition won't be triggered at all, if the new page is not handled by <Transition>,
 *       so no need to check for the new page's validation in exit animation methods.
 * Note: In exit methods, init the enter animation object because it might be used by callback functions (like when data is loaded and dom is rendered)
 * Note: Don't reuse animation objects, init a new one every time.
 */

import anime from "animejs";
import interestsAnimation from "./interestsAnimation";
import studyLevelAnimation from "./studyLevelAnimation";
import itrsAnimation from "./itrsAnimation";
import courseAnimation from "./courseAnimation";
import pathwayAnimation from "./pathwayAnimation";

export default class animationService {
  constructor() {
    this.interestsAnimation = new interestsAnimation(this);
    this.studyLevelAnimation = new studyLevelAnimation(this);
    this.itrsAnimation = new itrsAnimation(this);
    this.courseAnimation = new courseAnimation(this);
    this.pathwayAnimation = new pathwayAnimation(this);
    this.themeHighlightBlue = "#0199E6";
    this.textDark = "#070758";
    this.animationsByPath = {
      "/interests": {
        initEnterAnimation: this.interestsAnimation._initInterestsPageEnterAnimation.bind(
          this.interestsAnimation
        ),
        hidePage: this.interestsAnimation._hideInterestsPage.bind(
          this.interestsAnimation
        ),
        runEnterAnimation: this.interestsAnimation.runInterestsPageEnterAnimation.bind(
          this.interestsAnimation
        ),
      },
      "/study-level": {
        initEnterAnimation: this.studyLevelAnimation._initStudyLevelPageEnterAnimation.bind(
          this.studyLevelAnimation
        ),
        hidePage: this.studyLevelAnimation._hideStudyLevelPage.bind(
          this.studyLevelAnimation
        ),
        runEnterAnimation: this.studyLevelAnimation.runStudyLevelPageEnterAnimation.bind(
          this.studyLevelAnimation
        ),
      },
      "/interest/:slug": {
        initEnterAnimation: this.itrsAnimation._initItrsPageEnterAnimation.bind(
          this.itrsAnimation
        ),
        hidePage: this.itrsAnimation._hideItrsPage.bind(this.itrsAnimation),
        runEnterAnimation: this.itrsAnimation.runItrsPageEnterAnimation.bind(
          this.itrsAnimation
        ),
      },
      "/course/:codeUnique": {
        initEnterAnimation: this.courseAnimation._initCoursePageEnterAnimation.bind(
          this.courseAnimation
        ),
        hidePage: this.courseAnimation._hideCoursePage.bind(
          this.courseAnimation
        ),
        runEnterAnimation: this.courseAnimation.runCoursePageEnterAnimation.bind(
          this.courseAnimation
        ),
      },
      "/pathway/:slug": {
        initEnterAnimation: this.pathwayAnimation._initPathwayPageEnterAnimation.bind(
          this.pathwayAnimation
        ),
        hidePage: this.pathwayAnimation._hidePathwayPage.bind(
          this.pathwayAnimation
        ),
        runEnterAnimation: this.pathwayAnimation.runPathwayPageEnterAnimation.bind(
          this.pathwayAnimation
        ),
      },
    };
    // This is used by all exiting animations. When true, no entering animation should be added.
    this.exitAnimationRunning = false;
  }

  _hideItemById(itemId) {
    anime.set("#" + itemId, { display: "none" });
  }

  _showItemById(itemId) {
    anime.set("#" + itemId, { display: "" });
  }

  _getReverseArrayOfHTMLCollection(collection) {
    // Convert HTMLCollection to array
    collection = [...collection];
    return collection.reverse();
  }

  _tryToSetDomReady(anim, itemKey) {
    anim.readyFlags[itemKey] = true;
    let domIsReady = true;
    for (let key in anim.readyFlags) {
      if (!anim.readyFlags[key]) {
        domIsReady = false;
        break;
      }
    }
    anim.domReady = domIsReady;
  }

  _tryToAddAnimation(animQueue, queueIndex) {
    // If the index doesn't exist, exit
    if (!(queueIndex in animQueue)) {
      return;
    }
    // If animation is not ready to be added, exit
    if (!this._isReadyToBeAdded(animQueue, queueIndex)) {
      return;
    }

    // If there still are animations in front of it in the queue, exit
    if (!this._isItsTurnInQueue(animQueue, queueIndex)) {
      return;
    }

    // Add animation
    animQueue[queueIndex].addAnimation();
    animQueue[queueIndex].added = true;

    // Try to add next animation
    this._tryToAddAnimation(animQueue, queueIndex + 1);
  }

  _isReadyToBeAdded(animQueue, queueIndex) {
    if (!animQueue[queueIndex].domReady) {
      return false;
    }
    queueIndex--;
    if (queueIndex < 0) {
      return true;
    }
    if (!animQueue[queueIndex].began) {
      return false;
    }
    return true;
  }

  _isItsTurnInQueue(animQueue, queueIndex) {
    // Check all items with lower index, if not added yet, return false
    for (let i = queueIndex - 1; i > -1; i--) {
      if (!animQueue[i].added) {
        return false;
      }
    }
    return true;
  }

  // Interests page
  runInterestsPageEnterAnimation() {
    this.interestsAnimation.runInterestsPageEnterAnimation();
  }

  addInterestTilesToInterestsPageEnterAnimation(success) {
    this.interestsAnimation.addInterestTilesToInterestsPageEnterAnimation(
      success
    );
  }

  runInterestsPageExitAnimation(match) {
    this.interestsAnimation.runInterestsPageExitAnimation(match);
  }

  endInterestsPageAnimation(node, done) {
    this.interestsAnimation.endInterestsPageAnimation(node, done);
  }

  // StudyLevel page
  runStudyLevelPageEnterAnimation() {
    this.studyLevelAnimation.runStudyLevelPageEnterAnimation();
  }

  addStudyLevelTilesToStudyLevelPageEnterAnimation(success) {
    this.studyLevelAnimation.addStudyLevelTilesToStudyLevelPageEnterAnimation(
      success
    );
  }

  runStudyLevelPageExitAnimation(match) {
    this.studyLevelAnimation.runStudyLevelPageExitAnimation(match);
  }

  endStudyLevelPageAnimation(node, done) {
    this.studyLevelAnimation.endStudyLevelPageAnimation(node, done);
  }

  // Interest page
  runItrsPageExitAnimation(match) {
    this.itrsAnimation.runItrsPageExitAnimation(match);
  }

  endItrsPageAnimation(node, done) {
    this.itrsAnimation.endItrsPageAnimation(node, done);
  }

  runItrsPageEnterAnimation() {
    this.itrsAnimation.runItrsPageEnterAnimation();
  }

  addRelatedToItrsPageEnterAnimation(success) {
    this.itrsAnimation.addRelatedToItrsPageEnterAnimation(success);
  }

  addOtherToItrsPageEnterAnimation(success) {
    this.itrsAnimation.addOtherToItrsPageEnterAnimation(success);
  }

  addCoursesToItrsPageEnterAnimation(success) {
    this.itrsAnimation.addCoursesToItrsPageEnterAnimation(success);
  }

  // Course page
  runCoursePageExitAnimation(match) {
    this.courseAnimation.runCoursePageExitAnimation(match);
  }

  endCoursePageAnimation(node, done) {
    this.courseAnimation.endCoursePageAnimation(node, done);
  }

  runCoursePageEnterAnimation() {
    this.courseAnimation.runCoursePageEnterAnimation();
  }

  addItemsToCoursePageEnterAnimation(success, itemKey) {
    this.courseAnimation.addItemsToCoursePageEnterAnimation(success, itemKey);
  }

  // Pathway page
  runPathwayPageExitAnimation(match) {
    this.pathwayAnimation.runPathwayPageExitAnimation(match);
  }

  endPathwayPageAnimation(node, done) {
    this.pathwayAnimation.endPathwayPageAnimation(node, done);
  }

  runPathwayPageEnterAnimation() {
    this.pathwayAnimation.runPathwayPageEnterAnimation();
  }

  addItemsToPathwayPageEnterAnimation(success, itemKey) {
    this.pathwayAnimation.addItemsToPathwayPageEnterAnimation(success, itemKey);
  }
}
