import React, { useEffect } from "react";
import PropTypes from "prop-types";

function StudyLevels({ component, interest, studyLevelTilesLoadCallback }) {
  const studyLevels = [
    {
      title: "Higher education",
      slug: "higher-education",
    },
    {
      title: "Vocational (TAFE)",
      slug: "vocational",
    },
    {
      title: "See all courses",
      slug: "all-courses",
    },
  ];

  useEffect(() => {
    // If this component is called from somewhere else not related to our callback, exit
    if (!studyLevelTilesLoadCallback) {
      return;
    }
    // If no error, call the callback function with true, otherwise with false
    studyLevelTilesLoadCallback(true);
  });

  const Component = component;
  return studyLevels.map((studyLevel) => (
    <Component
      key={studyLevel.slug}
      interest={interest}
      studyLevel={studyLevel}
      className="study-level-page-content-tile" /* ref={componentRefCallback} */
    />
  ));
}

StudyLevels.propTypes = {
  component: PropTypes.func.isRequired,
};

export default StudyLevels;
