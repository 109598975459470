import styled from 'styled-components';

const SharePopup = styled.div`
  margin: auto;
  width: 300px;
  padding: 45px 60px 60px;
  background-color: white;
  position: relative;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 84, 0.17);
  @media only screen and (min-width: 900px) {
    width: 640px;
  }
  > button.no-border {
    position: absolute;
    right: 30px;
    top: 10px;
    padding: 0;
    z-index: 1;
  }
  h4 {
    font-family: 'Museo700';
    position: relative;
    padding: 1em 0 0 0;
    margin: 0 0 0.5em 0;
    &:before {
      content: '—';
      position: absolute;
      top: 0;
      left: 0px;
      color: ${props => props.theme.highlightBlue};
    }
  }
  .share__input-group {
    display: flex;
    margin-top: 30px;
  }
  .share__input-field {
    flex: 1 0 auto;
    padding: 10px 30px;
    font-size: 16px;
    line-height: 24px;
    font-family: 'Museo700';
    color: ${props => props.theme.textPrimary};
    border-radius: 25px;
    height: 50px;
    border: 1px solid rgba(1, 153, 230, 0.5);
    box-shadow: 0 2px 8px 0 rgba(0, 0, 84, 0.17);
    &:focus {
      outline: none;
    }
  }
`;

export default SharePopup;
