import React from 'react';
import { Link } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';
import breakpoint from '../lib/breakpoints';

const LogoWrap = styled.div`
  position: absolute;
  z-index: 30;
  background: ${props => props.theme.textDark};
  a {
    display: flex;
    padding: 12px 12px 12px 90px;
    ${breakpoint.up('m')`
        padding: 18px 30px 18px 25px;
      `};
    svg {
      width: 74px;
      height: 26px;
      ${breakpoint.up('m')`
        width: 130px;
        height: 45px;
      `};
    }
  }
`;

function Logo() {
  return (
    <LogoWrap className="logo">
      <Link to="/">
        <SVG src={process.env.PUBLIC_URL + '/logo.svg'} />
      </Link>
    </LogoWrap>
  );
}

export default Logo;
