import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "react-apollo-hooks";
import gql from "graphql-tag";
import SVG from "react-inlinesvg";
import { withRouter } from "react-router-dom";

import ModalWrap from "./ModalWrap";
import ModalInner from "./ModalInner";
import ModalHeader from "./ModalHeader";
import ModalSubHeader from "./ModalSubHeader";
import ModalBanner from "./ModalBanner";
import ModalAside from "./ModalAside";
import ModalContent from "./ModalContent";
import ModalClose from "./ModalClose";
import HeaderText from "./HeaderText";
import Button from "./Button";
import ContactWrap from "./ContactWrap";
import ContactIntro from "./ContactIntro";
import ContactCards from "./ContactCards";
import ContactCard from "./ContactCard";
import ContactInnerInfo from "./ContactInnerInfo";
import ContactInnerForm from "./ContactInnerForm";
import ContactInnerLocation from "./ContactInnerLocation";

import SearchField from "./SearchField";
import SearchNoResults from "./SearchNoResults";

const SEARCH_ACTIVE_QUERY = gql`
  query {
    searchActive @client
  }
`;

const TOGGLE_SEARCH_MUTATION = gql`
  mutation toggleSearch($searchActive: Boolean!) {
    toggleSearch(searchActive: $searchActive) @client
  }
`;

const SET_SEARCH_NO_PATHWAY = gql`
  mutation setSearchNoPathway($searchNoPathway: String!) {
    setSearchNoPathway(searchNoPathway: $searchNoPathway) @client
  }
`;

function SearchModal({ location }) {
  const searchActiveQuery = useQuery(SEARCH_ACTIVE_QUERY);
  const toggleSearch = useMutation(TOGGLE_SEARCH_MUTATION);
  const setSearchNoPathway = useMutation(SET_SEARCH_NO_PATHWAY);
  const [searchFocus, setSearchFocus] = useState(false);
  const updateSearchFocus = (toggle) => {
    setSearchFocus(toggle);
  };

  useEffect(() => {
    toggleSearch({
      variables: { searchActive: false },
    });
    setSearchNoPathway({
      variables: { searchNoPathway: [] },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // listen for modal change -> if modal active -> listen for keydown -> if keydown -> close modal
  const checkModalStatus = (event) => {
    if (searchActiveQuery.data.searchActive) {
      // if modal active
      addKeyDownListener();
    } else {
      // if modal not active
      removeKeyDownListener();
    }
  };

  const removeKeyDownListener = function () {
    setTimeout(() => {
      document.removeEventListener("keydown", checkDocumentKeyDown);
    });
  };

  const addKeyDownListener = function () {
    setTimeout(() => {
      document.addEventListener("keydown", checkDocumentKeyDown);
    });
  };

  const checkDocumentKeyDown = (event) => {
    if (event.key === "Escape" || event.key === "Esc" || event.keyCode === 27) {
      toggleSearch({
        variables: { searchActive: false },
      });
    }
  };

  checkModalStatus();

  return (
    <ModalWrap className={searchActiveQuery.data.searchActive && "active"}>
      <ModalInner>
        <ModalClose>
          <Button
            type="button"
            className="white no-border has-svg "
            onClick={() => {
              toggleSearch({
                variables: { searchActive: false },
              });
              setSearchNoPathway({
                variables: { searchNoPathway: [] },
              });
            }}
          >
            <span className="text">Close</span>
            <SVG src={process.env.PUBLIC_URL + "/icons/cross.svg"} />
          </Button>
        </ModalClose>
        <ModalHeader>
          <HeaderText>
            <span className="text no-icon">Search to find your course</span>
          </HeaderText>
        </ModalHeader>
        <ModalBanner className="short" />
        <ModalSubHeader className="short">
          <SearchField
            searchFocus={searchFocus}
            setSearchFocus={updateSearchFocus}
          />
        </ModalSubHeader>
        <ModalContent>
          <SearchNoResults setSearchFocus={updateSearchFocus} />
        </ModalContent>
        <ModalAside>
          <ContactWrap type="contact-wrap--sidebar">
            <ContactIntro>
              <h2>Contact us</h2>
              <p>
                If you have any questions about pathways please contact
                Study@RMIT. Our advisors are available with extended hours
                during the Change of Preference period.
              </p>
            </ContactIntro>
            <ContactCards type="contact-cards--column">
              <ContactCard type="card--no-border" icon="communication">
                <ContactInnerInfo />
              </ContactCard>
              <ContactCard type="card--no-border" icon="information-technology">
                <ContactInnerForm />
              </ContactCard>
              <ContactCard type="card--no-border" icon="navy-global">
                <ContactInnerLocation />
              </ContactCard>
            </ContactCards>
          </ContactWrap>
        </ModalAside>
      </ModalInner>
    </ModalWrap>
  );
}

export default withRouter(SearchModal);
export { SEARCH_ACTIVE_QUERY, TOGGLE_SEARCH_MUTATION };
