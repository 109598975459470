import styled from 'styled-components';

const CircleButton = styled.button`
  display: inline-block;
  width: 50px;
  height: 50px;
  padding: 0;
  border: 0;
  border-radius: 25px;
  line-height: 48px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  .isvg {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  svg {
    width: 16px;
    height: 16px;
  }
  &:focus {
    outline: none;
  }
  &.white {
    background: white;
    color: ${props => props.theme.highlightRed};
    box-shadow: 0 2px 7px 0 #cdcdcd;
    border: 2px solid white;
    &:active {
      background: ${props => props.theme.highlightRed};
      color: white;
      border-color: ${props => props.theme.highlightRed};
      svg {
        path {
          fill: white;
        }
      }
    }
    &:focus {
      border-color: ${props => props.theme.highlightRed};
    }
  }
  &.blue {
    background: ${props => props.theme.highlightBlue};
    box-shadow: 0 3px 7px 0 rgba(1, 153, 230, 0.5);
    color: white;
    svg {
      position: relative;
      path {
        fill: white;
      }
    }
    &:hover {
      background: ${props => props.theme.otherBlue};
    }
    &:focus {
      background: ${props => props.theme.darkBlue};
    }
    &:active {
      background: ${props => props.theme.textPrimary};
    }
  }
  &.share {
    background: white;
    box-shadow: 0 2px 7px 0 #cdcdcd;
    border: 2px solid white;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    .isvg {
      display: flex;
      margin: auto;
    }
    .isvg, svg {
      width: 30px;
      height: 30px;
    }
    &:hover {
      background: ${props => props.theme.highlightBlue};
      border-color: ${props => props.theme.highlightBlue};
      svg {
        path {
          fill: white;
        }
      }
    }
    &:focus {
      border-color: ${props => props.theme.highlightBlue};
    }
    &:active {
      background: ${props => props.theme.textPrimary};
      border-color: ${props => props.theme.textPrimary};
      svg {
        path {
          fill: white;
        }
      }
    }
    
  }
`;

export default CircleButton;
