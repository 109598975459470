import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styled from "styled-components";
import CardListItem from "./CardListItem";
import GuaranteedPathwaysTooltip from "./GuaranteedPathwaysTooltip";

import { SORT_STEPS_IN_ORDER_OF } from "../lib/consts";

const PathwayItemHeader = styled.div`
  padding: 15px;
  display: flex;
  justify-content: space-between;

  position: relative;
  @media only screen and (min-width: 375px) {
    padding: 15px 30px;
  }
  @media only screen and (min-width: 900px) {
    padding: 25px 25px 25px 20px;
    border-left: 5px solid ${(props) => props.theme.highlightBlue};
  }
  h3 {
    flex: 0 1 100%;
    margin-bottom: 0;
    color: ${(props) => props.theme.textDark};
  }
  span {
    flex: 1 0 120px;
    text-align: right;
    color: ${(props) => props.theme.textDark};
  }
  &:before {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0px;
    width: 100%;
    border-bottom: 1px solid rgba(205, 205, 205, 0.5);
  }

  &.ve-pathway {
    @media only screen and (min-width: 900px) {
      border-left: 5px solid ${(props) => props.theme.orange};
    }
  }

  &.he-pathway {
    @media only screen and (min-width: 900px) {
      border-left: 5px solid ${(props) => props.theme.lavender};
    }
  }
`;
const PathwayItemSteps = styled.div`
  padding: 15px;
  position: relative;
  @media only screen and (min-width: 375px) {
    padding: 15px 30px;
  }
  @media only screen and (min-width: 900px) {
    padding: 25px 25px 25px 20px;
  }
  &.ve-pathway {
    @media only screen and (min-width: 900px) {
      border-left: 5px solid ${(props) => props.theme.orange};
    }
  }

  &.he-pathway {
    @media only screen and (min-width: 900px) {
      border-left: 5px solid ${(props) => props.theme.lavender};
    }
  }
  /* dotted line */
  &:before {
    content: "";
    position: absolute;
    left: 19px;
    top: 40px;
    width: 2px;
    height: calc(100% - 80px);
    border-left: 2px dotted ${(props) => props.theme.textDark};
    @media only screen and (min-width: 375px) {
      left: 34px;
    }
    @media only screen and (min-width: 900px) {
      left: 25px;
      top: 50px;
      height: calc(100% - 100px);
    }
  }
  .pathway__step {
    padding: 12px 0 12px 25px;
    display: flex;
    justify-content: space-between;
    position: relative;
    @media only screen and (min-width: 900px) {
      padding: 15px 0 15px 25px;
    }
    &-title {
      font-family: "HelveticaNeue-Medium", Helvetica, Arial, sans-serif;
      flex: 0 1 100%;
      /* circle */
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 18px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: white;
        border: 2px solid ${(props) => props.theme.textDark};
        @media only screen and (min-width: 900px) {
          width: 12px;
          height: 12px;
          top: 20px;
        }
      }
    }
    &:first-of-type .pathway__step-title {
      &:before {
        background-color: ${(props) => props.theme.textDark};
      }
    }
    &:last-of-type {
      .pathway__step-title {
        /* cover the end of the dotted line with white */
        &:after {
          content: "";
          position: absolute;
          left: 4px;
          top: 28px;
          width: 4px;
          height: calc(100% - 40px);
          background-color: white;
          @media only screen and (min-width: 900px) {
            top: 34px;
            height: calc(100% - 50px);
          }
        }
      }
    }
    &-duration {
      padding-left: 40px;
      flex: 1 0 120px;
      font-family: "HelveticaNeue-Medium", Helvetica, Arial, sans-serif;
      text-align: right;
      display: none;
      @media only screen and (min-width: 900px) {
        display: inline-block;
      }
    }
  }
`;
const PathwayItemFooter = styled.div`
  padding: 0px 25px 25px 15px;
  display: flex;
  flex-wrap: wrap;
  @media only screen and (min-width: 375px) {
    padding: 0px 25px 25px 25px;
  }
  @media only screen and (min-width: 700px) {
    padding: 0px 25px 25px 25px;

    justify-content: space-between;
  }
  @media only screen and (min-width: 900px) {
    padding: 0px 25px 35px 15px;
  }
  &.ve-pathway {
    @media only screen and (min-width: 900px) {
      border-left: 5px solid ${(props) => props.theme.orange};
    }
  }

  &.he-pathway {
    @media only screen and (min-width: 900px) {
      border-left: 5px solid ${(props) => props.theme.lavender};
    }
  }
  a {
    display: inline-block;
    height: 50px;
    padding: 0 35px;
    margin: 5px;
    border: 0;
    border-radius: 25px;
    line-height: 48px;
    font-size: 16px;
    text-align: center;
    font-family: "Museo500", "Century Gothic", CenturyGothic, AppleGothic, Arial,
      Helvetica, sans-serif;
    background: ${(props) => props.theme.textPrimary};
    color: white;
    cursor: pointer;
    @media only screen and (max-width: 899px) {
      width: 100%;
      max-width: 335px;
    }
    &:hover {
      span {
        text-decoration: underline;
      }
    }
    &:active {
      background: ${(props) => props.theme.darkerBlue};
      color: white;
    }
    &:focus {
      background: ${(props) => props.theme.darkerBlue};
    }
  }
`;

function PathwayListItem({ index, pathway, className }) {
  const studyLevel = pathway.goalCourse.level.includes("vocational")
    ? "ve-pathway"
    : "he-pathway";

  const alphaIndex =
    index < 26
      ? String.fromCharCode(index + 65)
      : String.fromCharCode(65) + String.fromCharCode(index - 26 + 65);
  return (
    <CardListItem className={className}>
      <PathwayItemHeader className={studyLevel}>
        <h3 className="gamma">{`Pathway ${alphaIndex}`}</h3>
        <span className="gamma">
          {`${
            pathway.steps &&
            pathway.steps.map((step) => step.duration).reduce((a, c) => a + c) +
              pathway.goalDuration
          } years*`}
        </span>
      </PathwayItemHeader>
      <PathwayItemSteps className={studyLevel}>
        {pathway.steps &&
          pathway.steps
            .sort((a, b) => {
              return (
                SORT_STEPS_IN_ORDER_OF.indexOf(a.course.level) -
                SORT_STEPS_IN_ORDER_OF.indexOf(b.course.level)
              );
            })
            .map((step) => (
              <div className="pathway__step" key={step.course.codeUnique}>
                <span className="omega pathway__step-title">
                  {step.course.title}
                </span>
                <span className="omega pathway__step-duration">
                  {`${step.duration} ${step.duration > 1 ? "years" : "year"}`}
                </span>
              </div>
            ))}
        <div className="pathway__step">
          <span className="omega pathway__step-title">
            {pathway.goalCourse.title}
          </span>
          <span className="omega pathway__step-duration">
            {`${pathway.goalDuration} ${
              pathway.goalDuration > 1 ? "years" : "year"
            }`}
          </span>
        </div>
      </PathwayItemSteps>
      <PathwayItemFooter className={studyLevel}>
        <Link to={`/pathway/${pathway.slug.toUpperCase()}`}>
          <span className="text">Show pathway details</span>
        </Link>
        {pathway.guaranteedPathway && (
          <GuaranteedPathwaysTooltip tooltipPosition="right" />
        )}
      </PathwayItemFooter>
    </CardListItem>
  );
}

PathwayListItem.propTypes = {
  index: PropTypes.number.isRequired,
  pathway: PropTypes.shape({
    id: PropTypes.string,
    steps: PropTypes.array.isRequired,
    guaranteedPathway: PropTypes.bool,
    VEPathway: PropTypes.bool,
  }).isRequired,
};

export default PathwayListItem;
