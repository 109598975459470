import React from "react";

import SVG from "react-inlinesvg";
import { Controller, Scene } from "react-scrollmagic";
import { Link } from "react-router-dom";
import SubHeaderText from "./SubHeaderText";
import AnimImageWrapper from "./AnimImageWrapper";
import {
  ContentBlockWrap,
  ContentBlockItem,
  ContentBlockImageWrap,
  ContentBlockImage,
  ImageForeground,
  ContentBlockTextWrap,
  AnimFadeDown,
} from "./styles/ContentBlock";

function HomeWhyRMITPathways() {
  return (
    <ContentBlockWrap id="trigger__guaranteed-pathways">
      <Controller>
        <Scene
          triggerElement={"#trigger__guaranteed-pathways"}
          reverse={false}
          triggerHook={0}
          offset={-400}
          duration="100%"
        >
          {(progress) => (
            <>
              <ContentBlockItem imagePos={"right"}>
                <ContentBlockImageWrap>
                  <AnimImageWrapper
                    reveal={progress > 0 && true}
                    translateFrom={"translateY(-60px)"}
                    translateTo={"translateY(0)"}
                    background={"white"}
                  >
                    <ContentBlockImage progress={progress}>
                      <ImageForeground
                        className="content-block__foreground"
                        src={`${process.env.PUBLIC_URL}/guaranteed-pathways.jpg`}
                      ></ImageForeground>
                    </ContentBlockImage>
                  </AnimImageWrapper>
                </ContentBlockImageWrap>
                <ContentBlockTextWrap>
                  <AnimFadeDown
                    className={[
                      "fade-down-item",
                      progress > 0 ? "fade-down-item--in" : "",
                    ]}
                  >
                    <SubHeaderText className="beta beta--home red">
                      Guaranteed Pathways
                    </SubHeaderText>
                    <p className="delta">
                      These convenient, packaged pathways let you enrol in a
                      preliminary qualification and your goal degree at the same
                      time. Using the credit points from your preliminary
                      qualification, you’ll complete the packaged pathway in the
                      time it would take if you studied the goal degree as a
                      stand-alone course.
                    </p>
                    <Link to="/interests" className="delta chevron-link">
                      <SVG
                        src={`${process.env.PUBLIC_URL}/icons/arrow-right.svg`}
                      />
                      <span className="text">Find Guaranteed Pathways</span>
                    </Link>
                  </AnimFadeDown>
                </ContentBlockTextWrap>
              </ContentBlockItem>
            </>
          )}
        </Scene>
      </Controller>
    </ContentBlockWrap>
  );
}

export default HomeWhyRMITPathways;
