import React from "react";
import PropTypes from "prop-types";
import SVG from "react-inlinesvg";

import Button from "./Button";
import SubHeaderText from "./SubHeaderText";
import Share from "./Share";
import PathwayDetailWrap from "./styles/PathwayDetailWrap";
import PathwayOptionSteps from "./styles/PathwayOptionSteps";
import GuaranteedPathwaysTooltip from "./GuaranteedPathwaysTooltip";

import { SORT_STEPS_IN_ORDER_OF } from "../lib/consts";

function Pathway({ pathway, ...otherProps }) {
  return (
    <PathwayDetailWrap>
      <Share pathway={pathway} id={otherProps.shareId} />
      <SubHeaderText className="blue" id={otherProps.topHeaderId}>
        {`Start the pathway to ${pathway.goalCourse.title}`}
      </SubHeaderText>
      {pathway.guaranteedPathway && (
        <div id={otherProps.tooltipId}>
          <GuaranteedPathwaysTooltip tooltipPosition="left" />
        </div>
      )}
      <h3 className="omega" id={otherProps.topSubHeaderId}>
        {`Total pathway study time - ${
          pathway.steps &&
          pathway.steps.map((step) => step.duration).reduce((a, c) => a + c) +
            pathway.goalDuration
        } years*`}
      </h3>
      <p id={otherProps.topPId}>
        Pathways follow a series of steps to help you reach your degree, each
        course in the journey has unique entry requirements to progress. Follow
        the links below to find out more details on each course.
      </p>
      <p id={otherProps.secondPId}>
        To get started on a pathway, you'll need to apply for the first course
        in the journey. If you’re unsure, please contact{" "}
        <a
          href="https://www.rmit.edu.au/contact/online-enquiries/local-student-enquiries#local"
          target="_blank"
          rel="noopener noreferrer"
        >
          Study@RMIT
        </a>
        .
      </p>
      <a
        href="https://www.rmit.edu.au/study-with-us/applying-to-rmit/local-student-applications"
        className="chevron-link"
        target="_blank"
        rel="noopener noreferrer"
        id={otherProps.howToId}
      >
        <SVG src={`${process.env.PUBLIC_URL}/icons/arrow-right.svg`} />
        <span className="text">How to apply</span>
      </a>
      <PathwayOptionSteps>
        {pathway.steps &&
          pathway.steps
            .sort((a, b) => {
              return (
                SORT_STEPS_IN_ORDER_OF.indexOf(a.course.level) -
                SORT_STEPS_IN_ORDER_OF.indexOf(b.course.level)
              );
            })
            .map((step, index) => {
              return (
                <div
                  className={
                    "pathway-step__item " + otherProps.pathwayItemClass
                  }
                  key={step.course.id}
                >
                  <h4 className="delta pathway-step__title">
                    {step.course.title}
                    {step.course.ncc && ` - ${step.course.ncc}`}
                  </h4>
                  <div className="pathway-step__inner">
                    {index === 0 &&
                      step.course.atar &&
                      // eslint-disable-next-line no-restricted-globals
                      !isNaN(step.course.atar) && (
                        <p className="pathway-step__requirements">
                          {`The indicative ATAR for this course is ${step.course.atar}. ATARs reflect the previous year’s entry and are subject to change.`}
                        </p>
                      )}
                    <div className="pathway-step__details">
                      <div className="pathway-step__details-item">
                        <h5 className="omega">Duration</h5>
                        <p>
                          {`${step.duration} ${
                            step.duration > 1 ? "years*" : "year*"
                          }`}
                        </p>
                      </div>
                      <div className="pathway-step__details-item">
                        <h5 className="omega">Campus</h5>
                        <p>{step.course.campus}</p>
                      </div>
                    </div>
                    {step.course.description && (
                      <>
                        <h5 className="omega">Details</h5>
                        <p>{step.course.description}</p>
                      </>
                    )}
                    {index === 0 && step.course.prerequisites && (
                      <>
                        <h5 className="omega">Basic entry requirements</h5>
                        <p>{step.course.prerequisites}</p>
                      </>
                    )}
                    {index > 0 && (
                      <>
                        <h5 className="omega">
                          Pathway requirements and details
                        </h5>
                        {step.prerequisites ? (
                          <p>{step.prerequisites}</p>
                        ) : (
                          <p>Successful completion of previous step.</p>
                        )}
                      </>
                    )}
                    {step.course.selectionTasks && (
                      <>
                        <h5 className="omega">Selection Tasks</h5>
                        <p>
                          This course has a selection task requirement.{" "}
                          <a
                            href={`${step.course.url}#admissions`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View course page for details
                          </a>
                          .
                        </p>
                      </>
                    )}
                    <a
                      href={`${step.course.url}#admissions`}
                      className="chevron-link chevron-link--external"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <SVG
                        src={`${process.env.PUBLIC_URL}/icons/arrow-right.svg`}
                      />
                      <span className="text">
                        View full entry requirements
                        <SVG
                          src={`${process.env.PUBLIC_URL}/icons/external-link.svg`}
                        />
                      </span>
                    </a>
                  </div>
                  <a
                    className="blue"
                    href={step.course.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="text">
                      Full course details
                      <SVG
                        src={`${process.env.PUBLIC_URL}/icons/external-link.svg`}
                      />
                    </span>
                  </a>
                </div>
              );
            })}
        <div className={"pathway-step__item " + otherProps.pathwayItemClass}>
          <h4 className="delta pathway-step__title">
            {pathway.goalCourse.title}
          </h4>
          <div className="pathway-step__inner">
            <div className="pathway-step__details">
              <div className="pathway-step__details-item">
                <h5 className="omega">Duration</h5>
                <p>{`${pathway.goalDuration} ${
                  pathway.goalDuration > 1 ? "years*" : "year*"
                }`}</p>
              </div>
              <div className="pathway-step__details-item">
                <h5 className="omega">Campus</h5>
                <p>{pathway.goalCourse.campus}</p>
              </div>
            </div>
            {pathway.goalCourse.description && (
              <>
                <h5 className="omega">Details</h5>
                <p>{pathway.goalCourse.description}</p>
              </>
            )}
            <h5 className="omega">Pathway requirements and details</h5>
            {pathway.goalPrerequisites ? (
              <p>{pathway.goalPrerequisites}</p>
            ) : (
              <p>Successful completion of previous step.</p>
            )}
            {pathway.goalCourse.selectionTasks && (
              <>
                <h5 className="omega">Selection Tasks</h5>
                <p>
                  This course has a selection task requirement.{" "}
                  <a
                    href={`${pathway.goalCourse.url}#admissions`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View course page for details
                  </a>
                  .
                </p>
              </>
            )}
            <a
              href={`${pathway.goalCourse.url}#admissions`}
              className="chevron-link chevron-link--external"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SVG src={`${process.env.PUBLIC_URL}/icons/arrow-right.svg`} />
              <span className="text">
                View full entry requirements
                <SVG
                  src={`${process.env.PUBLIC_URL}/icons/external-link.svg`}
                />
              </span>
            </a>
          </div>
          <Button
            className="blue"
            as="a"
            href={pathway.goalCourse.url}
            target="_blank"
          >
            <span className="text">
              Full course details
              <SVG src={`${process.env.PUBLIC_URL}/icons/external-link.svg`} />
            </span>
          </Button>
        </div>
        <p className="disclaimer" id={otherProps.disclaimerId}>
          *Based on full-time study load of 96 credit points per year.
          Individual circumstances may vary and please be aware that not all
          courses are offered each semester. These circumstances may affect the
          total duration of a program.
        </p>
      </PathwayOptionSteps>
    </PathwayDetailWrap>
  );
}

Pathway.propTypes = {
  pathway: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
};

export default Pathway;
