/**
 * This class handles animations of pathway page.
 *
 */
import anime from "animejs";

export default class pathwayAnimation {
  constructor(animationService) {
    this.animationService = animationService;
    this.pathwayPageId = "pathway-page";
  }

  _hidePathwayPage() {
    this.animationService._hideItemById(this.pathwayPageId);
  }

  _showPathwayPage() {
    this.animationService._showItemById(this.pathwayPageId);
  }

  _initPathwayPageEnterAnimation() {
    // Set indexes based on their position in the animation queue.
    this.pathwayPageFirstEnterAnimIndex = 0;

    this.pathwayPageEnterAnimQueue = [
      {
        animObject: anime.timeline({
          easing: "cubicBezier(0.3, 0, 0.3, 1)",
        }),
        addAnimation: this._addPathwayPageFirstAnimation.bind(this),
        readyFlags: {
          content: false,
          options: false,
        },
        domReady: false,
        began: false,
        added: false,
      },
    ];
  }

  runPathwayPageExitAnimation(match) {
    // Set this flag, so no entering animation is added anymore
    this.animationService.exitAnimationRunning = true;
    // Init enter animation object
    this.animationService.animationsByPath[match.path].initEnterAnimation(true);
    // Hide entering page
    this.animationService.animationsByPath[match.path].hidePage();
    this.pathwayPageExitAnime = anime.timeline({
      easing: "cubicBezier(0.3, 0, 0.3, 1)",
    });
    let sidebarOptionsItems = document.getElementsByClassName(
      "pathway-page-pathway-item"
    );
    let targetPrefix = "";
    // If the initial URL has been changed by user by clicking on an item in the sidebar, element ids and classes will have an "exit-" prefix
    if (!sidebarOptionsItems.length) {
      targetPrefix = "exit-";
      sidebarOptionsItems = document.getElementsByClassName(
        `${targetPrefix}pathway-page-pathway-item`
      );
    }

    // Build an array of elements for sidebar animation
    sidebarOptionsItems =
      this.animationService._getReverseArrayOfHTMLCollection(
        sidebarOptionsItems
      );
    let sidebarHeader = document.getElementById("pathway-page-sidebar-header");
    if (sidebarHeader) {
      sidebarOptionsItems.push(sidebarHeader);
    }

    // Build an array of elements for main content animation
    let contentTargetArray = [];
    let pathwayItems = document.getElementsByClassName(
      `${targetPrefix}pathway-page-content-pathway-item`
    );
    pathwayItems =
      this.animationService._getReverseArrayOfHTMLCollection(pathwayItems);
    let contentContact = document.getElementById(
      `pathway-page-content-contact`
    );
    let contentHr = document.getElementById(`pathway-page-content-hr`);
    let contentDisclaimer = document.getElementById(
      `${targetPrefix}pathway-page-content-disclaimer`
    );
    let contentHowTo = document.getElementById(
      `${targetPrefix}pathway-page-content-how-to`
    );
    let contentSecondP = document.getElementById(
      `${targetPrefix}pathway-page-content-second-p`
    );
    let contentTopP = document.getElementById(
      `${targetPrefix}pathway-page-content-top-p`
    );
    let contentTopGP = document.getElementById(
      `${targetPrefix}pathway-page-content-top-gp`
    );
    let contentSubHeader = document.getElementById(
      `${targetPrefix}pathway-page-content-top-subheader`
    );
    if (contentContact) {
      contentTargetArray.push(contentContact);
    }
    if (contentHr) {
      contentTargetArray.push(contentHr);
    }
    if (contentDisclaimer) {
      contentTargetArray.push(contentDisclaimer);
    }
    contentTargetArray = contentTargetArray.concat(pathwayItems);
    if (contentHowTo) {
      contentTargetArray.push(contentHowTo);
    }
    if (contentSecondP) {
      contentTargetArray.push(contentSecondP);
    }
    if (contentTopP) {
      contentTargetArray.push(contentTopP);
    }
    if (contentSubHeader) {
      contentTargetArray.push(contentSubHeader);
    }
    if (contentTopGP) {
      contentTargetArray.push(contentTopGP);
    }

    this.pathwayPageExitAnime
      .add({
        targets: "#exit-vertical-nav-bar-pathway",
        backgroundColor: this.animationService.textDark,
        color: "#fff",
        opacity: 0,
        duration: 300,
      })
      // Sidebar items
      .add({
        targets: sidebarOptionsItems,
        translateY: [0, -25],
        opacity: [1, 0],
        duration: 300,
        delay: anime.stagger(100),
      })
      // Content - items
      .add(
        {
          targets: contentTargetArray,
          translateY: [0, -25],
          opacity: [1, 0],
          duration: 300,
          delay: anime.stagger(100),
        },
        0
      )
      // Content - header and share button
      .add(
        {
          targets: `#${targetPrefix}pathway-page-content-share, #${targetPrefix}pathway-page-content-top-header`,
          translateY: [0, -25],
          opacity: [1, 0],
          duration: 300,
        },
        "-=200"
      )
      // Page header
      .add(
        {
          targets: `#${targetPrefix}pathway-page-header-icon, #${targetPrefix}pathway-page-header-text`,
          translateY: [0, -25],
          opacity: [1, 0],
          duration: 300,
        },
        "-=200"
      );
    // Run the new page's enter animation when exit is finished
    this.pathwayPageExitAnime.finished.then(() => {
      this.animationService.exitAnimationRunning = false;
      this.animationService.animationsByPath[match.path].runEnterAnimation(
        true
      );
    });
  }

  endPathwayPageAnimation(node, done) {
    // // If animation object doesn't exist, call done and exit
    if (!this.pathwayPageExitAnime) {
      done();
      return;
    }
    // Call done when exit animation is finished
    this.pathwayPageExitAnime.finished.then(() => {
      done();
    });
  }

  runPathwayPageEnterAnimation(pageTransition = false) {
    // If it's not a page transition (it's a page load), init the animation object because it hasen't been already.
    if (!pageTransition) {
      this._initPathwayPageEnterAnimation();
    } else {
      this._showPathwayPage();
    }
    this.animationService._tryToAddAnimation(
      this.pathwayPageEnterAnimQueue,
      this.pathwayPageFirstEnterAnimIndex
    );
  }

  _addPathwayPageFirstAnimation() {
    this.pathwayPageEnterAnimQueue[
      this.pathwayPageFirstEnterAnimIndex
    ].animObject
      // Page header comes in
      .add({
        targets: "#pathway-page-header-text, #pathway-page-header-icon",
        translateY: [-25, 0],
        opacity: [0, 1],
        duration: 300,
      })
      // Nav bar - button comes in
      .add(
        {
          targets: "#vertical-nav-bar-pathway",
          opacity: 1,
          duration: 300,
        },
        0
      )
      // Sidebar items come in
      .add(
        {
          targets: "#pathway-page-sidebar-header, .pathway-page-pathway-item",
          translateY: [-25, 0],
          opacity: [0, 1],
          duration: 300,
          delay: anime.stagger(100),
        },
        100
      )
      // Main content - header and share button come in
      .add(
        {
          targets:
            "#pathway-page-content-share, #pathway-page-content-top-header",
          translateY: [-25, 0],
          opacity: [0, 1],
          duration: 300,
        },
        100
      )
      // Main content - other items come in
      .add(
        {
          targets:
            "#pathway-page-content-top-gp, #pathway-page-content-top-subheader,  #pathway-page-content-top-p, #pathway-page-content-second-p" +
            ", #pathway-page-content-how-to, .pathway-page-content-pathway-item, #pathway-page-content-disclaimer" +
            ", #pathway-page-content-hr, #pathway-page-content-contact",
          translateY: [-25, 0],
          opacity: [0, 1],
          duration: 300,
          delay: anime.stagger(100),
        },
        "-=200"
      )
      // Nav bar - highlight the active item
      .add(
        {
          targets: ".nav-item--active",
          backgroundColor: this.animationService.themeHighlightBlue,
          color: this.animationService.textDark,
          duration: 300,
        },
        300
      );
  }

  addItemsToPathwayPageEnterAnimation(success, itemKey) {
    if (!success) {
      return;
    }
    // Exit if the animation has already been added
    if (
      this.pathwayPageEnterAnimQueue[this.pathwayPageFirstEnterAnimIndex].added
    ) {
      return;
    }
    this.animationService._tryToSetDomReady(
      this.pathwayPageEnterAnimQueue[this.pathwayPageFirstEnterAnimIndex],
      itemKey
    );
    // If an exit animation is running, exit
    if (this.animationService.exitAnimationRunning) {
      return;
    }
    this.animationService._tryToAddAnimation(
      this.pathwayPageEnterAnimQueue,
      this.pathwayPageFirstEnterAnimIndex
    );
  }
}
