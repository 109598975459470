import React from "react";
import SVG from "react-inlinesvg";
import styled from "styled-components";
import { Controller, Scene } from "react-scrollmagic";

import HomeHero from "../components/HomeHero";
import FaqsHomeButton from "../components/FaqsHomeButton";
import SearchHomeButton from "../components/SearchHomeButton";
import HomeWhyRMITPathways from "../components/HomeWhyRMITPathways";
import HomeGuaranteedPathways from "../components/HomeGuaranteedPathways2";
import HomeVocational from "../components/HomeVocational";
import HomeUserSelection from "../components/HomeUserSelection";
import HomeFaqs from "../components/HomeFaqs";
import HomeContact from "../components/HomeContact";
import Footer from "../components/Footer";

const HomepageWrap = styled.main`
  display: inline-block;
  width: 100%;
`;

const HomeNav = styled.div`
  margin: 0 20px;
  padding-top: 20px;
  position: relative;
  display: flex;
  justify-content: space-between;
  @media only screen and (min-width: 900px) {
    margin: 0 3vw;
    padding-top: 30px;
  }
  &.fade-down__items {
    .fade-down__item {
      opacity: 0;
      transform: translateY(-25px);
      z-index: 10;
      &.fade-down__item {
        transition: all 400ms 800ms cubic-bezier(0.3, 0, 0.3, 1);
      }
    }
    &--in {
      .fade-down__item {
        opacity: 1;
        transform: translateY(0px);
      }
      + .hero__wrap:before {
        z-index: -100;
        height: calc(100% + 100px);
      }
    }
  }
`;

const LogoWrap = styled.div`
  width: 120px;
  margin-right: 3vw;
  @media only screen and (min-width: 900px) {
    width: 142px;
  }
  @media only screen and (min-width: 1200px) {
    width: 189px;
  }
  svg {
    width: 100%;
  }
`;

const HomeButtonWrap = styled.div`
  text-align: right;
  @media only screen and (min-width: 900px) {
    text-align: left;
    margin: 0;
    position: absolute;
    top: 30px;
    right: 0px;
    display: flex;
  }
`;

const HomePage = () => (
  <HomepageWrap className="content-wrap" id="trigger__home-nav">
    <Controller>
      <Scene
        triggerElement={"#trigger__home-nav"}
        reverse={false}
        triggerHook={0}
        offset="-100"
        duration="100%"
      >
        {(progress, event) => (
          <HomeNav
            className={[
              "fade-down__items",
              progress > 0 ? "fade-down__items--in" : "",
            ]}
          >
            <LogoWrap className="fade-down__item">
              <SVG src={`${process.env.PUBLIC_URL}/icons/primary-logo.svg`} />
            </LogoWrap>
            <HomeButtonWrap className="fade-down__item">
              <FaqsHomeButton />
              <SearchHomeButton />
            </HomeButtonWrap>
          </HomeNav>
        )}
      </Scene>
    </Controller>

    <HomeHero />
    <HomeWhyRMITPathways />
    <HomeGuaranteedPathways />
    <HomeVocational />
    <HomeUserSelection />
    <HomeFaqs />
    <HomeContact />
    <Footer className="footer--home" />
  </HomepageWrap>
);

export default HomePage;
