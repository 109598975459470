import React, { useEffect } from "react";
import { useQuery, useMutation } from "react-apollo-hooks";
import gql from "graphql-tag";
import SVG from "react-inlinesvg";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import ModalWrap from "./ModalWrap";
import ModalInner from "./ModalInner";
import ModalHeader from "./ModalHeader";

import ModalGridBanner from "./ModalGridBanner";
import ModalAside from "./ModalAside";
import ModalContent from "./ModalContent";
import ModalClose from "./ModalClose";
import HeaderText from "./HeaderText";
import SubHeaderText from "./SubHeaderText";

import Button from "./Button";

const STUDY_LEVEL_MODAL_ACTIVE_QUERY = gql`
  query {
    studyLevelModalActive @client
  }
`;

const TOGGLE_STUDY_LEVEL_MODAL_MUTATION = gql`
  mutation toggleStudyLevelModal($studyLevelModalActive: Boolean!) {
    toggleStudyLevelModal(studyLevelModalActive: $studyLevelModalActive) @client
  }
`;

const StyledModalHeader = styled(ModalHeader)`
  p {
    max-width: 550px;
  }
`;

const StyledContentInner = styled.div`
  margin-bottom: 40px;
  @media screen and (min-width: 1200px) {
    margin-bottom: 80px;
  }
  p {
    max-width: 550px;
  }
  ul {
    padding-left: 20px;
    margin-bottom: 30px;
  }
  li {
    margin-top: 20px;
  }
  a.chevron-link {
    margin-bottom: 20px;
    display: block;
  }
`;

const StyledAside = styled(ModalAside)`
  @media only screen and (min-width: 1200px) {
    grid-row-start: 1;
  }
`;

const StyledImage = styled.picture`
  position: relative;
  width: 100%;

  img {
    width: 100%;
    height: auto;
    max-width: 550px;
    @media only screen and (min-width: 1200px) {
      transform: translateY(-148px);
    }
  }
`;

function StudyLevelModal({ location }) {
  const studyLevelActiveQuery = useQuery(STUDY_LEVEL_MODAL_ACTIVE_QUERY);
  const toggleStudyLevelModal = useMutation(TOGGLE_STUDY_LEVEL_MODAL_MUTATION);
  useEffect(() => {
    toggleStudyLevelModal({
      variables: { studyLevelModalActive: false },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // listen for modal change -> if modal active -> listen for keydown -> if keydown -> close modal
  const checkModalStatus = (event) => {
    if (studyLevelActiveQuery.data.studyLevelModalActive) {
      // if modal active
      addKeyDownListener();
    } else {
      // if modal not active
      removeKeyDownListener();
    }
  };

  const removeKeyDownListener = function () {
    setTimeout(() => {
      document.removeEventListener("keydown", checkDocumentKeyDown);
    });
  };

  const addKeyDownListener = function () {
    setTimeout(() => {
      document.addEventListener("keydown", checkDocumentKeyDown);
    });
  };

  const checkDocumentKeyDown = (event) => {
    if (event.key === "Escape" || event.key === "Esc" || event.keyCode === 27) {
      toggleStudyLevelModal({
        variables: { studyLevelModalActive: false },
      });
    }
  };

  checkModalStatus();

  return (
    <ModalWrap
      className={studyLevelActiveQuery.data.studyLevelModalActive && "active"}
    >
      <ModalInner>
        <ModalClose>
          <Button
            type="button"
            className="white no-border has-svg "
            onClick={() => {
              toggleStudyLevelModal({
                variables: { studyLevelModalActive: false },
              });
            }}
          >
            <span className="text">Close</span>
            <SVG src={process.env.PUBLIC_URL + "/icons/cross.svg"} />
          </Button>
        </ModalClose>
        <StyledModalHeader>
          <HeaderText>
            <span className="text no-icon">Understanding study levels</span>
          </HeaderText>
          <p>
            RMIT offers a range of education options, learn more about the
            different study levels to choose a program and type of study that
            suits your needs and aspirations.
          </p>
        </StyledModalHeader>
        <ModalGridBanner>
          <ModalContent>
            <StyledContentInner>
              <SubHeaderText className="beta beta--large blue">
                <span className="text">Higher Education</span>
              </SubHeaderText>
              <p>
                Our higher education course offerings (Undergraduate and
                Postgraduate) provides you the theoretic and practical knowledge
                to pursue a range of career outcomes.
              </p>
              <ul>
                <li>Bachelor Degree</li>
                <li>Honours Degree</li>
                <li>Masters Degree</li>
              </ul>

              <a
                href="https://www.rmit.edu.au/study-with-us/levels-of-study/undergraduate-study"
                className="chevron-link"
              >
                <SVG src={`${process.env.PUBLIC_URL}/icons/arrow-right.svg`} />
                <span className="text">
                  Learn more about RMIT undergraduate education
                </span>
              </a>

              <a
                href="https://www.rmit.edu.au/study-with-us/levels-of-study/postgraduate-study"
                className="chevron-link"
              >
                <SVG src={`${process.env.PUBLIC_URL}/icons/arrow-right.svg`} />
                <span className="text">
                  Learn more about RMIT postgraduate education
                </span>
              </a>
            </StyledContentInner>
            <StyledContentInner>
              <SubHeaderText className="beta beta--large blue">
                <span className="text">Vocational (TAFE)</span>
              </SubHeaderText>
              <p>
                Get job-ready sooner or start your pathway into higher education
                with vocational study, also known as TAFE.
              </p>
              <ul>
                <li>Certificate</li>
                <li>Diploma</li>
                <li>Associate Degree</li>
              </ul>
              <a href="https://www.rmit.edu.au/tafe" className=" chevron-link">
                <SVG src={`${process.env.PUBLIC_URL}/icons/arrow-right.svg`} />
                <span className="text">
                  Learn more about RMIT vocational (TAFE)
                </span>
              </a>
            </StyledContentInner>
          </ModalContent>
          <StyledAside>
            <StyledImage>
              <source
                srcset={`${process.env.PUBLIC_URL}/Open-Day-Ivy-550x798.jpg`}
                media="(min-width: 900px)"
              />
              <img
                src={`${process.env.PUBLIC_URL}/Open-Day-Ivy-335x486.jpg`}
                alt=""
              />
            </StyledImage>
          </StyledAside>
        </ModalGridBanner>
      </ModalInner>
    </ModalWrap>
  );
}

export default withRouter(StudyLevelModal);
export { STUDY_LEVEL_MODAL_ACTIVE_QUERY, TOGGLE_STUDY_LEVEL_MODAL_MUTATION };
