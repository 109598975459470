import React from "react";
import PropTypes from "prop-types";
import { ThemeProvider, createGlobalStyle } from "styled-components";

import "./fonts.css";

const theme = {
  textPrimary: "#000054",
  textDark: "#070758",
  highlightRed: "#E60028",
  highlightRedActive: "#73003E",
  darkRed: "#95001a",
  darkerBlue: "#05054a",
  darkBlue: "#004C9C",
  otherBlue: "#007ADA",
  highlightBlue: "#0199E6",
  highlightMidBlue: "#7BCBF2",
  highlightLightBlue: "#D3F4FF",
  midPurple: "#C864C8",
  lightPurple: "#E3B1E3",
  lavender: "#829BF5",
  lightLavender: "#B5C5FF",
  orange: "#FFA04B",
  lightOrange: "#FFD9B7",
  sidebarBg: "#f6fdff",
};

const GlobalStyles = createGlobalStyle`

  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  body {
    padding: 0;
    margin: 0;
    font-family: 'HelveticaNeue-Medium', Helvetica, Arial, sans-serif;
    color: ${theme.textPrimary};
    &.popup-active {
      height: 100vh;
      overflow: hidden;
    }
  }
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
  }
  a, .button-link {
    text-decoration: none;
    color: ${theme.textPrimary};
    &.chevron-link {
      font-family: "Museo700", "Century Gothic", CenturyGothic, AppleGothic, Arial, Helvetica, sans-serif;
      position: relative;
      display: inline-block;
      padding-left: 15px;
      text-decoration: none;
      .isvg {
        position: absolute;
        left: 0;
        top: 50%; 
        transform: translateY(-50%);
        path, polygon {
          fill: ${theme.highlightRed};
        }
      }
      span.text {
        display: inline-block;
        position: relative;
        .isvg {
          position: absolute;
          left: auto;
          right: 0;
          top: 55%; 
          transform: translateY(-50%);
          width: 10px;
          svg {
            width: 10px;
          }
          path, polygon {
            fill: ${theme.textPrimary};
          }
        }
      }
      &--external {
        span.text {
          padding-right: 16px;
        }
      }
      &:hover {
        span.text {
          text-decoration: underline;
        }
      } 
      &:focus {
        outline: none;
        color:  ${theme.highlightRed};
        span.text {
          text-decoration: underline;
        }
      }
      &:active {
        color:  ${theme.darkRed};
      } 
    }
  }
  .alpha {
    &.alpha--404 {
font-family: "HelveticaNeue-Medium", Helvetica, Arial, sans-serif;
line-height: 1;
margin: 0 auto;
color: white;
text-align: center;
text-shadow: 0 2px 12px #cdcdcd;
font-size: 100px;
	font-size: 8rem;
	max-width: 300px;
@media only screen and (min-width: 900px) {
	line-height: 1;
	font-size: 200px;
	font-size: 25rem;
	max-width: 700px;
	
	letter-spacing: 2px;
	text-shadow: 0 5px 20px #cdcdcd;
}
    }
  }
  .beta {
    font-family: "Museo700","Century Gothic", CenturyGothic, AppleGothic, Arial, Helvetica, sans-serif;
    font-size: 20px;
    color: ${theme.textPrimary};
    letter-spacing: 0;
    &.beta--large {
      @media only screen and (min-width: 900px) {
        font-size: 24px;
      }
    }
    /* @media only screen and (min-width: 900px) {
      font-size: 24px;
    } */
    &.beta--home {
      font-size: 24px;
      line-height: 1em;
      @media only screen and (min-width: 900px) {
        font-size: 40px;
      }
      @media only screen and (min-width: 1200px) {
        font-size: 54px;
      }
    }
  }
  .gamma {
    font-family: "HelveticaNeue-Bold", Helvetica, Arial, sans-serif;
    font-size: 18px;
    color: ${theme.highlightBlue};
    letter-spacing: 0;
    @media only screen and (min-width: 900px) {
      font-size: 22px;
    }
    &.gamma--home {
      font-family: "Museo700","Century Gothic", CenturyGothic, AppleGothic, Arial, Helvetica, sans-serif;
      color: ${theme.textPrimary};
      font-size: 24px;
      line-height: 1.1em;
      @media only screen and (min-width: 900px) {
        font-size: 30px;
      }
      @media only screen and (min-width: 1200px) {
        font-size: 32px;
      }
    }
  }
  .delta {
    font-family: "HelveticaNeue-Bold", Helvetica, Arial, sans-serif;
    font-size: 16px;
    color: ${theme.textPrimary};
    letter-spacing: 0;
    line-height: 24px;
    @media only screen and (min-width: 900px) {
      font-size: 20px;
      line-height: 22px;
    }
  }
  .omega {
    font-family: "HelveticaNeue-Bold", Helvetica, Arial, sans-serif;
    font-size: 16px;
    color: ${theme.textPrimary};
    letter-spacing: 0;
    line-height: 24px;
  }
  p {
    margin: 0 0 20px 0;
    font-size: 16px;
    line-height: 24px;
    font-family: 'HelveticaNeue-Medium', Helvetica, Arial, sans-serif;
    a, .button-link {
      text-decoration: underline;
      border: 0;
      padding: 0;
      cursor: pointer;
      font: inherit;
      &:hover {
        color:  ${theme.highlightRed};
      }
      &:focus {
        color: ${theme.textPrimary};
        outline: 1px solid  ${theme.highlightRed};
        outline-offset: 2px;
      }
      &:active {
        color:  ${theme.darkRed};
        outline: none;
        &:focus {
          color:  ${theme.darkRed};
          outline: none;
        }
      }
    }
  }

  ul.list--styled {
    margin: 30px 0 0;
    padding: 0;
    list-style: none;
    li {
      position: relative;
      padding: 25px 25px 25px 30px;
      border-top: 1px solid rgba(205, 205, 205, 0.4);
      &:last-child {
        border-bottom: 1px solid rgba(205, 205, 205, 0.4);
      }
      &:before {
        content: "";
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color:${theme.highlightRed};
        position: absolute;
        left: 15px;
        top: 50%; 
        transform: translateY(-50%);
      }
      &.hidden {
        display: none;
      }
    }
    &.more-visible {
      li.hidden {
        display: block;
      }
    }
  }
  .visible-sm-down {
    display: none;
    @media only screen and (max-width: 899px) {
      display: inline-block;
    }
  }
  .visible-md-up {
    @media only screen and (max-width: 899px) {
      display: none;
    }
  }
  hr {
    border: none;
    border-top: 1px solid #D8D8D8;
    margin-bottom: 30px;
    display: none;
    @media only screen and (min-width: 900px) {
      display: block;
    }
    @media only screen and (min-width: 1200px) {
      margin-bottom: 60px;
    }
  }

`;

function Theme({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles />
        {children}
      </>
    </ThemeProvider>
  );
}

Theme.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Theme;
