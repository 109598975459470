import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useMutation } from "react-apollo-hooks";
import styled from "styled-components";
import gql from "graphql-tag";
import SVG from "react-inlinesvg";

import { SORT_STEPS_IN_ORDER_OF } from "../lib/consts";

const CURRENT_PATHWAY_MUTATION = gql`
  mutation updateCurrentPathway($currentPathway: String!) {
    updateCurrentPathway(currentPathway: $currentPathway) @client
  }
`;

const StyledItem = styled.div`
  a {
    padding: 18px 20px;
    font-size: 14px;
    position: relative;
    display: inline-block;
    border: 1px solid ${(props) => props.theme.highlightMidBlue};
    border-top: none;
    color: ${(props) => props.theme.textDark};
    width: 100%;
    &:hover {
      h3,
      h3 + span {
        text-decoration: underline;
      }
    }
    &:focus {
      outline: none;
      &:after {
        content: "";
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        position: absolute;
        top: -1px;
        left: -1px;
        border: 2px solid ${(props) => props.theme.highlightBlue};
      }
    }
    &:active {
      color: ${(props) => props.theme.textDark};
      background: ${(props) => props.theme.highlightBlue};
      .pathway__step-title {
        &:before {
          background-color: ${(props) => props.theme.highlightBlue};
        }
      }

      &:after {
        content: "";
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        position: absolute;
        top: -1px;
        left: -1px;
        border: 1px solid ${(props) => props.theme.highlightBlue};
      }
    }
  }
  &.active {
    a {
      color: ${(props) => props.theme.textDark};
      background: ${(props) => props.theme.highlightBlue};
      pointer-events: none;
      &:after {
        content: "";
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        position: absolute;
        top: -1px;
        left: -1px;
        border: 1px solid ${(props) => props.theme.highlightBlue};
      }
    }
  }
  &:first-child a {
    border-top: 1px solid ${(props) => props.theme.highlightMidBlue};
  }
`;

const PathwayItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  h3 {
    flex: 0 1 100%;
    margin-bottom: 0;
    margin-top: 0;
    color: ${(props) => props.theme.textDark};
  }
  span.omega {
    flex: 1 0 100px;
    text-align: right;
    font-family: "HelveticaNeue-Medium" Helvetica, Arial, sans-serif;
    color: ${(props) => props.theme.textDark};
  }
`;

const PathwayItemGPLabel = styled.span`
  display: flex;
  width: 100%;
  border-radius: 10px;
  height: 33px;
  margin-top: 12px;
  max-width: 176px;
  background: white;
  color: ${(props) => props.theme.highlightRed};
  svg,
  .isvg {
    width: 100%;
    height: 100%;
  }
`;

const PathwayItemSteps = styled.div`
  position: relative;

  /* dotted line */
  &:before {
    content: "";
    position: absolute;
    left: 3px;
    top: 20px;
    width: 1px;
    height: calc(100% - 30px);
    background-image: linear-gradient(
      ${(props) => props.theme.highlightBlue} 50%,
      ${(props) => props.theme.textDark} 0%
    );
    background-position: right;
    background-size: 1px 5px;
    background-repeat: repeat-y;
    @media only screen and (min-width: 900px) {
      height: calc(100% - 25px);
    }
  }
  .pathway__step {
    padding: 10px 0 10px 15px;
    display: flex;
    justify-content: space-between;
    position: relative;
    &-title {
      font-family: "HelveticaNeue-Medium";
      flex: 0 1 100%;
      color: ${(props) => props.theme.textDark};
      line-height: 20px;
      /* circle */
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 18px;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: white;
        border: 1px solid ${(props) => props.theme.textDark};
        .active & {
          background-color: ${(props) => props.theme.highlightBlue};
        }
      }
    }
    &:first-of-type .pathway__step-title {
      &:before {
        background-color: ${(props) => props.theme.textDark};
      }
    }
    &:last-of-type {
      padding-bottom: 0;
      /* end line */
      &:after {
        content: "";
        position: absolute;
        left: 1px;
        bottom: 5px;
        width: 5px;
        height: 1px;
        background-color: ${(props) => props.theme.textDark};
        display: inline-block;
      }
    }
  }
`;

function PathwaySidebarItem({ index, pathway, className, match }) {
  const getAlphaIndex = (i) => {
    if (index < 26) {
      return String.fromCharCode(index + 65);
    } else {
      return String.fromCharCode(65) + String.fromCharCode(index - 26 + 65);
    }
  };
  const updateCurrentPathway = useMutation(CURRENT_PATHWAY_MUTATION);
  if (match.params.slug.toUpperCase() === pathway.slug.toUpperCase()) {
    const currentPathway = `Pathway ${getAlphaIndex(index)}`;
    updateCurrentPathway({
      variables: { currentPathway },
    });
  }
  return (
    <>
      <StyledItem
        className={
          (match.params.slug.toUpperCase() === pathway.slug.toUpperCase()
            ? "active"
            : "") +
          " " +
          className
        }
      >
        <Link to={`/pathway/${pathway.slug.toUpperCase()}`}>
          <PathwayItemHeader>
            <h3 className="omega">{`Pathway ${getAlphaIndex(index)}`}</h3>
            <span className="omega">
              {`${
                pathway.steps &&
                pathway.steps
                  .map((step) => step.duration)
                  .reduce((a, c) => a + c) + pathway.goalDuration
              } years`}
            </span>
          </PathwayItemHeader>
          {pathway.guaranteedPathway && (
            <PathwayItemGPLabel>
              <SVG src={process.env.PUBLIC_URL + "/icons/icon-gp-small.svg"} />
            </PathwayItemGPLabel>
          )}
          <PathwayItemSteps>
            {pathway.steps &&
              pathway.steps
                .sort((a, b) => {
                  return (
                    SORT_STEPS_IN_ORDER_OF.indexOf(a.course.level) -
                    SORT_STEPS_IN_ORDER_OF.indexOf(b.course.level)
                  );
                })
                .map((step) => (
                  <div className="pathway__step" key={step.course.codeUnique}>
                    <span className="para pathway__step-title">
                      {step.course.title}
                    </span>
                  </div>
                ))}
            <div className="pathway__step">
              <span className="para pathway__step-title">
                {pathway.goalCourse.title}
              </span>
            </div>
          </PathwayItemSteps>
        </Link>
      </StyledItem>
    </>
  );
}

PathwaySidebarItem.propTypes = {
  index: PropTypes.number.isRequired,
  pathway: PropTypes.shape({
    slug: PropTypes.string,
    steps: PropTypes.array.isRequired,
  }).isRequired,
};

export default PathwaySidebarItem;
