/**
 * This class handles animations of interest page.
 *
 */
import anime from "animejs";

export default class itrsAnimation {
  constructor(animationService) {
    this.animationService = animationService;
    this.verticalNavItrsId = "vertical-nav-bar-itrs";
    this.itrsPageId = "itrs-page";
    this.itrsPageHeaderTextId = "itrs-page-header-text";
    this.itrsPageHeaderIconId = "itrs-page-header-icon";
    this.itrsPageSidebarOtherHeader = "itrs-page-sidebar-other-header";
  }

  _hideItrsPage() {
    this.animationService._hideItemById(this.itrsPageId);
  }

  _showItrsPage() {
    this.animationService._showItemById(this.itrsPageId);
  }

  _getItrsPageRelated() {
    return document.getElementById("itrs-page-related");
  }

  _getItrsPageOther() {
    return document.getElementById("itrs-page-other");
  }

  _initItrsPageEnterAnimation() {
    // Set indexes based on their position in the animation queue.
    this.itrsPageFirstEnterAnimIndex = 0;
    this.itrsPageRelatedEnterAnimIndex = 1;
    this.itrsPageOtherEnterAnimIndex = 2;
    this.itrsPageCourseEnterAnimIndex = 3;

    this.itrsPageEnterAnimQueue = [
      {
        animObject: anime.timeline({
          easing: "cubicBezier(0.3, 0, 0.3, 1)",
        }),
        addAnimation: this._addItrsPageFirstAnimation.bind(this),
        // Dom is ready for the first animation
        domReady: true,
        began: false,
        added: false,
      },
      {
        animObject: anime.timeline({
          easing: "cubicBezier(0.3, 0, 0.3, 1)",
        }),
        addAnimation: this._addItrsPageRelatedAnimation.bind(this),
        domReady: false,
        began: false,
        // A flag to check if relatedAnim added
        added: false,
        // The main animation is 900ms long. The relatedAnim should start at 700ms. We consider 50ms timeout execution delay, so it's 650.
        //beAddedAt: 650,
      },
      {
        animObject: anime.timeline({
          easing: "cubicBezier(0.3, 0, 0.3, 1)",
        }),
        addAnimation: this._addItrsPageOtherAnimation.bind(this),
        domReady: false,
        began: false,
        // A flag to check if otherAnim added
        added: false,
      },
      {
        animObject: anime.timeline({
          easing: "cubicBezier(0.3, 0, 0.3, 1)",
        }),
        addAnimation: this._addItrsPageCourseAnimation.bind(this),
        domReady: false,
        began: false,
        // A flag to check if coursesAnim added
        added: false,
      },
    ];
  }

  runItrsPageExitAnimation(match) {
    // Set this flag, so no entering animation is added anymore
    this.animationService.exitAnimationRunning = true;
    // Init enter animation object
    this.animationService.animationsByPath[match.path].initEnterAnimation(true);
    // Hide entering page
    this.animationService.animationsByPath[match.path].hidePage();
    this.itrsPageExitAnime = anime.timeline({
      easing: "cubicBezier(0.3, 0, 0.3, 1)",
    });
    let courseCardListItems = document.getElementsByClassName(
      "course-card-list-item"
    );
    let targetPrefix = "";
    // If the initial interest has been changed by user by clicking on an interest in the sidebar, element ids and classes will have an "exit-" prefix
    if (!courseCardListItems.length) {
      targetPrefix = "exit-";
      courseCardListItems = document.getElementsByClassName(
        `${targetPrefix}course-card-list-item`
      );
    }
    // Convert HTMLCollection to array
    courseCardListItems = [...courseCardListItems];
    // Build an array of elements for the course list animation
    courseCardListItems.reverse();
    let courseCardsTargetArray = [];
    let coursesHr = document.getElementById(
      `${targetPrefix}itrs-page-courses-hr`
    );
    let coursesGPNotification = document.getElementById(
      `${targetPrefix}itrs-page-courses-gp-notification`
    );
    let coursesVEFilter = document.getElementById(
      `${targetPrefix}itrs-page-courses-ve-filter`
    );
    let coursesStudyLevelModalBtn = document.getElementById(
      `${targetPrefix}itrs-page-study-level-modal-btn`
    );
    let coursesTopP = document.getElementById(
      `${targetPrefix}itrs-page-courses-top-p`
    );

    if (coursesHr) {
      courseCardsTargetArray.push(coursesHr);
    }
    courseCardsTargetArray = courseCardsTargetArray.concat(courseCardListItems);
    if (coursesGPNotification) {
      courseCardsTargetArray.push(coursesGPNotification);
    }
    if (coursesVEFilter) {
      courseCardsTargetArray.push(coursesVEFilter);
    }
    if (coursesStudyLevelModalBtn) {
      courseCardsTargetArray.push(coursesStudyLevelModalBtn);
    }
    if (coursesTopP) {
      courseCardsTargetArray.push(coursesTopP);
    }

    // If a course list item is clicked, add the button background animation
    // let courseItemClicked = document.getElementsByClassName(
    //   'course-item-clicked'
    // );
    // if (courseItemClicked.length) {
    //   this.itrsPageExitAnime.add({
    //     targets: courseItemClicked,
    //     duration: 250
    //   });
    // }
    this.itrsPageExitAnime
      //Nav bar - unhighlight the item
      .add({
        targets: "#exit-" + this.verticalNavItrsId,
        backgroundColor: this.animationService.textDark,
        color: "#fff",
        duration: 300,
      })
      .add(
        {
          targets:
            "#" +
            targetPrefix +
            this.itrsPageHeaderIconId +
            ", #" +
            targetPrefix +
            this.itrsPageHeaderTextId +
            ", #" +
            this.itrsPageSidebarOtherHeader +
            ", #itrs-page-sidebar-top-header",
          translateY: [0, -25],
          opacity: [1, 0],
          duration: 300,
        },
        "-=300"
      )
      .add(
        {
          targets: "#itrs-page-contact-container",
          translateY: [0, -25],
          opacity: [1, 0],
          duration: 300,
        },
        "-=200"
      )
      .add(
        {
          targets:
            `#${targetPrefix}itrs-page-courses-bottom-header, #${targetPrefix}itrs-page-courses-bottom-p` +
            `, #${targetPrefix}itrs-page-courses-bottom-ul, #${targetPrefix}itrs-page-courses-show-more-button`,
          translateY: [0, -25],
          opacity: [1, 0],
          duration: 300,
        },
        "-=200"
      )
      .add(
        {
          targets: courseCardsTargetArray,
          translateY: [0, -25],
          opacity: [1, 0],
          duration: 300,
          delay: anime.stagger(100),
        },
        "-=200"
      )
      .add(
        {
          targets: "#itrs-page-related, #itrs-page-other",
          width: ["100%", "0"],
          duration: 600,
        },
        400
      );
    // Run the new page's enter animation when exit is finished
    this.itrsPageExitAnime.finished.then(() => {
      this.animationService.exitAnimationRunning = false;
      this.animationService.animationsByPath[match.path].runEnterAnimation(
        true
      );
    });
  }

  endItrsPageAnimation(node, done) {
    // If animation object doesn't exist, call done and exit
    if (!this.itrsPageExitAnime) {
      done();
      return;
    }
    // Call done when exit animation is finished
    this.itrsPageExitAnime.finished.then(() => {
      done();
    });
  }

  runItrsPageEnterAnimation(pageTransition = false) {
    // If it's not a page transition (it's a page load), init the animation object because it hasen't been already.
    if (!pageTransition) {
      this._initItrsPageEnterAnimation();
    } else {
      this._showItrsPage();
    }

    this.animationService._tryToAddAnimation(
      this.itrsPageEnterAnimQueue,
      this.itrsPageFirstEnterAnimIndex
    );
  }

  _addItrsPageFirstAnimation() {
    anime.set("#itrs-page-sidebar", { opacity: 1 });
    this.itrsPageEnterAnimQueue[this.itrsPageFirstEnterAnimIndex].animObject
      // Nav bar - button comes in
      .add({
        targets: "#" + this.verticalNavItrsId,
        opacity: 1,
        duration: 300,
        complete: (anim) => {
          // If an exit animation is running, exit
          if (this.animationService.exitAnimationRunning) {
            return;
          }
          this.itrsPageEnterAnimQueue[
            this.itrsPageFirstEnterAnimIndex
          ].began = true;
          this.animationService._tryToAddAnimation(
            this.itrsPageEnterAnimQueue,
            this.itrsPageFirstEnterAnimIndex + 1
          );
        },
      })
      // Nav bar - highlight the active item. At the same time as LHS container comes in
      .add(
        {
          targets: ".nav-item--active",
          backgroundColor: this.animationService.themeHighlightBlue,
          color: this.animationService.textDark,
          duration: 300,
        },
        "-=200"
      )
      // LHS container - comes in
      .add(
        {
          targets: "#itrs-page-sidebar",
          translateX: ["-100%", "0%"],
          duration: 500,
        },
        "-=300"
      )
      // sidebar - top header
      .add({
        targets: "#itrs-page-sidebar-top-header",
        translateY: [-25, 0],
        opacity: [0, 1],
        duration: 300,
      });
  }

  addRelatedToItrsPageEnterAnimation(success) {
    // Exit if there are no items to be animated
    if (!success) {
      return;
    }
    // Exit if the animation has already been added
    if (this.itrsPageEnterAnimQueue[this.itrsPageRelatedEnterAnimIndex].added) {
      return;
    }
    // The dom is ready
    this.itrsPageEnterAnimQueue[
      this.itrsPageRelatedEnterAnimIndex
    ].domReady = true;
    // If an exit animation is running, exit
    if (this.animationService.exitAnimationRunning) {
      return;
    }
    this.animationService._tryToAddAnimation(
      this.itrsPageEnterAnimQueue,
      this.itrsPageRelatedEnterAnimIndex
    );
  }

  addOtherToItrsPageEnterAnimation(success) {
    if (!success) {
      return;
    }
    // Exit if the animation has already been added
    if (this.itrsPageEnterAnimQueue[this.itrsPageOtherEnterAnimIndex].added) {
      return;
    }
    // The dom is ready
    this.itrsPageEnterAnimQueue[
      this.itrsPageOtherEnterAnimIndex
    ].domReady = true;
    // If an exit animation is running, exit
    if (this.animationService.exitAnimationRunning) {
      return;
    }
    this.animationService._tryToAddAnimation(
      this.itrsPageEnterAnimQueue,
      this.itrsPageOtherEnterAnimIndex
    );
  }

  addCoursesToItrsPageEnterAnimation(success) {
    if (!success) {
      return;
    }
    // Exit if the animation has already been added
    if (this.itrsPageEnterAnimQueue[this.itrsPageCourseEnterAnimIndex].added) {
      return;
    }
    // The dom is ready
    this.itrsPageEnterAnimQueue[
      this.itrsPageCourseEnterAnimIndex
    ].domReady = true;
    // If an exit animation is running, exit
    if (this.animationService.exitAnimationRunning) {
      return;
    }
    this.animationService._tryToAddAnimation(
      this.itrsPageEnterAnimQueue,
      this.itrsPageCourseEnterAnimIndex
    );
  }

  _addItrsPageRelatedAnimation() {
    let related = this._getItrsPageRelated();
    let beAddedAt =
      this.itrsPageEnterAnimQueue[this.itrsPageFirstEnterAnimIndex].animObject
        .duration - 200;
    // If it's not early to add the animation
    if (
      this.itrsPageEnterAnimQueue[this.itrsPageFirstEnterAnimIndex].animObject
        .currentTime >= beAddedAt
    ) {
      // Give the animation a small delay so it's visible
      this._createRelatedEnterAnimation(related, 200);
      return;
    }

    // It's early to add the animation, set a timeout
    setTimeout(() => {
      this._createRelatedEnterAnimation(related);
    }, beAddedAt - this.itrsPageEnterAnimQueue[this.itrsPageFirstEnterAnimIndex].animObject.currentTime);
  }

  _addItrsPageOtherAnimation() {
    let other = this._getItrsPageOther();
    let beAddedAt =
      this.itrsPageEnterAnimQueue[this.itrsPageRelatedEnterAnimIndex].animObject
        .duration - 200;
    // If it's not early to add the animation
    if (
      this.itrsPageEnterAnimQueue[this.itrsPageRelatedEnterAnimIndex].animObject
        .currentTime >= beAddedAt
    ) {
      // Give the animation a small delay so it's visible
      this._createOtherEnterAnimation(other, 200);
      return;
    }

    // It's early to add the animation, set a timeout
    setTimeout(() => {
      this._createOtherEnterAnimation(other);
    }, beAddedAt - this.itrsPageEnterAnimQueue[this.itrsPageRelatedEnterAnimIndex].animObject.currentTime);
  }

  _addItrsPageCourseAnimation() {
    let beAddedAt =
      this.itrsPageEnterAnimQueue[this.itrsPageOtherEnterAnimIndex].animObject
        .duration;
    // If it's not early to add the animation
    if (
      this.itrsPageEnterAnimQueue[this.itrsPageOtherEnterAnimIndex].animObject
        .currentTime >= beAddedAt
    ) {
      // Give the animation a small delay so it's visible
      this._createCourseEnterAnimation(200);
      return;
    }

    // It's early to add the animation, set a timeout
    setTimeout(() => {
      this._createCourseEnterAnimation();
    }, beAddedAt - this.itrsPageEnterAnimQueue[this.itrsPageOtherEnterAnimIndex].animObject.currentTime);
  }

  _createRelatedEnterAnimation(related, InitialDelay = 0) {
    anime.set(related, { opacity: 1 });
    this.itrsPageEnterAnimQueue[this.itrsPageRelatedEnterAnimIndex].animObject
      .add({
        targets: related,
        width: ["0", "100%"],
        // translateX: [-25, 0],
        // opacity: [0, 1],
        duration: 300,
        delay: InitialDelay,
        complete: (anim) => {
          // If an exit animation is running, exit
          if (this.animationService.exitAnimationRunning) {
            return;
          }
          this.itrsPageEnterAnimQueue[
            this.itrsPageRelatedEnterAnimIndex
          ].began = true;
          this.animationService._tryToAddAnimation(
            this.itrsPageEnterAnimQueue,
            this.itrsPageRelatedEnterAnimIndex + 1
          );
        },
      })
      .add(
        {
          targets: "#" + this.itrsPageHeaderTextId,
          translateY: [-25, 0],
          opacity: [0, 1],
          duration: 300,
        },
        0
      )
      .add(
        {
          targets: "#" + this.itrsPageSidebarOtherHeader,
          translateY: [-25, 0],
          opacity: [0, 1],
          duration: 300,
        },
        "-=200"
      );
  }

  _createOtherEnterAnimation(other, InitialDelay = 0) {
    anime.set(other, { opacity: 1 });
    this.itrsPageEnterAnimQueue[this.itrsPageOtherEnterAnimIndex].animObject
      .add({
        targets: other,
        width: ["0", "100%"],
        duration: 300,
        delay: InitialDelay,
        complete: (anim) => {
          // If an exit animation is running, exit
          if (this.animationService.exitAnimationRunning) {
            return;
          }
          this.itrsPageEnterAnimQueue[
            this.itrsPageOtherEnterAnimIndex
          ].began = true;
          this.animationService._tryToAddAnimation(
            this.itrsPageEnterAnimQueue,
            this.itrsPageOtherEnterAnimIndex + 1
          );
        },
      })
      .add(
        {
          targets: "#" + this.itrsPageHeaderIconId,
          opacity: [0, 1],
          duration: 300,
        },
        0
      );
  }

  _createCourseEnterAnimation(InitialDelay = 0) {
    this.itrsPageEnterAnimQueue[this.itrsPageCourseEnterAnimIndex].animObject
      .add({
        targets:
          "#itrs-page-courses-gp-notification, #itrs-page-courses-ve-filter, #itrs-page-study-level-modal-btn, #itrs-page-courses-top-p",
        translateY: [-25, 0],
        opacity: [0, 1],
        duration: 300,
        delay: InitialDelay,
        complete: (anim) => {
          // If an exit animation is running, exit
          if (this.animationService.exitAnimationRunning) {
            return;
          }
          this.itrsPageEnterAnimQueue[
            this.itrsPageCourseEnterAnimIndex
          ].began = true;
          this.animationService._tryToAddAnimation(
            this.itrsPageEnterAnimQueue,
            this.itrsPageCourseEnterAnimIndex + 1
          );
        },
      })
      .add(
        {
          targets: ".course-card-list-item, #itrs-page-courses-hr",
          translateY: [-25, 0],
          opacity: [0, 1],
          duration: 300,
          delay: anime.stagger(100),
        },
        "-=200"
      )
      .add(
        {
          targets:
            "#itrs-page-courses-bottom-header, #itrs-page-courses-bottom-p, #itrs-page-courses-bottom-ul, #itrs-page-courses-show-more-button",
          translateY: [-25, 0],
          opacity: [0, 1],
          duration: 300,
        },
        "-=200"
      )
      .add(
        {
          targets: "#itrs-page-contact-container",
          translateY: [-25, 0],
          opacity: [0, 1],
          duration: 300,
        },
        "-=200"
      );
  }
}
