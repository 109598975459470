import styled from "styled-components";

const ModalContent = styled.div`
  grid-column: content-start / content-end;
  margin-top: 35px;
  position: relative;
  @media only screen and (min-width: 1200px) {
    grid-column: content-start / content-aside;
    margin-top: 75px;
  }
`;

export default ModalContent;
