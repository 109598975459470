import React, { useRef } from "react";
import PropTypes from "prop-types";
import { useQuery, useMutation } from "react-apollo-hooks";
import gql from "graphql-tag";
import SVG from "react-inlinesvg";
import {
  Typeahead,
  Menu,
  MenuItem,
  Highlighter,
} from "react-bootstrap-typeahead";
import { withRouter } from "react-router-dom";

import SearchFieldWrap from "./styles/SearchFieldWrap";

const SET_SEARCH_NO_PATHWAY = gql`
  mutation setSearchNoPathway($searchNoPathway: String!) {
    setSearchNoPathway(searchNoPathway: $searchNoPathway) @client
  }
`;

const COURSES_QUERY = gql`
  query COURSES_QUERY {
    courses(orderBy: title_ASC) {
      id
      title
      codeUnique
      level
      interests {
        id
        title
        slug
        relatedInterests(first: 2) {
          id
          title
          slug
        }
      }
      pathways {
        id
        title
        guaranteedPathway
      }
    }
  }
`;

function SearchField({ searchFocus, setSearchFocus, history }) {
  const setSearchNoPathway = useMutation(SET_SEARCH_NO_PATHWAY);
  const searchCoursesQuery = useQuery(COURSES_QUERY);
  const typeaheadEl = useRef(null);
  if (searchFocus) {
    typeaheadEl.current.focus();
    setSearchFocus(false);
  }
  return (
    <SearchFieldWrap>
      {!searchCoursesQuery.loading && (
        <Typeahead
          ref={typeaheadEl}
          id="course-search"
          placeholder="Enter a course name..."
          options={searchCoursesQuery.data.courses}
          minLength={2}
          labelKey="title"
          renderMenu={(results, menuProps) => (
            <Menu {...menuProps}>
              <div className="rbt-menu-header">
                <h3 className="omega header-item pathways">
                  <span className="text">Pathways</span>
                  <SVG
                    src={process.env.PUBLIC_URL + "/icons/pathway-options.svg"}
                  />
                </h3>
                <h3 className="omega header-item courses">Courses</h3>
              </div>
              <ul className="inner-list">
                {!results.length && (
                  <li>
                    <div className="dropdown-item no-results">
                      <span className="pathways">-</span>
                      <span className="title">No courses found</span>
                    </div>
                  </li>
                )}
                {results.map((result, index) => (
                  <MenuItem option={result} position={index} key={index}>
                    <span className="pathways">
                      {result.pathways && result.pathways.length
                        ? result.pathways.length
                        : "-"}
                    </span>
                    <span className="title">
                      <Highlighter search={menuProps.text}>
                        {result.title}
                      </Highlighter>{" "}
                      <span className="item-gp">
                        {result.pathways &&
                          result.pathways.filter(
                            (pathway) => pathway.guaranteedPathway === true
                          ).length > 0 &&
                          "(" +
                            result.pathways.filter(
                              (pathway) => pathway.guaranteedPathway === true
                            ).length +
                            " Guaranteed Pathway" +
                            (result.pathways.filter(
                              (pathway) => pathway.guaranteedPathway === true
                            ).length > 1
                              ? "s"
                              : "") +
                            " available)"}
                      </span>
                    </span>
                  </MenuItem>
                ))}
              </ul>
            </Menu>
          )}
          onChange={(selected) => {
            if (!selected.length) return;
            if (selected[0].pathways.length) {
              history.push(`/course/${selected[0].codeUnique}`);
            } else {
              setSearchNoPathway({
                variables: { searchNoPathway: selected },
              });
            }
            typeaheadEl.current.clear();
            typeaheadEl.current.blur();
          }}
        />
      )}
    </SearchFieldWrap>
  );
}

SearchField.propTypes = {
  searchFocus: PropTypes.bool.isRequired,
  setSearchFocus: PropTypes.func.isRequired,
};

export default withRouter(SearchField);
