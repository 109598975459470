import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useQuery, useMutation } from "react-apollo-hooks";
import gql from "graphql-tag";
import styled from "styled-components";
import SVG from "react-inlinesvg";
import CardListWrap from "./CardListWrap";
import CourseListItem from "./CourseListItem";
import SubHeaderText from "./SubHeaderText";
import StudyLevelModalButton from "./StudyLevelModalButton";
import { GuaranteedPathwaysNotificationSimple } from "./GuaranteedPathwaysNotification";
import Filter from "./Filter";
import usePrevious from "../lib/usePrevious";

const COURSES_BY_INTEREST_QUERY = gql`
  query COURSES_BY_INTEREST_QUERY($slug: String!) {
    courses(orderBy: title_ASC, where: { interests_some: { slug: $slug } }) {
      id
      title
      codeUnique
      pathways {
        id
        title
        guaranteedPathway
      }
      level
    }
  }
`;

const CURRENT_STUDY_LEVEL_QUERY = gql`
  query {
    currentStudyLevel @client
  }
`;

const CURRENT_STUDY_LEVEL_MUTATION = gql`
  mutation updateCurrentStudyLevel($currentStudyLevel: String!) {
    updateCurrentStudyLevel(currentStudyLevel: $currentStudyLevel) @client
  }
`;

const ShowMoreButton = styled.button`
  padding: 25px 25px 25px 15px;
  border: none;
  border-top: 1px solid rgba(205, 205, 205, 0.4);
  border-bottom: 1px solid rgba(205, 205, 205, 0.4);
  width: 100%;
  text-align: left;
  line-height: 32px;
  background: transparent;
  font-size: 18px;
  font-family: HelveticaNeue-Medium;
  color: ${(props) => props.theme.textPrimary};
  cursor: pointer;
  &.more-visible {
    display: none;
  }
  .isvg {
    position: relative;
    padding-left: 5px;
    top: -1px;
  }
  em {
    color: ${(props) => props.theme.highlightRed};
    font-style: normal;
  }
  &:hover {
    > span {
      border-bottom: 2px solid ${(props) => props.theme.textPrimary};
    }
  }
  &:active,
  &:active em {
    color: ${(props) => props.theme.highlightRedActive};
  }
  &:active > span {
    border-bottom: 2px solid ${(props) => props.theme.highlightRedActive};
  }
  &:active .isvg {
    polygon {
      fill: ${(props) => props.theme.highlightRedActive};
    }
  }
  &:focus,
  &:focus em,
  &:focus > span {
    color: ${(props) => props.theme.highlightRed};
    outline: 0;
  }
  &:focus > span {
    border-bottom: 2px solid ${(props) => props.theme.highlightRed};
  }
  &:focus .isvg {
    polygon {
      fill: ${(props) => props.theme.highlightRed};
    }
  }
`;

const FilterStudyLevelModalButton = styled(StudyLevelModalButton)`
  margin-top: 20px;

  button {
    font-size: 16px;
  }
`;

function CoursesByInterest({ interestSlug, itrsCourseLoadCallback, match }) {
  const { data, loading, error } = useQuery(COURSES_BY_INTEREST_QUERY, {
    variables: { slug: interestSlug },
  });
  const [showMore, setShowMore] = useState(false);
  const [urlChanged, setUrlChanged] = useState(false);
  const prevUrl = usePrevious(match.url);
  const updateCurrentStudyLevel = useMutation(CURRENT_STUDY_LEVEL_MUTATION);
  const currentStudyLevelQuery = useQuery(CURRENT_STUDY_LEVEL_QUERY);

  useEffect(() => {
    // If URL is changed, set the urlChanged flag and return. The flag removes the animation ids from dom, so they appear normally
    if (prevUrl && prevUrl !== match.url) {
      setUrlChanged(true);
      return;
    }
    // If no error, call the callback function with true, otherwise with false
    let success = false;
    if (!error && data.hasOwnProperty("courses")) {
      success = true;
    }
    itrsCourseLoadCallback(success);
  }, [prevUrl, match.url, error, data, itrsCourseLoadCallback]);

  if (loading) return "Loading...";
  if (error) return "Error";

  const coursesWithPathways = data.courses.filter(
    (course) => course.pathways.length > 0
  );
  const coursesWithoutPathways = data.courses.filter(
    (course) => course.pathways.length === 0
  );

  const coursesWithPathwaysHE = coursesWithPathways.filter((course) =>
    course.level.includes("undergraduate")
  );
  const coursesWithPathwaysVE = coursesWithPathways.filter((course) =>
    course.level.includes("vocational")
  );

  const coursesWithoutPathwaysHE = coursesWithoutPathways.filter((course) =>
    course.level.includes("undergraduate")
  );
  const coursesWithoutPathwaysVE = coursesWithoutPathways.filter((course) =>
    course.level.includes("vocational")
  );

  return (
    <>
      {coursesWithPathways.length > 0 && (
        <>
          <p
            id={
              urlChanged
                ? "exit-itrs-page-courses-top-p"
                : "itrs-page-courses-top-p"
            }
          >
            Select the course you'd like to study from the options below, each
            course has a different journey to reach your chosen degree.
          </p>
          <GuaranteedPathwaysNotificationSimple
            id={
              urlChanged
                ? "exit-itrs-page-courses-gp-notification"
                : "itrs-page-courses-gp-notification"
            }
          />

          <Filter
            id={
              urlChanged
                ? "exit-itrs-page-courses-ve-filter"
                : "itrs-page-courses-ve-filter"
            }
          >
            <h4>Filter courses by study level:</h4>
            <label htmlFor="studyLevelHigher">
              <input
                type="radio"
                value="higher"
                name="studyLevel"
                id="studyLevelHigher"
                className={
                  currentStudyLevelQuery.data.currentStudyLevel ===
                  "higher-education"
                    ? "checked"
                    : ""
                }
                onClick={() =>
                  updateCurrentStudyLevel({
                    variables: { currentStudyLevel: "higher-education" },
                  })
                }
              />
              <SVG src={`${process.env.PUBLIC_URL}/icons/icon-radio.svg`} />{" "}
              Higher education
            </label>
            <label htmlFor="studyLevelVocational">
              <input
                type="radio"
                value="vocational"
                name="studyLevel"
                id="studyLevelVocational"
                className={
                  currentStudyLevelQuery.data.currentStudyLevel === "vocational"
                    ? "checked"
                    : ""
                }
                onClick={() =>
                  updateCurrentStudyLevel({
                    variables: { currentStudyLevel: "vocational" },
                  })
                }
              />
              <SVG src={`${process.env.PUBLIC_URL}/icons/icon-radio.svg`} />{" "}
              Vocational (TAFE)
            </label>{" "}
            <label htmlFor="studyLevelAll">
              <input
                type="radio"
                value="all-courses"
                name="studyLevel"
                id="studyLevelAll"
                defaultChecked
                className={
                  currentStudyLevelQuery.data.currentStudyLevel ===
                  "all-courses"
                    ? "checked"
                    : ""
                }
                onClick={() =>
                  updateCurrentStudyLevel({
                    variables: { currentStudyLevel: "all-courses" },
                  })
                }
              />
              <SVG src={`${process.env.PUBLIC_URL}/icons/icon-radio.svg`} /> See
              all courses
            </label>
          </Filter>

          <FilterStudyLevelModalButton
            id={
              urlChanged
                ? "exit-itrs-page-study-level-modal-btn"
                : "itrs-page-study-level-modal-btn"
            }
          >
            Learn more about study levels
          </FilterStudyLevelModalButton>

          <CardListWrap
            filterValue={currentStudyLevelQuery.data.currentStudyLevel}
          >
            {coursesWithPathwaysHE.length > 0 &&
              coursesWithPathwaysHE.map((course) => (
                <CourseListItem
                  key={course.codeUnique}
                  course={course}
                  className={
                    urlChanged
                      ? "exit-course-card-list-item"
                      : "course-card-list-item"
                  }
                />
              ))}
            {coursesWithPathwaysVE.length > 0 &&
              coursesWithPathwaysVE.map((course) => (
                <CourseListItem
                  key={course.codeUnique}
                  course={course}
                  className={
                    urlChanged
                      ? "exit-course-card-list-item"
                      : "course-card-list-item"
                  }
                />
              ))}
            {coursesWithPathwaysVE.length === 0 &&
              currentStudyLevelQuery.data.currentStudyLevel ===
                "vocational" && (
                <>
                  <SubHeaderText
                    className="beta red"
                    id={
                      urlChanged
                        ? "exit-itrs-page-courses-no-ve-header"
                        : "itrs-page-courses-no-ve-header"
                    }
                  >
                    No Vocational (TAFE) pathway options
                  </SubHeaderText>
                  <p
                    id={
                      urlChanged
                        ? "exit-itrs-page-courses-no-ve-p"
                        : "itrs-page-courses-no-ve-p"
                    }
                  >
                    There are currently no Vocational (TAFE) pathways available
                    in this interest area. Use the filter above to explore
                    higher education pathways, or select a new interest area.
                  </p>
                </>
              )}
          </CardListWrap>
        </>
      )}
      {coursesWithPathways.length > 0 && coursesWithoutPathways.length > 0 && (
        <hr
          id={urlChanged ? "exit-itrs-page-courses-hr" : "itrs-page-courses-hr"}
        />
      )}
      {coursesWithoutPathways.length > 0 && (
        <>
          <SubHeaderText
            className="beta red"
            id={
              urlChanged
                ? "exit-itrs-page-courses-bottom-header"
                : "itrs-page-courses-bottom-header"
            }
          >
            Courses with no pathway options
          </SubHeaderText>
          <p
            id={
              urlChanged
                ? "exit-itrs-page-courses-bottom-p"
                : "itrs-page-courses-bottom-p"
            }
          >
            Unfortunately we are unable to offer formal pathways into these
            courses. Try browsing by another interest area or please contact{" "}
            <a
              href="https://www.rmit.edu.au/contact/visit-us/info-corner"
              target="_blank"
              rel="noopener noreferrer"
            >
              Study@RMIT
            </a>{" "}
            to discuss your options.
          </p>
          <ul
            className={`list--styled ${showMore ? `more-visible` : ``}`}
            id={
              urlChanged
                ? "exit-itrs-page-courses-bottom-ul"
                : "itrs-page-courses-bottom-ul"
            }
          >
            {coursesWithoutPathwaysHE.map((course, index) => (
              <li
                className={`delta ${index >= 10 ? `hidden` : ``}`}
                key={course.codeUnique}
                data-id={course.codeUnique}
              >
                {course.title}
              </li>
            ))}
            {coursesWithoutPathwaysVE.map((course, index) => (
              <li
                className={`delta ${index >= 10 ? `hidden` : ``}`}
                key={course.codeUnique}
                data-id={course.codeUnique}
              >
                {course.title}
              </li>
            ))}
          </ul>
          {coursesWithoutPathways.length > 10 && (
            <ShowMoreButton
              id={
                urlChanged
                  ? "exit-itrs-page-courses-show-more-button"
                  : "itrs-page-courses-show-more-button"
              }
              type="button"
              className={`${showMore ? `more-visible` : ``}`}
              onClick={() => setShowMore(true)}
            >
              <span>
                Show <em>{coursesWithoutPathways.length - 10} more</em> courses
                without a Pathway option
                <SVG src={process.env.PUBLIC_URL + "/icons/arrow-down.svg"} />
              </span>
            </ShowMoreButton>
          )}
        </>
      )}
    </>
  );
}

CoursesByInterest.propTypes = {
  interestSlug: PropTypes.string.isRequired,
};

export default CoursesByInterest;
