import styled from "styled-components";

const Tile = styled.div`
  box-shadow: 0 2px 7px 0 #ebebeb;
  a,
  > button {
    display: flex;
    height: 100%;
    flex-direction: column;
    padding: 30px 20px 20px 20px;
    align-items: center;
    text-align: center;
    box-sizing: border-box;
    border: 4px solid white;
    transition: border ease-in-out 0.25s, background ease-in-out 0.25s;
    @media only screen and (min-width: 900px) {
      padding: 25% 20px;
    }
    &:hover {
      color: ${(props) => props.theme.textPrimary};
      border-color: ${(props) => props.theme.textPrimary};
      .text {
        text-decoration: underline;
      }
    }
    &:focus {
      outline: none;
      color: ${(props) => props.theme.textPrimary};
      background: transparent;
      border-color: ${(props) => props.theme.highlightBlue};
      .text {
        text-decoration: underline;
      }
    }
    &:active {
      color: white;
      background: ${(props) => props.theme.textPrimary};
      border-color: ${(props) => props.theme.textPrimary};
    }

    svg {
      width: 50px;
      height: 50px;
      @media only screen and (min-width: 900px) {
        width: 80px;
        height: 80px;
      }
    }
  }
  img {
    margin: 0 0 16px 0;
    @media only screen and (max-width: 899px) {
      width: 60px;
    }
  }
  .text {
    margin-top: 10px;
    @media only screen and (max-width: 899px) {
      font-size: 16px;
      letter-spacing: 0;
      line-height: 19px;
    }
  }
  &.tile--flat {
    border: none;
    box-shadow: none;
    a,
    > button {
      border: none;
      box-shadow: none;
      padding: 25px 15px;
      font-size: 14px;
      position: relative;
      width: 100%;
      transition: none;
      min-height: 160px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      svg {
        width: 55px;
      }
      &:hover {
        .text {
          text-decoration: underline;
        }
      }
      &:focus {
        &:after {
          content: "";
          width: calc(100% + 1px);
          height: calc(100% + 1px);
          position: absolute;
          top: 0px;
          left: 0px;
        }
      }
      &:active {
        color: white;
        .text {
          text-decoration: none;
        }
        &:after {
          content: "";
          width: calc(100% + 1px);
          height: calc(100% + 1px);
          position: absolute;
          top: 0px;
          left: 0px;
        }
        &:hover {
          .text {
            text-decoration: none;
          }
        }
      }
    }
    &.active {
      button,
      a {
        pointer-events: none;
        &:after {
          content: "";
          width: calc(100% + 1px);
          height: calc(100% + 1px);
          position: absolute;
          top: 0px;
          left: 0px;
          border: 2px solid ${(props) => props.theme.textPrimary};
        }
      }
      &.tile--navy a,
      &.tile--navy > button {
        background: ${(props) => props.theme.textPrimary};
        color: white;
        &:after {
          border: 1px solid ${(props) => props.theme.textPrimary};
        }
      }
    }
    &.tile--blue a,
    &.tile--blue > button {
      &:after {
        border: 1px solid ${(props) => props.theme.highlightBlue};
      }
      &:focus {
        &:after {
          border: 2px solid ${(props) => props.theme.highlightBlue};
        }
      }
      &:active {
        background: ${(props) => props.theme.highlightBlue};
        &:after {
          border: 1px solid ${(props) => props.theme.highlightBlue};
        }
        &:hover {
          .text {
            text-decoration: none;
          }
        }
      }
    }
    &.tile--navy a {
      &:focus {
        &:after {
          border: 2px solid ${(props) => props.theme.textPrimary};
        }
      }
      &:active {
        background: ${(props) => props.theme.textPrimary};
        &:after {
          border: 1px solid ${(props) => props.theme.textPrimary};
        }
        &:hover {
          .text {
            text-decoration: none;
          }
        }
      }
    }
    &.tile--has-svg {
      button {
        padding: 25px;
        @media only screen and (max-width: 899px) {
          padding: 25px 25px 25px 100px;
          min-height: 95px;
          justify-content: center;
          .isvg {
            position: absolute;
            left: 25px;
            top: 50%;
            transform: translateY(-50%);
            svg {
              width: 45px;
            }
          }
        }
      }
    }
  }
`;

export default Tile;
