import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledAnimImageWrapper = styled.div`
	position: relative;
`;

const AnimImageDown = styled.div`
	height: 100%;
	transform: ${props => props.translateFrom};
`;
const AnimImageReveal = styled.div`
	overflow: hidden;
	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: ${props => props.background};
		z-index: 2;
	}
	&.in {
		&:after {
			height: 0%;
			transition: height 400ms cubic-bezier(0.3, 0, 0.3, 1);
		}
		.anim-item__down {
			transform: ${props => props.translateTo};
			transition: transform 400ms cubic-bezier(0.3, 0, 0.3, 1);
		}
	}
`;



function AnimImageWrapper({children, reveal, translateFrom, translateTo, background}) {
	return (
	  <StyledAnimImageWrapper className="anim-item__wrapper">
		<AnimImageReveal 
			className={[
				'anim-item__reveal',
				reveal === true ? 'in' : ''
				
				]}
			translateTo={translateTo}
			background={background}>
			<AnimImageDown 
				className="anim-item__down" 
				translateFrom={translateFrom}
				
				>
				{children}
			</AnimImageDown>
		</AnimImageReveal>
	  </StyledAnimImageWrapper>
	);
  }

  AnimImageWrapper.propTypes = {
	reveal: PropTypes.bool,
	translateFrom: PropTypes.string,
	translateTo: PropTypes.string,
	background: PropTypes.string
  };
  
  export default AnimImageWrapper;
