import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-apollo-hooks";
import gql from "graphql-tag";
import styled from "styled-components";

import CardListWrap from "./CardListWrap";
import CourseListItem from "./CourseListItem";
import SubHeaderText from "./SubHeaderText";

const COURSES_BY_INTEREST_QUERY = gql`
  query COURSES_BY_INTEREST_QUERY($id: ID!) {
    courses(
      orderBy: title_ASC
      where: { interests_some: { id: $id }, pathways_some: { id_not: "" } }
    ) {
      id
      title
      codeUnique
      level
      interests(first: 1) {
        id
      }
      pathways {
        id
        title
        guaranteedPathway
      }
    }
  }
`;

const StyledAlternativeOptions = styled.div`
  margin-top: 35px;
  @media only screen and (min-width: 900px) {
    margin-top: 50px;
  }
`;

function AlternativeOptions({ interestId }) {
  const { data, loading, error } = useQuery(COURSES_BY_INTEREST_QUERY, {
    variables: { id: interestId },
  });
  if (loading) return "Loading...";
  if (error) return "Error";
  return (
    <>
      {data.courses.length > 0 && (
        <StyledAlternativeOptions>
          <SubHeaderText className="beta blue">
            Browse alternative pathway options
          </SubHeaderText>
          <CardListWrap>
            {data.courses.map((course) => (
              <CourseListItem key={course.codeUnique} course={course} />
            ))}
          </CardListWrap>
        </StyledAlternativeOptions>
      )}
    </>
  );
}

AlternativeOptions.propTypes = {
  interestId: PropTypes.string.isRequired,
};

export default AlternativeOptions;
