import React from 'react';
import PropTypes from 'prop-types';


function CourseInterests({ component, interests }) {
  const Component = component;
  return (
    <>
      <Component key={interests[0].id} interest={interests[0]} />
      {interests[0].relatedInterests.map(interest => (
        <Component key={interest.id} interest={interest} />
      ))}
    </>
  )
}

CourseInterests.propTypes = {
  component: PropTypes.func.isRequired,
  interests: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      slug: PropTypes.string
    })
  ).isRequired,
};

export default CourseInterests;
