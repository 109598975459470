import React from "react";
import styled from "styled-components";
import { Controller, Scene } from "react-scrollmagic";

import SubHeaderText from "./SubHeaderText";
import ContactCards from "./ContactCards";
import ContactCard from "./ContactCard";
import ContactInnerInfo from "./ContactInnerInfo";
import ContactInnerForm from "./ContactInnerForm";
import ContactInnerLocation from "./ContactInnerLocation";

const StyledHomeContact = styled.div`
  position: relative;
  padding: 40px 20px 50px;

  @media only screen and (min-width: 700px) {
    padding: 40px 40px 60px;
  }
  @media only screen and (min-width: 900px) {
    padding: 100px 50px 100px;
  }
  @media only screen and (min-width: 1200px) {
    padding: 100px 10vw 140px;
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 390px;
    background-color: ${(props) => props.theme.highlightBlue};
    z-index: -1;
    @media only screen and (min-width: 700px) {
      height: 240px;
    }
    @media only screen and (min-width: 900px) {
      height: 330px;
    }
  }
  .gamma.gamma--home {
    color: white;
    &:before {
      color: ${(props) => props.theme.textPrimary};
    }
  }
  > .isvg {
    position: absolute;
    left: 20px;
    @media only screen and (min-width: 700px) {
      display: none;
    }
  }
`;

const HomeContactInner = styled.div`
  max-width: 1570px;
  margin: 0 auto;
  &.fade-down__items {
    .fade-down__item {
      opacity: 0;
      transform: translateY(-25px);
      &.fade-down__item--0 {
        transition: all 400ms 100ms cubic-bezier(0.3, 0, 0.3, 1);
      }
      &.fade-down__item--1 {
        transition: all 400ms 200ms cubic-bezier(0.3, 0, 0.3, 1);
      }
      &.fade-down__item--2 {
        transition: all 400ms 300ms cubic-bezier(0.3, 0, 0.3, 1);
      }
      &.fade-down__item--3 {
        transition: all 400ms 400ms cubic-bezier(0.3, 0, 0.3, 1);
      }
      &.fade-down__item--4 {
        transition: all 400ms 500ms cubic-bezier(0.3, 0, 0.3, 1);
      }
      &.fade-down__item--5 {
        transition: all 400ms 600ms cubic-bezier(0.3, 0, 0.3, 1);
      }
    }
    &--in {
      .fade-down__item {
        opacity: 1;
        transform: translateY(0px);
      }
    }
  }
`;

function HomeContact() {
  return (
    <StyledHomeContact id="trigger__contact">
      <Controller>
        <Scene
          triggerElement={"#trigger__contact"}
          reverse={false}
          triggerHook={0}
          offset={-400}
          duration="100%"
        >
          {(progress) => (
            <HomeContactInner
              className={[
                "fade-down__items",
                progress > 0 ? "fade-down__items--in" : "",
              ]}
            >
              <SubHeaderText className="gamma gamma--home fade-down__item fade-down__item--0">
                Got questions? Get in touch with Study@RMIT
              </SubHeaderText>
              <ContactCards type="contact-cards--home">
                <ContactCard
                  classes="fade-down__item fade-down__item--1"
                  type="card--home"
                  icon="communication"
                >
                  <ContactInnerInfo />
                </ContactCard>
                <ContactCard
                  classes="fade-down__item fade-down__item--2"
                  type="card--home"
                  icon="information-technology"
                >
                  <ContactInnerForm />
                </ContactCard>
                <ContactCard
                  classes="fade-down__item fade-down__item--3"
                  type="card--home"
                  icon="navy-global"
                >
                  <ContactInnerLocation />
                </ContactCard>
              </ContactCards>
            </HomeContactInner>
          )}
        </Scene>
      </Controller>
    </StyledHomeContact>
  );
}

export default HomeContact;
