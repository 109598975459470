import styled from "styled-components";

const Filter = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
  h4 {
    margin-bottom: 10px;
    flex: 1 0 100%;
    font-weight: 400;
    margin-top: 0;
  }
  label {
    padding: 10px 20px 10px 10px;

    flex: 1 0 100%;
    background-color: rgba(211, 244, 255, 40%);
    margin-top: 10px;
    border-radius: 7px;
    font-size: 16px;
    line-height: 22px;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;

    @media only screen and (min-width: 700px) {
      max-width: 240px;
      flex: 1 1 auto;
      margin-top: 0;
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .isvg {
    border: 2px solid ${(props) => props.theme.textPrimary};
    background-color: white;
    border-radius: 5px;
    display: flex;
    width: 26px;
    height: 26px;
    justify-content: center;
    align-items: center;
    padding: 2px;
    margin-right: 10px;
    svg {
      opacity: 0;
    }
  }
  input {
    height: 1px;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    &.checked {
      + .isvg {
        svg {
          opacity: 1;
        }
      }
    }
  }
`;

export default Filter;
