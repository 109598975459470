import styled from 'styled-components';

const ModalHeader = styled.div`
	grid-column: content-start / content-end;
	grid-row-start: 1;
	@media only screen and (min-width: 1200px) {
		grid-column: content-start / content-aside;
	}
	@media only screen and (min-width: 900px) {
	h1 + p {
		margin-top: -20px;
	}
	}
	p {
		max-width: 660px;
		&:last-child {
			margin-bottom: 60px;
		}
	}
`;

export default ModalHeader;
