/* eslint-disable quotes */
import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import SVG from "react-inlinesvg";
import { Controller, Scene } from "react-scrollmagic";
import { useMutation } from "react-apollo-hooks";
import { TOGGLE_FAQS_MUTATION } from "./FaqsModal";
import SubHeaderText from "./SubHeaderText";

const SubHeaderLink = styled.div`
  position: absolute;
  left: 25px;
  bottom: 25px;
  @media only screen and (min-width: 700px) {
    left: 45px;
    bottom: 40px;
  }
  @media only screen and (min-width: 900px) {
    right: 50px;
    top: 80px;
    bottom: auto;
    left: auto;
  }
`;
const FaqsPanelInner = styled.div`
  @media only screen and (max-width: 899px) {
    margin-bottom: 50px;
  }

  &.fade-down__items {
    .fade-down__item {
      opacity: 0;
      transform: translateY(-25px);
      &.fade-down__item--0 {
        transition: all 400ms 100ms cubic-bezier(0.3, 0, 0.3, 1);
      }
      &.fade-down__item--1 {
        transition: all 400ms 200ms cubic-bezier(0.3, 0, 0.3, 1);
      }
      &.fade-down__item--2 {
        transition: all 400ms 300ms cubic-bezier(0.3, 0, 0.3, 1);
      }
      &.fade-down__item--3 {
        transition: all 400ms 400ms cubic-bezier(0.3, 0, 0.3, 1);
      }
      &.fade-down__item--4 {
        transition: all 400ms 500ms cubic-bezier(0.3, 0, 0.3, 1);
      }
      &.fade-down__item--5 {
        transition: all 400ms 600ms cubic-bezier(0.3, 0, 0.3, 1);
      }
    }
    &--in {
      .fade-down__item {
        opacity: 1;
        transform: translateY(0px);
      }
    }
  }
  .button-link {
    border: 0;
    background: transparent;
    cursor: pointer;
  }
`;

const FaqsPanel = styled.div`
  margin: 40px 20px 50px;
  @media only screen and (min-width: 700px) {
    margin: 40px 40px 100px;
  }
  @media only screen and (min-width: 900px) {
    margin: 40px 50px 100px;
  }
  @media only screen and (min-width: 1200px) {
    margin: 0 10vw 100px;
  }
  .faqs-panel__box {
    max-width: 1570px;
    margin: 0 auto;
    box-shadow: 0 2px 7px 0 #ebebeb;
    padding: 20px 20px 30px;
    position: relative;
    @media only screen and (min-width: 700px) {
      padding: 40px 40px 60px;
    }
  }
  .inner {
    margin: 20px -15px 0;
    position: relative;
    display: flex;
    flex-wrap: wrap;
  }
  .item {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    box-shadow: 0 2px 7px 0 #ebebeb;
    margin: 0 15px 20px;
    &:last-child {
      margin-bottom: 0;
    }
    .faq-question {
      cursor: pointer;

      display: flex;
      padding: 20px 15px;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      border: none;
      text-align: left;
      background-color: white;
      margin: 0;
      &:focus {
        outline: none;
      }
      @media only screen and (min-width: 900px) {
        padding: 15px;
      }
      .q-letter {
        flex: 1 0 26px;
        margin-right: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        height: 26px;
        width: 26px;
        line-height: 16px;
        font-size: 16px;
        color: ${(props) => props.theme.highlightBlue};
        background-color: ${(props) => props.theme.highlightLightBlue};
        font-family: "Museo700", serif;
        @media only screen and (min-width: 700px) {
          height: 32px;
          width: 32px;
          flex: 1 0 32px;
        }
        @media only screen and (min-width: 900px) {
          flex: 1 0 48px;
          margin-right: 30px;
          padding: 10px;
          height: 48px;
          width: 48px;
          line-height: 26px;
          font-size: 26px;
        }
      }
      .svg-wrap {
        margin-left: 15px;
        svg {
          width: 20px;
        }
        @media only screen and (min-width: 900px) {
          margin-left: 30px;
          svg {
            width: 24px;
          }
        }
      }
      h4 {
        margin: 0;
        flex: 1 1 100%;
      }
    }
    .faq-answer {
      padding: 15px;
      display: none;
      width: 100%;
      position: relative;
      @media only screen and (min-width: 900px) {
        padding: 30px;
      }
      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
      &:before {
        content: "";
        width: 100%;
        height: 1px;
        background-color: ${(props) => props.theme.highlightLightBlue};
        position: absolute;
        top: 0px;
        left: 0px;
      }
      a {
        text-decoration: underline;
      }
    }
    &.active {
      .svg-wrap {
        svg {
          transform: rotate(180deg);
        }
      }
      .faq-answer {
        display: block;
      }
    }
  }
`;

function HomeFaqsByUserType({ userType }) {
  const toggleFaqs = useMutation(TOGGLE_FAQS_MUTATION, {
    variables: { faqsActive: true },
  });
  const [activeQuestion, setActiveQuestion] = useState("");
  const faqs = [
    {
      question: "What is a pathway and how do they work?",
      answer:
        "<p>A pathway is an alternative way to get into a course. RMIT's flexible pathways can help you move through different levels of study to reach the qualification you're aiming for. Some courses provide guaranteed entry and credit, which can help you reduce the time it takes to complete your studies.</p>",
    },
    {
      question: "How much time will I save off my degree?",
      answer:
        "<p>This will depend on which path of study you choose, and if you qualify for any credit transfers. Most pathways will provide credit, reducing the time of the degree. Please check each pathway for more details.</p>",
    },
    {
      question:
        "Will I be guaranteed entry to my degree after completing a pathway?",
      answer:
        "<p>Some courses provide guaranteed entry and credit if you meet the requirements. This can help you to reduce the time it takes to complete your studies. Please check the course page for more details on entry requirements.</p>",
    },
    {
      question: "How do I apply for a pathway?",
      answer:
        '<p>To apply, visit the course page for the first course in the pathway and follow the <a href="https://www.rmit.edu.au/study-with-us/applying-to-rmit/local-student-applications/how-to-apply">application process</a>.</p><p>Note that you’ll need to apply for each course in a pathway as you progress along the journey.</p>',
    },
  ];
  return (
    <FaqsPanel id="trigger__faqs">
      <div className="faqs-panel__box">
        <Controller>
          <Scene
            triggerElement={"#trigger__faqs"}
            reverse={false}
            triggerHook={0}
            offset={-400}
            duration="100%"
          >
            {(progress) => (
              <FaqsPanelInner
                className={[
                  "fade-down__items",
                  progress > 0 ? "fade-down__items--in" : "",
                ]}
              >
                <SubHeaderText className="gamma gamma--home red fade-down__item fade-down__item--0">
                  Need help understanding pathways?
                </SubHeaderText>
                <SubHeaderLink className=" fade-down__item fade-down__item--0">
                  <button
                    type="button"
                    className="delta chevron-link button-link"
                    onClick={toggleFaqs}
                  >
                    <SVG
                      src={`${process.env.PUBLIC_URL}/icons/arrow-right.svg`}
                    />
                    <span className="text"> More FAQs</span>
                  </button>
                </SubHeaderLink>

                <div className="inner">
                  {faqs.map((faq, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div
                      key={index}
                      className={
                        activeQuestion === `answer-${index}`
                          ? `item active fade-down__item fade-down__item--${index}`
                          : `item fade-down__item fade-down__item--${index}`
                      }
                    >
                      <button
                        className="faq-question"
                        type="button"
                        id={`question-${index}`}
                        aria-controls={`answer-${index}`}
                        onClick={() =>
                          setActiveQuestion(
                            activeQuestion === `answer-${index}`
                              ? ""
                              : `answer-${index}`
                          )
                        }
                      >
                        <span className="q-letter">Q</span>
                        <h4 className="omega">{faq.question}</h4>
                        <span className="svg-wrap">
                          <SVG
                            src={`${process.env.PUBLIC_URL}/icons/accordion-arrow.svg`}
                          />
                        </span>
                      </button>
                      <div
                        className="faq-answer"
                        id={`answer-${index}`}
                        role="region"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: faq.answer }}
                        aria-labelledby={`question-${index}`}
                      />
                    </div>
                  ))}
                </div>
              </FaqsPanelInner>
            )}
          </Scene>
        </Controller>
      </div>
    </FaqsPanel>
  );
}

HomeFaqsByUserType.propTypes = {
  userType: PropTypes.string,
};

HomeFaqsByUserType.defaultProps = {
  userType: "",
};

export default HomeFaqsByUserType;
