import styled, { keyframes } from "styled-components";

const button = keyframes`
  0%  { 
    transform: scale(0.95,1.05) translateY(-1000px); 
    opacity: 0;
    }
  42% { 
    transform: scale(0.95,1.05) translateY(0);  
    opacity: 1; 
    }
  45% { 
    transform: scale(1.1,0.9) translateY(0) ;  
    opacity: 1; 
    }
  52% { 
    transform: scale(1,1) translateY(-60px) ;  
    opacity: 1; 
  }
  57% { 
    transform: scale(1,1) translateY(-80px) ;  
    opacity: 1; 
    }
  67% { 
    transform: scale(1,1) translateY(-100px) ;  
    opacity: 1; 
  }
  77% { 
    transform: scale(1,1) translateY(-80px) ;  
    opacity: 1; 
  }
  82% { 
    transform: scale(1,1) translateY(-60px) ;  
    opacity: 1; 
  }
  87% { 
    transform: scale(1.1,0.9) translateY(0px) ;  
    opacity: 1; 
    }
  92% { 
    transform: scale(1,1.1) translateY(0px) ;  
    opacity: 1; 
    }
  100% { 
    transform: scale(1,1) translateY(0);  
    opacity: 1; 
    }
`;
const grow = keyframes`
  0%  { 
    width: 50px;
    }
  100% { 
    width: 240px;
    }
`;

const HomeHeroInner = styled.div`
  &.fade-down__items {
    .anim__button {
      transform: scale(0.95, 1.05) translateY(-1000px);
      opacity: 0;
      width: 50px;
      position: relative;
      padding: 0 15px;
      transition: all 800ms 1800ms cubic-bezier(0.3, 0, 0.3, 1);
      span {
        width: 0px;
        opacity: 0;
        overflow: hidden;
        white-space: nowrap;
        height: 20px;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 50%;
        transition: opacity 250ms 2000ms cubic-bezier(0.3, 0, 0.3, 1);
      }
    }
    .fade-down__item {
      opacity: 0;
      transform: translateY(-25px);
      /* 1200ms + start time of 1200ms++ */
      &.fade-down__item--0 {
        transition: all 500ms 1400ms cubic-bezier(0.3, 0, 0.3, 1);
      }
      &.fade-down__item--1 {
        transition: all 500ms 1500ms cubic-bezier(0.3, 0, 0.3, 1);
      }
      &.fade-down__item--2 {
        transition: all 500ms 1600ms cubic-bezier(0.3, 0, 0.3, 1);
      }
      &.fade-down__item--3 {
        transition: all 500ms 1700ms cubic-bezier(0.3, 0, 0.3, 1);
      }
      &.fade-down__item--4 {
        transition: all 500ms 1800ms cubic-bezier(0.3, 0, 0.3, 1);
      }
      &.fade-down__item--5 {
        transition: all 500ms 3600ms cubic-bezier(0.3, 0, 0.3, 1);
      }
    }
    &--in {
      .fade-down__item {
        opacity: 1;
        transform: translateY(0px);
      }
      .anim__button {
        animation-name: ${button}, ${grow};
        animation-duration: 1500ms, 550ms;
        animation-delay: 550ms, 1900ms;
        animation-timing-function: linear, ease-out;
        animation-fill-mode: forwards, forwards;
        span {
          width: 120px;
          margin: 0 auto;
          overflow: auto;
          height: auto;
          opacity: 1;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  p {
    text-shadow: 1px 1px 1px white;
  }
  .button-link {
    background-color: transparent;
    text-shadow: 1px 1px 1px white;
    &:focus {
      outline: 0;
      color: ${(props) => props.theme.highlightRed};
    }
  }
`;

export default HomeHeroInner;
