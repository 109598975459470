/**
 * This class handles animations of study level page.
 *
 */
import anime from "animejs";

export default class studyLevelAnimation {
  constructor(animationService) {
    this.animationService = animationService;
    this.verticalNavStudyLevelId = "vertical-nav-bar-study-level";
    this.studyLevelPageId = "study-level-page";
  }

  _hideStudyLevelPage() {
    this.animationService._hideItemById(this.studyLevelPageId);
  }

  _showStudyLevelPage() {
    this.animationService._showItemById(this.studyLevelPageId);
  }

  _getStudyLevelPageTiles() {
    return document.getElementsByClassName("study-level-page-content-tile");
  }

  _initStudyLevelPageEnterAnimation() {
    // Set indexes based on their position in the animation queue.
    this.studyLevelPageFirstEnterAnimIndex = 0;
    this.studyLevelPageTilesEnterAnimIndex = 1;

    this.studyLevelPageEnterAnimQueue = [
      {
        animObject: anime.timeline({
          easing: "cubicBezier(0.3, 0, 0.3, 1)",
        }),
        addAnimation: this._addStudyLevelPageFirstAnimation.bind(this),
        // Dom is ready for the first animation
        domReady: true,
        began: false,
        added: false,
      },
      {
        animObject: anime.timeline({
          easing: "cubicBezier(0.3, 0, 0.3, 1)",
        }),
        addAnimation: this._addStudyLevelPageTilesAnimation.bind(this),
        domReady: false,
        began: false,
        added: false,
      },
    ];
  }

  addStudyLevelTilesToStudyLevelPageEnterAnimation(success) {
    if (!success) {
      return;
    }
    // Exit if the animation has already been added
    if (
      this.studyLevelPageEnterAnimQueue[this.studyLevelPageTilesEnterAnimIndex]
        .added
    ) {
      return;
    }
    // The dom is ready
    this.studyLevelPageEnterAnimQueue[
      this.studyLevelPageTilesEnterAnimIndex
    ].domReady = true;
    // If an exit animation is running, exit
    if (this.animationService.exitAnimationRunning) {
      return;
    }
    this.animationService._tryToAddAnimation(
      this.studyLevelPageEnterAnimQueue,
      this.studyLevelPageTilesEnterAnimIndex
    );
  }

  _addStudyLevelPageTilesAnimation() {
    let beAddedAt =
      this.studyLevelPageEnterAnimQueue[this.studyLevelPageFirstEnterAnimIndex]
        .animObject.duration - 200;
    // If it's not early to add the animation
    if (
      this.studyLevelPageEnterAnimQueue[this.studyLevelPageFirstEnterAnimIndex]
        .animObject.currentTime >= beAddedAt
    ) {
      // Give the animation a small delay so it's visible
      this._createStudyLevelTilesEnterAnimation(200);
      return;
    }
    // It's early to add the animation, set a timeout
    setTimeout(() => {
      this._createStudyLevelTilesEnterAnimation();
    }, beAddedAt - this.studyLevelPageEnterAnimQueue[this.studyLevelPageFirstEnterAnimIndex].animObject.currentTime);
  }

  _getGroupedRows(tiles) {
    let tileRows = {};
    // for (let til of tiles) {
    //     let top = til.getClientRects()[0].top;
    //     if (!tileRows.hasOwnProperty(top)) {
    //         tileRows[top] = [];
    //     }
    //     tileRows[top].push(til);
    // }
    for (let i = 0; i < tiles.length; i++) {
      let top = tiles[i].getClientRects()[0].top;
      if (!tileRows.hasOwnProperty(top)) {
        tileRows[top] = [];
      }
      tileRows[top].push(tiles[i]);
    }
    return tileRows;
  }

  _createStudyLevelTilesEnterAnimation(InitialDelay = 0) {
    let tiles = this._getStudyLevelPageTiles();
    // Group tiles in rows to be animataed one after another
    let tileRows = this._getGroupedRows(tiles);
    let offset = "0";
    for (let key in tileRows) {
      let inputObject = {
        targets: tileRows[key],
        translateY: [-25, 0],
        opacity: [0, 1],
        duration: 300,
        delay: InitialDelay,
      };
      // If first item, add complete callback
      if (offset === "0") {
        inputObject.complete = (anim) => {
          // If an exit animation is running, exit
          if (this.animationService.exitAnimationRunning) {
            return;
          }
          this.studyLevelPageEnterAnimQueue[
            this.studyLevelPageTilesEnterAnimIndex
          ].began = true;
          this.animationService._tryToAddAnimation(
            this.studyLevelPageEnterAnimQueue,
            this.studyLevelPageTilesEnterAnimIndex + 1
          );
        };
      }
      this.studyLevelPageEnterAnimQueue[
        this.studyLevelPageTilesEnterAnimIndex
      ].animObject.add(inputObject, offset);
      offset = "-=200";
      InitialDelay = 0;
    }
    // If it's a page transition, exit
    if (
      this.studyLevelPageEnterAnimQueue[this.studyLevelPageFirstEnterAnimIndex]
        .pageTransition
    ) {
      //anime.set('#study-level-page-footer', {opacity: 1});
      return;
    }
    // First load, animate footer
    this.studyLevelPageEnterAnimQueue[
      this.studyLevelPageTilesEnterAnimIndex
    ].animObject
      .add(
        {
          targets: "#study-level-page-footer",
          opacity: [0, 1],
          translateY: [25, 0],
          duration: 300,
        },
        "-=200"
      )
      .add(
        {
          targets: "#study-level-page-content-link",
          translateY: [-25, 0],
          opacity: [0, 1],
          duration: 300,
        },
        "-=200"
      );
  }

  runStudyLevelPageExitAnimation(match) {
    // Set this flag, so no entering animation is added anymore
    this.animationService.exitAnimationRunning = true;
    // Init enter animation object
    this.animationService.animationsByPath[match.path].initEnterAnimation(true);
    // Hide entering page
    this.animationService.animationsByPath[match.path].hidePage();
    this.studyLevelPageExitAnime = anime.timeline({
      easing: "cubicBezier(0.3, 0, 0.3, 1)",
    });
    let tiles = this._getStudyLevelPageTiles();
    let tileRows = this._getGroupedRows(tiles);
    // Get reversed array of keys
    let tileRowKeys = Object.keys(tileRows).reverse();

    // interest tiles
    let offset = "0";
    tileRowKeys.forEach((key) => {
      this.studyLevelPageExitAnime.add(
        {
          targets: tileRows[key],
          translateY: [0, -25],
          opacity: [1, 0],
          duration: 300,
        },
        offset
      );
      offset = "-=200";
    });
    this.studyLevelPageExitAnime
      // P inside content container
      .add(
        {
          targets: "#study-level-page-content-p",
          translateY: [0, -25],
          opacity: [1, 0],
          duration: 300,
        },
        "-=200"
      )
      .add(
        {
          targets: "#study-level-page-content-link",
          translateY: [0, -25],
          opacity: [1, 0],
          duration: 300,
        },
        300
      )
      // Page header
      .add({
        targets: "#study-level-page-header-text",
        translateY: [0, -25],
        opacity: [1, 0],
        duration: 300,
      })
      // Nav bar - unhighlight the active item
      .add({
        targets: "#exit-vertical-nav-bar-study-level",
        backgroundColor: this.animationService.textDark,
        color: "#fff",
        duration: 300,
      });

    // Run the new page's enter animation when exit is finished
    this.studyLevelPageExitAnime.finished.then(() => {
      this.animationService.exitAnimationRunning = false;
      this.animationService.animationsByPath[match.path].runEnterAnimation(
        true
      );
    });
  }

  endStudyLevelPageAnimation(node, done) {
    // If animation object doesn't exist, call done and exit
    if (!this.studyLevelPageExitAnime) {
      done();
      return;
    }
    // Call done when exit animation is finished
    this.studyLevelPageExitAnime.finished.then(() => {
      done();
    });
  }

  runStudyLevelPageEnterAnimation(pageTransition = false) {
    // If it's not a page transition, init the animation object because it hasen't been already.
    if (!pageTransition) {
      this._initStudyLevelPageEnterAnimation();
    } else {
      // anime.set("#study-level-page-footer", { opacity: 1 });
      this._showStudyLevelPage();
    }
    this.animationService._tryToAddAnimation(
      this.studyLevelPageEnterAnimQueue,
      this.studyLevelPageFirstEnterAnimIndex
    );
  }

  _addStudyLevelPageFirstAnimation() {
    this.studyLevelPageEnterAnimQueue[
      this.studyLevelPageFirstEnterAnimIndex
    ].animObject
      // Nav bar - button comes in
      .add({
        targets: "#vertical-nav-bar-study-level",

        duration: 300,
        complete: (anim) => {
          // If an exit animation is running, exit
          if (this.animationService.exitAnimationRunning) {
            return;
          }
          this.studyLevelPageEnterAnimQueue[
            this.studyLevelPageFirstEnterAnimIndex
          ].began = true;
          this.animationService._tryToAddAnimation(
            this.studyLevelPageEnterAnimQueue,
            this.studyLevelPageFirstEnterAnimIndex + 1
          );
        },
      })

      // Page header and faq, search buttons. At the same time as Nav bar highlight
      .add(
        {
          targets: "#study-level-page-header-text, #page-button-wrap",
          translateY: [-25, 0],
          opacity: [0, 1],
          duration: 300,
        },
        "-=300"
      )
      // content container
      .add(
        {
          targets: "#study-level-page-content-card",
          scaleY: [0, 1],
          transformOrigin: "0% 0% 0%",
          duration: 400,
        },
        "-=200"
      )
      // P inside content container
      .add({
        targets: "#study-level-page-content-p",
        translateY: [-25, 0],
        opacity: [0, 1],
        duration: 300,
      })

      // Nav bar - highlight the active item
      .add(
        {
          targets: ".nav-item--active",
          backgroundColor: this.animationService.themeHighlightBlue,
          color: this.animationService.textDark,
          opacity: 1,
          duration: 300,
        },
        300
      );
  }

  _createStudyLevelPageFirstAnimationOnTransition() {
    this.studyLevelPageEnterAnimQueue[
      this.studyLevelPageFirstEnterAnimIndex
    ].animObject
      // Nav bar - highlight the active item
      .add({
        targets: "#vertical-nav-bar-study-level",
        duration: 300,
        complete: (anim) => {
          // If an exit animation is running, exit
          if (this.animationService.exitAnimationRunning) {
            return;
          }
          this.studyLevelPageEnterAnimQueue[
            this.studyLevelPageFirstEnterAnimIndex
          ].began = true;
          this.animationService._tryToAddAnimation(
            this.studyLevelPageEnterAnimQueue,
            this.studyLevelPageFirstEnterAnimIndex + 1
          );
        },
      })
      // Nav bar - highlight the active item
      .add(
        {
          targets: ".nav-item--active",
          backgroundColor: this.animationService.themeHighlightBlue,
          color: this.animationService.textDark,
          opacity: 1,
          duration: 300,
        },
        "-=200"
      )
      // Page header at the same time as Nav bar highlight
      .add(
        {
          targets: "#study-level-page-header-text",
          translateY: [-25, 0],
          opacity: [0, 1],
          duration: 300,
        },
        "-=300"
      )
      // P inside content container
      .add(
        {
          targets: "#study-level-page-content-p",
          translateY: [-25, 0],
          opacity: [0, 1],
          duration: 300,
        },
        "-=200"
      );
  }

  _createStudyLevelPageFirstAnimation() {
    this.studyLevelPageEnterAnimQueue[
      this.studyLevelPageFirstEnterAnimIndex
    ].animObject
      // Nav bar - button comes in
      .add({
        targets: "#vertical-nav-bar-study-level",

        duration: 300,
        complete: (anim) => {
          // If an exit animation is running, exit
          if (this.animationService.exitAnimationRunning) {
            return;
          }
          this.studyLevelPageEnterAnimQueue[
            this.studyLevelPageFirstEnterAnimIndex
          ].began = true;
          this.animationService._tryToAddAnimation(
            this.studyLevelPageEnterAnimQueue,
            this.studyLevelPageFirstEnterAnimIndex + 1
          );
        },
      })

      // Page header and faq, search buttons. At the same time as Nav bar highlight
      .add(
        {
          targets: "#study-level-page-header-text, #page-button-wrap",
          translateY: [-25, 0],
          opacity: [0, 1],
          duration: 300,
        },
        "-=300"
      )
      // content container
      .add(
        {
          targets: "#study-level-page-content-card",
          scaleY: [0, 1],
          transformOrigin: "0% 0% 0%",
          duration: 400,
        },
        "-=200"
      )
      // P inside content container
      .add({
        targets: "#study-level-page-content-p",
        translateY: [-25, 0],
        opacity: [0, 1],
        duration: 300,
      })

      // Nav bar - highlight the active item
      .add(
        {
          targets: ".nav-item--active",
          backgroundColor: this.animationService.themeHighlightBlue,
          color: this.animationService.textDark,
          opacity: 1,
          duration: 300,
        },
        300
      );
  }
}
