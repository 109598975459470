const SORT_STEPS_IN_ORDER_OF = [
  "pre-university-study/victorian-certificate-of-education",
  "vocational-study/certificates",
  "vocational-study/diplomas",
  "vocational-study/advanced-diplomas",
  "undergraduate-study/associate-degrees",
  "undergraduate-study/bachelor-degrees",
  "undergraduate-study/honours-degrees",
];

// levels of study derived from courses.csv
// "pre-university-study/victorian-certificate-of-education"
// "vocational-study/certificates"
// "vocational-study/diplomas"
// "vocational-study/advanced-diplomas"
// "undergraduate-study/associate-degrees"
// "undergraduate-study/bachelor-degrees"
// "undergraduate-study/honours-degrees"

export { SORT_STEPS_IN_ORDER_OF };
