import styled from 'styled-components';

const SharePopUpModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 84, 0.4);
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 25;
  &.active {
    display: flex;
  }
`;

export default SharePopUpModal;
