import React from 'react';
import styled from 'styled-components';
import { Controller, Scene } from 'react-scrollmagic';
import SVG from 'react-inlinesvg';

import Error404 from '../components/Error404';
import ContactButton from '../components/ContactButton';
import Footer from '../components/Footer';



const Error404Wrap = styled.main`
  display: inline-block;
  width: 100%;
  grid-column: 1 / -1;
  
`;

const ErrorNav = styled.div`
	padding: 20px 20px 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	display: flex;
	justify-content: flex-start;
	@media only screen and (min-width: 900px) {
		padding: 30px 3vw 0;
	}
	&.fade-down__items {
		.fade-down__item {
			opacity: 0;
			transform: translateY(-25px);
			z-index: 10;
			&.fade-down__item {
				transition: all 400ms 800ms cubic-bezier(0.3, 0, 0.3, 1);
			}
		}
		&--in {
			.fade-down__item {
				opacity: 1;
				transform: translateY(0px);
				
			}
			+ .hero__wrap:before {
				z-index: -100;
				height: calc(100% + 100px);
			}
		}
	}
`;
const LogoWrap = styled.div`
  width: 120px;
  margin-right: 3vw;
  background-color : transparent;
  @media only screen and (min-width: 900px) {
    width: 142px;
  }
  @media only screen and (min-width: 1200px) {
    width: 189px;
  }
  svg {
    width: 100%;
  }
`;

const Error404ButtonWrap = styled.div`
    text-align: left;
    margin: 0;
    position: absolute;
    top: 30px;
    right: 3vw;
    display: flex;
`;

const Error404Page = () => (
	<Error404Wrap className="content-wrap" id="trigger__error-logo">
		<Controller>
			<Scene
				triggerElement={'#trigger__error-logo'}
				reverse={false}
				triggerHook={0}
				offset="-100"
				duration="100%"
			>
				{(progress, event) => (
					<ErrorNav className={[
						'fade-down__items',
						progress > 0 ? 'fade-down__items--in' : ''
					]}
					>
						<LogoWrap className="fade-down__item">
							<SVG src={`${process.env.PUBLIC_URL}/icons/primary-logo.svg`} />
						</LogoWrap>
						<Error404ButtonWrap className="fade-down__item">
							<ContactButton />
						</Error404ButtonWrap>
					</ErrorNav>
				)}
			</Scene>
		</Controller>
		<Error404 />
		<Footer className="footer--home" />
	</Error404Wrap>
);

export default Error404Page;
