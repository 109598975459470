import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-apollo-hooks";
import gql from "graphql-tag";

import CourseSidebarWrap from "./CourseSidebarWrap";
import CourseSidebarItem from "./CourseSidebarItem";

const COURSES_BY_INTEREST_QUERY = gql`
  query COURSES_BY_INTEREST_QUERY($slug: String!) {
    courses(orderBy: title_ASC, where: { interests_some: { slug: $slug } }) {
      id
      title
      codeUnique
      interests(first: 1) {
        id
        slug
      }
      pathways {
        id
        title
      }
      level
    }
  }
`;

function CourseSidebar({
  interestSlug,
  match,
  courseOtherCourseLoadCallback,
  itemClassName,
  wrapperId,
}) {
  const { data, loading, error } = useQuery(COURSES_BY_INTEREST_QUERY, {
    variables: { slug: interestSlug },
  });
  useEffect(() => {
    // If no error, call the callback function with true, otherwise with false
    let success = false;
    if (!error && data.hasOwnProperty("courses")) {
      success = true;
    }
    courseOtherCourseLoadCallback(success);
  });
  if (loading) return "Loading...";
  if (error) return "Error";
  let coursesWithPathways = [];
  let coursesWithPathwaysHE = [];
  let coursesWithPathwaysVE = [];
  if (data.courses) {
    coursesWithPathways = data.courses.filter(
      (course) => course.pathways.length > 0
    );
    coursesWithPathwaysHE = coursesWithPathways.filter((course) =>
      course.level.includes("undergraduate")
    );
    coursesWithPathwaysVE = coursesWithPathways.filter((course) =>
      course.level.includes("vocational")
    );
  }
  return (
    <>
      {coursesWithPathways.length > 0 && (
        <>
          <CourseSidebarWrap id={wrapperId}>
            {coursesWithPathwaysHE.length > 0 && (
              <h3 className={itemClassName}>Higher education</h3>
            )}
            {coursesWithPathwaysHE.map((course) => (
              <CourseSidebarItem
                key={course.codeUnique}
                course={course}
                match={match}
                className={itemClassName}
              />
            ))}
            {coursesWithPathwaysVE.length > 0 && (
              <h3 className={itemClassName}>Vocational (TAFE)</h3>
            )}
            {coursesWithPathwaysVE.map((course) => (
              <CourseSidebarItem
                key={course.codeUnique}
                course={course}
                match={match}
                className={itemClassName}
              />
            ))}
          </CourseSidebarWrap>
        </>
      )}
    </>
  );
}

CourseSidebar.propTypes = {
  interestSlug: PropTypes.string.isRequired,
};

export default CourseSidebar;
