import styled from 'styled-components';

const ModalAside = styled.div`
	grid-column: content-start / content-end;
	margin-top: 35px;
	@media only screen and (min-width: 1200px) {
		grid-row-start: 3;
		grid-column: content-aside / aside-end;
		margin-top: 75px;
	}
	.vertical {
		flex-direction: column;
	}
`;

export default ModalAside;
