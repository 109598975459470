import React from 'react';
import styled from 'styled-components';

const StyledContactIntro = styled.div`
  position: relative;

  h2 {
    font-family: 'HelveticaNeue-Bold', Helvetica, Arial, sans-serif;
    font-size: 16px;
    margin-top: 0;
  }
  p {
    max-width: 600px;
    margin-bottom: 0;
  }
  &.has-svg {
    @media only screen and (min-width: 900px) {
      margin-top: 45px;
    }
    p {
      @media only screen and (min-width: 1200px) {
        width: 70%;
      }
    }
    svg {
      position: absolute;
      right: 0;
      bottom: 0;
      display: none;
      @media only screen and (min-width: 1400px) and (max-width: 1599px) {
        display: inline-block;
      }
    }
  }
`;

function ContactIntro({ children, icon }) {
  return <StyledContactIntro>{children}</StyledContactIntro>;
}

export default ContactIntro;
