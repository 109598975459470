import styled from 'styled-components';

const ModalCLose = styled.div`
  position: absolute;
  top: 15px;
  left: 15px;
  @media only screen and (min-width: 900px) {
    top: 1vw;
    left: 0;
  }
`;

export default ModalCLose;
