import React, { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

const StyledModalWrap = styled.section`
  position: fixed;
  right: 0;
  z-index: 30;
  width: 0%;
  min-height: 100%;
  transition: width 0.8s ease-in-out;
  background-color: white;
  padding-bottom: 75px;
  -webkit-overflow-scrolling: touch;
  @media only screen and (max-width: 900px) {
    padding-bottom: 120px;
  }
  &:focus {
    outline: none;
  }
  > * {
    opacity: 0;
    transition: opacity 0.4s ease-in-out 0.8s;
  }
  &.enter-active,
  &.enter-done {
    box-shadow: 0 2px 7px 0 rgba(black, 0.5);
    display: block;
    width: 100%;
    top: 0;
    @media only screen and (min-width: 900px) {
      width: calc(100% - 95px);
    }
    > * {
      opacity: 1;
    }
  }
  &.homepage.enter-active,
  &.homepage.enter-done {
    @media only screen and (min-width: 900px) {
      top: 0;
      width: 100%;
    }
  }
  &.enter-done {
    max-height: 100vh;
    overflow-y: scroll;
  }
  &.exit {
    transition: width 0.5s ease-in-out 0s;
    > * {
      opacity: 0;
      transition: opacity 0.2s ease-in-out 0s;
    }
  }
  /* Overlay styles */
  ~ .page-wrap > .content-wrap {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0px;
      background: rgba(1, 153, 229, 0.1);
      z-index: -1;
      opacity: 0;
      transition: opacity 0.4s ease-in-out 0s;
      @media only screen and (max-width: 899px) {
        position: fixed;
      }
    }
  }
  &.enter-active ~ .page-wrap > .content-wrap,
  &.exit-active ~ .page-wrap > .content-wrap {
    &:before {
      z-index: 25;
      opacity: 1;
      @media only screen and (max-width: 899px) {
        z-index: 26;
      }
    }
  }
  &.exit ~ .page-wrap > .content-wrap {
    &:before {
      z-index: 25;
      opacity: 1;
      @media only screen and (max-width: 899px) {
        z-index: 26;
      }
    }
  }
  &.exit-active ~ .page-wrap > .content-wrap {
    &:before {
      opacity: 0;
      transition: opacity 0.1s ease-in-out;
    }
  }
  &.exit-done ~ .page-wrap > .content-wrap {
    &:before {
      z-index: -1;
      transition: opacity 0.1s ease-in-out 0s, z-index 0.1s ease-in-out 0.4s;
    }
  }
  /* Nav Styles */
  ~ .internal-page .logo {
    svg {
      position: relative;
      transition: transform 0.2s ease-in-out 0.8s;
    }
    [id^='Text'] {
      transition: opacity 0.4s ease-in-out 0.4s;
    }
  }
  &.enter-active ~ .internal-page .logo,
  &.enter-done ~ .internal-page .logo {
    svg {
      transform: translateY(5px);
    }
    [id^='Text'] {
      opacity: 0;
    }
  }
  &.exit ~ .internal-page .logo {
    svg {
      transition: transform 0.2s ease-in-out 0.1s;
    }
  }
`;

function ModalWrap({ children, className, location }) {
  const modalEl = useRef(null);
  const timeout = 800;
  return (
    <CSSTransition
      in={Boolean(className)}
      timeout={timeout}
      unmountOnExit
      onEnter={function(){ 
        modalEl.current.setAttribute('tabindex', '1');
        modalEl.current.focus();
      }}
      onExit={function(){ 
        modalEl.current.blur();
        modalEl.current.setAttribute('tabindex', '0');
      }}
      className={`${
        location.pathname === '/'
          ? `modal-transition homepage`
          : `modal-transition`
      }`}

    >
      <StyledModalWrap ref={modalEl} aria-modal="true" aria-hidden={!Boolean(className)}>{children}</StyledModalWrap>
    </CSSTransition>
  );
}

export default withRouter(ModalWrap);
