import styled from 'styled-components';

const ModalInner = styled.div`
  display: grid;
  width: 100%;
  padding-top: 100px;
  grid-template-columns: [first] 3vw [content-start] minmax(auto, 1070px) [content-end] 3vw [end];
  @media only screen and (min-width: 1200px) {
    grid-template-columns: [first] 3vw [content-start] minmax(auto, 2fr) [content-aside] minmax(
        auto,
        1fr
      ) [aside-end] 3vw [end];
    grid-gap: 0 1.5vw;
  }
  @media only screen and (min-width: 1400px) {
    grid-template-columns: [first] minmax(auto, 6vw) [content-start] minmax(
        auto,
        1070px
      ) [content-aside] minmax(auto, 385px) [aside-end] minmax(auto, 1.5vw) [end];
    grid-gap: 0 3vw;
  }
`;

export default ModalInner;
