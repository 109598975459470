import React from "react";
import SVG from "react-inlinesvg";
import { Controller, Scene } from "react-scrollmagic";
import { Link } from "react-router-dom";
import SubHeaderText from "./SubHeaderText";
import AnimImageWrapper from "./AnimImageWrapper";
import {
  ContentBlockWrap,
  ContentBlockItem,
  ContentBlockImageWrap,
  ContentBlockImage,
  ImageForeground,
  ContentBlockTextWrap,
  AnimFadeDown,
} from "./styles/ContentBlock";

function HomeWhyRMITPathways() {
  return (
    <ContentBlockWrap id="trigger__why-pathways">
      <Controller>
        <Scene
          triggerElement={"#trigger__why-pathways"}
          reverse={false}
          triggerHook={0}
          offset={-400}
          duration="100%"
        >
          {(progress) => (
            <>
              <ContentBlockItem imagePos={"left"}>
                <ContentBlockImageWrap>
                  <AnimImageWrapper
                    reveal={progress > 0 && true}
                    translateFrom={"translateY(-60px)"}
                    translateTo={"translateY(0)"}
                    background={"white"}
                  >
                    <ContentBlockImage progress={progress}>
                      <ImageForeground
                        className="content-block__foreground"
                        src={`${process.env.PUBLIC_URL}/girl-smiling@2x.jpg`}
                      ></ImageForeground>
                    </ContentBlockImage>
                  </AnimImageWrapper>
                </ContentBlockImageWrap>
                <ContentBlockTextWrap>
                  <AnimFadeDown
                    className={[
                      "fade-down-item",
                      progress > 0 ? "fade-down-item--in" : "",
                    ]}
                  >
                    <SubHeaderText className="beta beta--home red">
                      Why RMIT pathways?
                    </SubHeaderText>
                    <p className="delta">
                      Our pathways provide you with the opportunity to study a
                      bachelor degree without having the need for an ATAR score.
                    </p>
                    <p className="delta">
                      Study all the required courses from certificates to
                      degrees in one place, earning multiple qualifications
                      along the way.
                    </p>
                    <Link to="/interests" className="delta chevron-link">
                      <SVG
                        src={`${process.env.PUBLIC_URL}/icons/arrow-right.svg`}
                      />
                      <span className="text">Explore courses</span>
                    </Link>
                  </AnimFadeDown>
                </ContentBlockTextWrap>
              </ContentBlockItem>
            </>
          )}
        </Scene>
      </Controller>
    </ContentBlockWrap>
  );
}

export default HomeWhyRMITPathways;
