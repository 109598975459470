import styled from 'styled-components';

const FaqsTiles = styled.div`
	grid-column: first / end;
	grid-row-start: 2;
	display: flex;
	flex-direction: column;
	margin-top: 20px;
	position: relative;
	@media only screen and (min-width: 900px) {
		flex-direction: row;
	}
	@media only screen and (min-width: 1200px) {
		grid-column: content-start / content-aside;
	}
`;

export default FaqsTiles;
