import styled from "styled-components";

const CourseSidebarWrap = styled.div`
  margin-top: 12px;
  display: grid;
  > * {
    background: ${(props) => props.theme.sidebarBg};
    box-shadow: none;
    border: none;
  }
  h3 {
    font-family: "HelveticaNeue-Medium", Helvetica, Arial, sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 8px;
    margin-top: 24px;
    + div a {
      border-top: 1px solid ${(props) => props.theme.highlightMidBlue};
    }
  }
`;
export default CourseSidebarWrap;
