import styled from 'styled-components';

const ModalBanner = styled.div`
	height: 270px;
	grid-column: first / end;
	position: relative;
	grid-row-start: 2;
	&.short {
		height: 130px;
	}
	&:before {
		content: '';
		width: 100%;
		height: 100%;
		background: ${props => props.theme.sidebarBg};
		position: absolute;
		top: 0;
		right: 0;
		@media only screen and (min-width: 1400px) {
			width: calc(100% - 3vw);
		}
	}
`;

export default ModalBanner;
