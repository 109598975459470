import React from "react";
import styled from "styled-components";

const StyledCardListHeader = styled.h3`
  color: ${(props) => props.theme.textDark};
`;

function CardListHeader({ children }) {
  return (
    <StyledCardListHeader className="gamma">{children}</StyledCardListHeader>
  );
}

export default CardListHeader;
