import styled from 'styled-components';

const TileUserType = styled.div`
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    pointer-events: none;
    border: 1px solid ${props => props.theme.highlightBlue};
    transition: border ease-in-out 0.25s;
  }
  &.currenttype--highschool, &.currenttype-- {
    &:before {
      border: 1px solid #80ccf2;
    }
    &.active {
      &:before {
        border: 1px solid ${props => props.theme.highlightBlue};
      }
      button {
        background: ${props => props.theme.highlightBlue};
      }
    }
    &:focus-within {
      &:before {
        z-index: 1;
        border: 2px solid ${props => props.theme.highlightBlue};
      }
    }
  }
  &.currenttype--mature {
    &:before {
      border: 1px solid #f1d8f1;
    }
    &.active {
      &:before {
        border: 1px solid ${props => props.theme.midPurple};
      }
      button {
        background: ${props => props.theme.midPurple};
      }
    }
    &:focus-within {
      &:before {
        z-index: 1;
        border: 2px solid ${props => props.theme.midPurple};
      }
    }
  }
  &.currenttype--advisor {
    &:before {
      border: 1px solid #f27e92;
    }
    &.active {
      &:before {
        border: 1px solid ${props => props.theme.highlightRed};
      }
      button {
        background: ${props => props.theme.highlightRed};
      }
    }
    &:focus-within {
      &:before {
        z-index: 1;
        border: 2px solid ${props => props.theme.highlightRed};
      }
    }
  }
  &.active {
    @media only screen and (min-width: 900px) {
      + * {
        &:before {
          border-left: none;
        }
      }
    }
    &:before {
      z-index: 1;
    }
    button {
      color: white;
      .beta {
        color: white;
      }
      h3 {
        &:before {
          background: ${props => props.theme.textPrimary};
        }
      }
    }
  }

  button {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: row;
    padding: 30px 15px 22px;
    align-items: center;
    box-sizing: border-box;
    border: none;
    background-color: white;
    transition: border ease-in-out 0.25s, background ease-in-out 0.25s;
    cursor: pointer;
    @media only screen and (min-width: 900px) {
      padding: 50px 25px 32px;
      flex-direction: column;
      text-align: left;
      align-items: flex-start;
    }
    &:focus,
    &:active {
      outline: none;
    }
    &:hover {
      .text {
        text-decoration: underline;
      }
    }
  }
  .text {
    margin-top: 10px;
    @media only screen and (max-width: 899px) {
      font-size: 16px;
      letter-spacing: 0;
      line-height: 19px;
    }
  }
  svg {
    width: 45px;
    height: 45px;
    margin-right: 15px;
    @media only screen and (min-width: 900px) {
      width: 75px;
      height: 75px;
    }
  }
`;

export default TileUserType;
