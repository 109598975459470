import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledContactWrap = styled.div`
	&.contact-wrap--footer {
		margin-top: 30px;
		box-shadow: 0 2px 7px 0 #EBEBEB;
		padding: 35px;
		@media only screen and (min-width: 900px) {
			box-shadow: none;
			padding: 0;
		}
		
	}
	&.contact-wrap--sidebar {
		padding: 35px;
		box-shadow: 0 2px 7px 0 #EBEBEB;
	}
`;

function ContactWrap({ children, type, id }) {
  return <StyledContactWrap className={type} id={id} >{children}</StyledContactWrap>;
}
ContactWrap.propTypes = {
	type: PropTypes.string,
};
ContactWrap.defaultProps = {
	type: ''
  };
export default ContactWrap;
