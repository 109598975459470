import styled from 'styled-components';

const ContentCard = styled.div`
  @media only screen and (min-width: 900px) {
    box-shadow: 0 2px 7px 0 #ebebeb;
    padding: 30px 30px 40px;
    h2,
    p {
      max-width: 740px;
    }
  }
  @media only screen and (min-width: 1400px) {
    padding: 70px 70px 80px;
  }
`;

export default ContentCard;
