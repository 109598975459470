import styled from "styled-components";

const HeaderText = styled.h1`
  position: relative;
  display: flex;
  align-items: center;
  font-family: "Museo700";
  letter-spacing: 0;
  font-size: 24px;
  line-height: 24px;
  padding: 0;
  margin: 0 0 10px;
  @media only screen and (min-width: 900px) {
    font-size: 28px;
    line-height: 28px;
    margin: 25px 0;
  }
  @media only screen and (min-width: 1200px) {
    font-size: 32px;
    line-height: 32px;
    margin: 28px 0;
  }
  span.icon {
    width: 60px;
    height: 60px;
    @media only screen and (min-width: 1200px) {
      width: 80px;
      height: 80px;
    }
    svg {
      width: 60px;
      height: 60px;
      @media only screen and (min-width: 1200px) {
        width: 80px;
        height: 80px;
      }
    }
  }
  span.text {
    position: relative;
    padding: 1em 0 0 0;
    margin: 0 0 0.5em 0;
    height: 100%;
    display: inline-block;
    &:before {
      content: "—";
      position: absolute;
      top: 0;
      left: 0px;
      color: ${(props) => props.theme.highlightBlue};
    }
    &:not(.no-icon) {
      padding-left: 10px;
      @media only screen and (min-width: 1200px) {
        padding-left: 20px;
      }
      &:before {
        left: 10px;
        @media only screen and (min-width: 1200px) {
          left: 20px;
        }
      }
    }
  }
`;

export default HeaderText;
