import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-apollo-hooks";
import gql from "graphql-tag";

const INTERESTS_QUERY = gql`
  query Interests {
    interests(orderBy: title_ASC) {
      id
      title
      slug
    }
  }
`;

function Interests({ component, interestTilesLoadCallback }) {
  //const { data, loading, error } = useQuery(INTERESTS_QUERY, { skip: true }  );
  const { data, loading, error } = useQuery(INTERESTS_QUERY);

  /*
  // Component ref array
  const [nodeArray, nodeDispatch] = useReducer((nodeArray, { type, value }) => {
    switch (type) {
      case 'add':
        return [...nodeArray, value];
      default:
        return nodeArray;
    }
  }, []);
  
  // Component ref callback function. 
  const ref = useCallback(node => {
    if (node === null) {
      return;
    }
    nodeDispatch({type: 'add', value: node});
  }, []);
  */

  useEffect(() => {
    // If this component is called from somewhere else not related to our callback, exit
    if (!interestTilesLoadCallback) {
      return;
    }
    // If no error, call the callback function with true, otherwise with false
    if (!error && data.hasOwnProperty("interests")) {
      interestTilesLoadCallback(true);
    } else {
      interestTilesLoadCallback(false);
    }
  });

  if (loading) return "Loading...";
  if (error) return "Error";
  const Component = component;
  return data.interests.map((interest) => (
    <Component
      key={interest.id}
      interest={interest}
      className="interests-page-content-tile" /* ref={componentRefCallback} */
    />
  ));
}

Interests.propTypes = {
  component: PropTypes.func.isRequired,
};

export default Interests;
