import styled from "styled-components";
import SVG from "react-inlinesvg";
import { useMutation } from "react-apollo-hooks";
import { TOGGLE_STUDY_LEVEL_MODAL_MUTATION } from "../components/StudyLevelModal";
const StyledStudyLevelModalButton = styled.span`
  display: inline-block;
  margin-top: 15px;
  button {
    background-color: transparent;
    border: none;
    font-size: 16px;
    &:hover {
      cursor: pointer;
    }
  }
  &.large {
    margin-top: 0;
    button {
      @media screen and (min-width: 900px) {
        font-size: 20px;
      }
    }
  }
`;

function StudyLevelModalButton({ id, className, children }) {
  const toggleStudyLevelModal = useMutation(TOGGLE_STUDY_LEVEL_MODAL_MUTATION, {
    variables: { studyLevelModalActive: true },
  });
  return (
    <StyledStudyLevelModalButton id={id} className={className}>
      <button
        type="button"
        className="chevron-link button-link"
        onClick={() => {
          toggleStudyLevelModal();
        }}
      >
        <SVG src={`${process.env.PUBLIC_URL}/icons/arrow-right.svg`} />
        <span className="text">{children}</span>
      </button>
    </StyledStudyLevelModalButton>
  );
}

export default StudyLevelModalButton;
