import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import SVG from "react-inlinesvg";
import Tile from "./Tile";

function SideNavInterestTile({ ...props }) {
  return (
    <Tile
      className={`tile--${props.type} tile--${props.color} ${
        props.currentInterest === props.interest.slug ? "active" : ""
      } ${props.className}`}
    >
      <Link to={`/interest/${props.interest.slug}`}>
        <SVG
          src={`${process.env.PUBLIC_URL}/icons/icon-${props.interest.slug}.svg`}
        />
        <span className="text">{props.interest.title}</span>
      </Link>
    </Tile>
  );
}

SideNavInterestTile.propTypes = {
  interest: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  type: PropTypes.string,
  color: PropTypes.string,
  currentInterest: PropTypes.string,
};

SideNavInterestTile.defaultProps = {
  type: "",
  color: "",
  currentInterest: "",
};

export default SideNavInterestTile;
