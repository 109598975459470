import React from "react";
import { Route } from "react-router-dom";
import { Transition } from "react-transition-group";

import Nav from "./Nav";
import InterestsPage from "../pages/InterestsPage";
import StudyLevelPage from "../pages/StudyLevelPage";
import InterestPage from "../pages/InterestPage";
import CoursePage from "../pages/CoursePage";
import PathwayPage from "../pages/PathwayPage";
import Footer from "./Footer";
import PageButtonWrap from "../components/PageButtonWrap";
import FaqsButton from "../components/FaqsButton";
import SearchButton from "../components/SearchButton";

import animationService from "../lib/animation/animationService";
import "../assets/styles/animationStyles.css";

export default class PathwayStepsRoot extends React.Component {
  constructor(props) {
    super(props);
    this.interestsPagePath = "/interests";
    this.studyLevelPagePath = "/study-level";
    this.itrsPagePath = "/interest/:slug";
    this.coursePagePath = "/course/:codeUnique";
    this.pathwayPagePath = "/pathway/:slug";
    this.animationService = new animationService();
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    // Keep previous match
    //if (prevProps.match.path !== this.props.match.path) {
    this.prevMatch = prevProps.match;
    //}
    return null;
  }

  // Only to prevent the Warning message: "getSnapshotBeforeUpdate() should be used with componentDidUpdate()"
  componentDidUpdate() {}

  interestTilesLoadCallback(success) {
    this.animationService.addInterestTilesToInterestsPageEnterAnimation(
      success
    );
  }
  studyLevelTilesLoadCallback(success) {
    this.animationService.addStudyLevelTilesToStudyLevelPageEnterAnimation(
      success
    );
  }

  itrsDataLoadCallback(success) {
    this.animationService.addRelatedToItrsPageEnterAnimation(success);
  }

  itrsOtherInterestLoadCallback(success) {
    this.animationService.addOtherToItrsPageEnterAnimation(success);
  }

  itrsCourseLoadCallback(success) {
    this.animationService.addCoursesToItrsPageEnterAnimation(success);
  }

  courseDataLoadCallback(success) {
    this.animationService.addItemsToCoursePageEnterAnimation(
      success,
      "headers"
    );
  }

  courseOtherCourseLoadCallback(success) {
    this.animationService.addItemsToCoursePageEnterAnimation(success, "other");
  }

  coursePathwayLoadCallback(success) {
    this.animationService.addItemsToCoursePageEnterAnimation(
      success,
      "pathways"
    );
  }

  pathwayDataLoadCallback(success) {
    this.animationService.addItemsToPathwayPageEnterAnimation(
      success,
      "content"
    );
  }

  pathwayOptionsLoadCallback(success) {
    this.animationService.addItemsToPathwayPageEnterAnimation(
      success,
      "options"
    );
  }

  // Run InterestsPage's entering animation
  handleInterestsPageEnter(node, isAppearing) {
    // If it's coming from any page under PathwayStepsRoot, exit. Because enter animation has already been called from the previous page's exit animation.
    if (this.prevMatch) {
      return;
    }
    this.animationService.runInterestsPageEnterAnimation();
  }

  // Run InterestsPage's exiting animation
  handleInterestsPageExit(node) {
    this.animationService.runInterestsPageExitAnimation(this.props.match);
  }

  interestsPageEndListener(node, done) {
    this.animationService.endInterestsPageAnimation(node, done);
  }

  studyLevelPageEndListener(node, done) {
    this.animationService.endStudyLevelPageAnimation(node, done);
  }

  itrsPageEndListener(node, done) {
    this.animationService.endItrsPageAnimation(node, done);
  }

  coursePageEndListener(node, done) {
    this.animationService.endCoursePageAnimation(node, done);
  }

  pathwayPageEndListener(node, done) {
    this.animationService.endPathwayPageAnimation(node, done);
  }

  // Run StudyLevelPage's entering animation
  handleStudyLevelPageEnter(node, isAppearing) {
    // If it's coming from any page under PathwayStepsRoot, exit. Because enter animation has already been called from the previous page's exit animation.
    if (this.prevMatch) {
      return;
    }
    this.animationService.runStudyLevelPageEnterAnimation();
  }

  // Run InterestPage's entering animation
  handleItrsPageEnter(node, isAppearing) {
    // If it's coming from any page under PathwayStepsRoot, exit. Because enter animation has already been called from the previous page's exit animation.
    if (this.prevMatch) {
      return;
    }
    this.animationService.runItrsPageEnterAnimation();
  }

  handleCoursePageEnter(node, isAppearing) {
    // If it's coming from any page under PathwayStepsRoot, exit. Because enter animation has already been called from the previous page's exit animation.
    if (this.prevMatch) {
      return;
    }
    this.animationService.runCoursePageEnterAnimation();
  }

  handlePathwayPageEnter(node, isAppearing) {
    // If it's coming from any page under PathwayStepsRoot, exit. Because enter animation has already been called from the previous page's exit animation.
    if (this.prevMatch) {
      return;
    }
    this.animationService.runPathwayPageEnterAnimation();
  }

  // Run InterestPage's exiting animation
  handleItrsPageExit(node) {
    this.animationService.runItrsPageExitAnimation(this.props.match);
  }

  handleStudyLevelPageExit(node) {
    this.animationService.runStudyLevelPageExitAnimation(this.props.match);
  }

  handleCoursePageExit(node) {
    this.animationService.runCoursePageExitAnimation(this.props.match);
  }

  handlePathwayPageExit(node) {
    this.animationService.runPathwayPageExitAnimation(this.props.match);
  }

  // Keep the last match object of interest page to be passed to it between transitions,
  // when the current match is null (when interest page is exiting it's not the current page and its match is null in route children)
  updateMatch() {
    if (this.props.match.path === this.studyLevelPagePath) {
      this.studyLevelPageMatch = this.props.match;
    }
    if (this.props.match.path === this.itrsPagePath) {
      this.itrsPageMatch = this.props.match;
    }
    if (this.props.match.path === this.coursePagePath) {
      this.coursePageMatch = this.props.match;
    }
    if (this.props.match.path === this.pathwayPagePath) {
      this.pathwayPageMatch = this.props.match;
    }
  }

  render() {
    this.updateMatch();
    return (
      <>
        <Nav location={this.props.location} match={this.props.match} />
        <PageButtonWrap id="page-button-wrap">
          <FaqsButton />
          <SearchButton />
        </PageButtonWrap>
        <Route path={this.interestsPagePath} exact>
          {({ match }) => (
            <Transition
              in={match != null}
              appear
              unmountOnExit
              onEnter={this.handleInterestsPageEnter.bind(this)}
              onExit={this.handleInterestsPageExit.bind(this)}
              addEndListener={this.interestsPageEndListener.bind(this)}
            >
              <InterestsPage
                interestTilesLoadCallback={this.interestTilesLoadCallback.bind(
                  this
                )}
              />
            </Transition>
          )}
        </Route>
        <Route path={this.studyLevelPagePath} exact>
          {({ match }) => (
            <Transition
              in={match != null}
              appear
              unmountOnExit
              onEnter={this.handleStudyLevelPageEnter.bind(this)}
              onExit={this.handleStudyLevelPageExit.bind(this)}
              addEndListener={this.studyLevelPageEndListener.bind(this)}
            >
              <StudyLevelPage
                match={this.studyLevelPageMatch}
                studyLevelTilesLoadCallback={this.studyLevelTilesLoadCallback.bind(
                  this
                )}
              />
            </Transition>
          )}
        </Route>
        <Route path={this.itrsPagePath} exact>
          {({ match }) => (
            <Transition
              in={match != null}
              appear
              unmountOnExit
              onEnter={this.handleItrsPageEnter.bind(this)}
              onExit={this.handleItrsPageExit.bind(this)}
              addEndListener={this.itrsPageEndListener.bind(this)}
            >
              <InterestPage
                match={this.itrsPageMatch}
                itrsDataLoadCallback={this.itrsDataLoadCallback.bind(this)}
                itrsOtherInterestLoadCallback={this.itrsOtherInterestLoadCallback.bind(
                  this
                )}
                itrsCourseLoadCallback={this.itrsCourseLoadCallback.bind(this)}
              />
            </Transition>
          )}
        </Route>
        <Route path={this.coursePagePath.toUpperCase()} exact>
          {({ match }) => (
            <Transition
              in={match != null}
              appear
              unmountOnExit
              onEnter={this.handleCoursePageEnter.bind(this)}
              onExit={this.handleCoursePageExit.bind(this)}
              addEndListener={this.coursePageEndListener.bind(this)}
            >
              <CoursePage
                match={this.coursePageMatch}
                courseDataLoadCallback={this.courseDataLoadCallback.bind(this)}
                courseOtherCourseLoadCallback={this.courseOtherCourseLoadCallback.bind(
                  this
                )}
                coursePathwayLoadCallback={this.coursePathwayLoadCallback.bind(
                  this
                )}
              />
            </Transition>
          )}
        </Route>
        <Route path={this.pathwayPagePath.toUpperCase()} exact>
          {({ match }) => (
            <Transition
              in={match != null}
              appear
              unmountOnExit
              onEnter={this.handlePathwayPageEnter.bind(this)}
              onExit={this.handlePathwayPageExit.bind(this)}
              addEndListener={this.pathwayPageEndListener.bind(this)}
            >
              <PathwayPage
                match={this.pathwayPageMatch}
                pathwayDataLoadCallback={this.pathwayDataLoadCallback.bind(
                  this
                )}
                pathwayOptionsLoadCallback={this.pathwayOptionsLoadCallback.bind(
                  this
                )}
              />
            </Transition>
          )}
        </Route>
        <Footer
          id={
            this.props.match.path === this.interestsPagePath
              ? "interests-page-footer"
              : "exit-interests-page-footer"
          }
        />
      </>
    );
  }
}
