import React from "react";
import { useMutation } from "react-apollo-hooks";
import { Link } from "react-router-dom";
import { Controller, Scene } from "react-scrollmagic";
import SVG from "react-inlinesvg";

import { TOGGLE_SEARCH_MUTATION } from "./SearchModal";
import Button from "./Button";
import HomeHeroWrap from "./styles/HomeHeroWrap";
import HomeHeroInner from "./styles/HomeHeroInner";

function HomeHero() {
  const toggleSearch = useMutation(TOGGLE_SEARCH_MUTATION, {
    variables: { searchActive: true },
  });
  return (
    <HomeHeroWrap id="trigger__hero" className="hero__wrap">
      <Controller>
        <Scene
          triggerElement={"#trigger__hero"}
          reverse={false}
          triggerHook={0}
          offset="-100"
          duration="100%"
        >
          {(progress, event) => (
            <HomeHeroInner
              className={[
                "fade-down__items",
                progress > 0 && "fade-down__items--in",
              ]}
            >
              <h1 className="fade-down__item fade-down__item--1">
                Find your pathway to study at RMIT
              </h1>
              <h2 className="beta fade-down__item fade-down__item--2">
                Explore your study options with RMIT, through individual or
                packaged pathways, to reach your study goals.
              </h2>
              <h3 className="delta fade-down__item fade-down__item--3">
                Use our tool to find the right pathway to your dream degree.
              </h3>
              <Link to="/interests">
                <Button className="red anim__button">
                  <span className="text">Explore courses</span>
                </Button>
              </Link>
              <p className="para fade-down__item fade-down__item--4">
                or{" "}
                <button
                  type="button"
                  className="button-link"
                  onClick={toggleSearch}
                >
                  <span className="text">use search to find your course</span>
                </button>
              </p>
              <div className="scroll fade-down__item fade-down__item--5">
                <SVG src={process.env.PUBLIC_URL + "/icons/mouse.svg"} />
                <p className="text">scroll</p>
              </div>
            </HomeHeroInner>
          )}
        </Scene>
      </Controller>
    </HomeHeroWrap>
  );
}

export default HomeHero;
