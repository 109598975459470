import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledContactCards = styled.div`
  margin: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  @media only screen and (min-width: 800px) and (max-width: 1199px) {
    flex-direction: row;
  }
  @media only screen and (min-width: 1200px) {
    flex-direction: column;
  }
  .svg-wrap {
    display: none;
    flex: 1 0 1px;
    padding: 0 10px;
    align-items: flex-end;
    @media only screen and (min-width: 1600px) {
      display: flex;
    }
    svg {
      margin-left: 10%;
      width: 90%;
      height: auto;
    }
  }
  &.contact-cards--column {
    @media only screen and (min-width: 800px) {
      > * {
        margin-top: 20px;
      }
    }
    @media only screen and (min-width: 800px) and (max-width: 1199px) {
      margin:0 -10px 0;
    }
  }
  &.contact-cards--row {
    @media only screen and (min-width: 800px) {
      flex-direction: row;
      margin: 40px -10px 0;
    }
    .card--border {
      @media only screen and (min-width: 800px) and (max-width: 900px) {
        padding: 0 10px;
      }
    }
  }
  &.contact-cards--home {
    box-shadow: 0 2px 7px 0 rgba(1, 153, 230, 0.1);
    position: relative;
    @media only screen and (min-width: 700px) {
      flex-direction: row;
      margin: 40px -10px 0;
      box-shadow: none;
    }
    .card--border {
      @media only screen and (min-width: 700px) and (max-width: 900px) {
        padding: 0 10px;
      }
    }
    > .isvg {
      position: absolute;
      right: 30px;
      top: 30px;
      @media only screen and (min-width: 700px) {
        display: none;
      }
    }
  }
`;

function ContactCards({ children, type }) {
  return <StyledContactCards className={type}>{children}</StyledContactCards>;
}

ContactCards.propTypes = {
  type: PropTypes.string
};
ContactCards.defaultProps = {
  type: ''
};

export default ContactCards;
