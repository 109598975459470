import React from 'react';
import PropTypes from 'prop-types';

import PathwaysByCourse from './PathwaysByCourse';
import PathwaySidebarItem from './PathwaySidebarItem';

function PathwaySidebar({ codeUnique, pathwayItemsLoadCallback, className, wrapperId, match }) {
  return (
    <>
      <div id={wrapperId} >
        <PathwaysByCourse
          codeUnique={codeUnique}
          component={PathwaySidebarItem}
          coursePathwayLoadCallback={pathwayItemsLoadCallback}
          className={className}
          match={match}
        />
      </div>
    </>
  );
}

PathwaySidebar.propTypes = {
  codeUnique: PropTypes.string.isRequired
};

export default PathwaySidebar;
