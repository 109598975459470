import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useQuery, useMutation } from "react-apollo-hooks";
import gql from "graphql-tag";
import SVG from "react-inlinesvg";
import styled from "styled-components";
import { Helmet } from "react-helmet";

import ContentNotFound from "../components/ContentNotFound";
import ContentWithSidebar from "../components/ContentWithSidebar";
import Sidebar from "../components/Sidebar";
import SubHeaderText from "../components/SubHeaderText";
import CourseSidebar from "../components/CourseSidebar";
import Main from "../components/Main";
import HeaderText from "../components/HeaderText";
import SectionBreak from "../components/SectionBreak";
import ContentCard from "../components/ContentCard";
import ContentCardInnerMax from "../components/ContentCardInnerMax";
import PathwaysByCourseList from "../components/PathwaysByCourseList";
import PathwaysByCourse from "../components/PathwaysByCourse";
import PathwayListItem from "../components/PathwayListItem";
import Filter from "../components/Filter";
import ContactWrap from "../components/ContactWrap";
import ContactIntro from "../components/ContactIntro";
import ContactCards from "../components/ContactCards";
import ContactCard from "../components/ContactCard";
import ContactInnerInfo from "../components/ContactInnerInfo";
import ContactInnerForm from "../components/ContactInnerForm";
import ContactInnerLocation from "../components/ContactInnerLocation";
import { GuaranteedPathwaysNotification } from "../components/GuaranteedPathwaysNotification";
import usePrevious from "../lib/usePrevious";

const COURSE_QUERY = gql`
  query COURSE_QUERY($codeUnique: String!) {
    course(where: { codeUnique: $codeUnique }) {
      id
      title
      codeUnique
      level
      interests {
        id
        title
        slug
      }
      pathways {
        id
      }
    }
  }
`;

const DisclaimerText = styled.p`
  font-style: italic;
  @media only screen and (min-width: 900px) {
    margin: -20px 0 60px 0;
  }
  @media only screen and (min-width: 1200px) {
    margin: -50px 0 60px 0;
  }
`;

const CURRENT_COURSE_MUTATION = gql`
  mutation updateCurrentCourse($currentCourse: String!) {
    updateCurrentCourse(currentCourse: $currentCourse) @client
  }
`;
const CURRENT_INTEREST_MUTATION = gql`
  mutation updateCurrentInterest($currentInterest: String!) {
    updateCurrentInterest(currentInterest: $currentInterest) @client
  }
`;

function CoursePage({
  match,
  courseDataLoadCallback,
  courseOtherCourseLoadCallback,
  coursePathwayLoadCallback,
}) {
  const { data, loading, error } = useQuery(COURSE_QUERY, {
    variables: { codeUnique: match.params.codeUnique.toUpperCase() },
  });
  const updateCurrentCourse = useMutation(CURRENT_COURSE_MUTATION);
  const updateCurrentInterest = useMutation(CURRENT_INTEREST_MUTATION);
  const [urlChanged, setUrlChanged] = useState(false);
  const [filterValue, setFilterValue] = useState(null);

  const prevUrl = usePrevious(match.url);
  useEffect(() => {
    // If URL is changed, set the urlChanged flag and return. The flag changes the animation-related ids in the dom so entering animations don't run
    if (prevUrl && prevUrl !== match.url) {
      setUrlChanged(true);
      return;
    }
    // If no error, call the callback function with true, otherwise with false
    let success = false;
    if (!error && data.hasOwnProperty("course")) {
      success = true;
    }
    courseDataLoadCallback(success);
  }, [prevUrl, match.url, error, data, courseDataLoadCallback]);
  if (error) return "Error";

  if (data.course === null) {
    return <ContentNotFound />;
  }
  if (!loading && data.course) {
    updateCurrentCourse({
      variables: { currentCourse: data.course.codeUnique },
    });
    updateCurrentInterest({
      variables: { currentInterest: data.course.interests[0].slug },
    });
  }

  return (
    <>
      {!loading && (
        <Helmet>
          <title>{data.course.title} | RMIT Pathways</title>
          <meta
            name="description"
            content={`Browse ${data.course.title} options below to discover which pathway is right for you. Each pathway has different steps and requirements to get started.`}
          />
        </Helmet>
      )}
      <ContentWithSidebar className="content-wrap">
        <Sidebar>
          {!loading && (
            <>
              <SubHeaderText
                className="beta blue"
                id={
                  urlChanged
                    ? "exit-course-page-sidebar-header"
                    : "course-page-sidebar-header"
                }
              >
                {`Other ${
                  data.course.interests[0].title &&
                  data.course.interests[0].title
                } courses`}
              </SubHeaderText>
              <CourseSidebar
                interestSlug={data.course.interests[0].slug}
                match={match}
                courseOtherCourseLoadCallback={courseOtherCourseLoadCallback}
                itemClassName={
                  urlChanged
                    ? "exit-course-page-sidebar-item"
                    : "course-page-sidebar-item"
                }
                wrapperId={
                  urlChanged
                    ? "exit-course-page-sidebar-wrapper"
                    : "course-page-sidebar-wrapper"
                }
              />
            </>
          )}
        </Sidebar>
        <Main id="course-page">
          {loading && <HeaderText className="loading">...</HeaderText>}
          {!loading && (
            <HeaderText>
              {data.course.interests[0].title && (
                <span
                  className="icon"
                  id={
                    urlChanged
                      ? "exit-course-page-header-icon"
                      : "course-page-header-icon"
                  }
                >
                  <SVG
                    className="lg"
                    src={`${process.env.PUBLIC_URL}/icons/icon-${data.course.interests[0].slug}.svg`}
                  />
                </span>
              )}
              <span
                className="text"
                id={
                  urlChanged
                    ? "exit-course-page-header-text"
                    : "course-page-header-text"
                }
              >{`${data.course.interests[0].title}`}</span>
            </HeaderText>
          )}
          <SectionBreak>
            <span>
              <SVG
                src={`${process.env.PUBLIC_URL}/icons/icon-step-3-nav-blue.svg`}
              />
            </span>
          </SectionBreak>
          <ContentCard>
            <ContentCardInnerMax>
              <SubHeaderText
                className="beta blue"
                id={
                  urlChanged
                    ? "exit-course-page-content-header"
                    : "course-page-content-header"
                }
              >
                {!loading &&
                  `${data.course.pathways.length} ${
                    data.course.pathways.length > 1 ? "options" : "option"
                  } for ${data.course.title}`}
              </SubHeaderText>
              <p
                id={
                  urlChanged
                    ? "exit-course-page-content-p"
                    : "course-page-content-p"
                }
              >
                Browse options below to discover which pathway is right for you.
                Each pathway has different steps and requirements to get
                started.
              </p>
              <GuaranteedPathwaysNotification
                id={
                  urlChanged
                    ? "exit-course-page-content-gp-notification"
                    : "course-page-content-gp-notification"
                }
              />

              <Filter
                id={
                  urlChanged
                    ? "exit-course-page-content-gp-filter"
                    : "course-page-content-gp-filter"
                }
              >
                <h4>Filter by:</h4>
                <label htmlFor="pathwayTypeGuaranteedAll">
                  <input
                    type="radio"
                    value="null"
                    name="pathwayType"
                    id="pathwayTypeGuaranteedAll"
                    defaultChecked
                    className={filterValue === null ? "checked" : ""}
                    onClick={() => setFilterValue(null)}
                  />
                  <SVG src={`${process.env.PUBLIC_URL}/icons/icon-radio.svg`} />{" "}
                  All Pathways
                </label>
                <label htmlFor="pathwayTypeGuaranteedTrue">
                  <input
                    type="radio"
                    value="true"
                    name="pathwayType"
                    id="pathwayTypeGuaranteedTrue"
                    className={filterValue === true ? "checked" : ""}
                    onClick={() => setFilterValue(true)}
                  />
                  <SVG src={`${process.env.PUBLIC_URL}/icons/icon-radio.svg`} />{" "}
                  Guaranteed Pathways
                </label>
                <label htmlFor="pathwayTypeGuaranteedFalse">
                  <input
                    type="radio"
                    value="false"
                    name="pathwayType"
                    id="pathwayTypeGuaranteedFalse"
                    className={filterValue === false ? "checked" : ""}
                    onClick={() => setFilterValue(false)}
                  />
                  <SVG src={`${process.env.PUBLIC_URL}/icons/icon-radio.svg`} />{" "}
                  Other Pathways
                </label>
              </Filter>
              <PathwaysByCourseList gpFilter={filterValue}>
                <PathwaysByCourse
                  codeUnique={match.params.codeUnique}
                  component={PathwayListItem}
                  coursePathwayLoadCallback={coursePathwayLoadCallback}
                  gpFilter={filterValue}
                  className={
                    urlChanged
                      ? "exit-course-page-pathway-item"
                      : "course-page-pathway-item"
                  }
                />
              </PathwaysByCourseList>
              <DisclaimerText
                id={
                  urlChanged
                    ? "exit-course-page-content-disclaimer"
                    : "course-page-content-disclaimer"
                }
              >
                *Based on full-time study load of 96 credit points per year.
                Individual circumstances may vary and please be aware that not
                all courses are offered each semester. These circumstances may
                affect the total duration of a program.
              </DisclaimerText>
            </ContentCardInnerMax>
            <ContactWrap
              type="contact-wrap--footer"
              id={
                urlChanged
                  ? "exit-course-page-content-contact"
                  : "course-page-content-contact"
              }
            >
              <ContactIntro icon="group-shapes">
                <h2>Contact us</h2>
                <p>
                  If you have any questions about pathways please contact
                  Study@RMIT. Our advisors are available with extended hours
                  during the Change of Preference period.
                </p>
              </ContactIntro>
              <ContactCards type="contact-cards--row">
                <ContactCard type="card--border" icon="communication">
                  <ContactInnerInfo />
                </ContactCard>
                <ContactCard type="card--border" icon="information-technology">
                  <ContactInnerForm />
                </ContactCard>
                <ContactCard type="card--border" icon="navy-global">
                  <ContactInnerLocation />
                </ContactCard>
              </ContactCards>
            </ContactWrap>
          </ContentCard>
        </Main>
      </ContentWithSidebar>
    </>
  );
}

CoursePage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      codeUnique: PropTypes.string.isRequired,
    }),
  }), //.isRequired
};

export default CoursePage;
