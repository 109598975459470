import React from "react";
import { Controller, Scene } from "react-scrollmagic";
import SubHeaderText from "./SubHeaderText";
import AnimImageWrapper from "./AnimImageWrapper";
import {
  ContentBlockWrap,
  ContentBlockItem,
  ContentBlockImageWrap,
  ContentBlockImage,
  ImageForeground,
  ContentBlockTextWrap,
  AnimFadeDown,
} from "./styles/ContentBlock";
import StudyLevelModalButton from "./StudyLevelModalButton";

function HomeVocational() {
  return (
    <ContentBlockWrap id="trigger__vocational">
      <Controller>
        <Scene
          triggerElement={"#trigger__vocational"}
          reverse={false}
          triggerHook={0}
          offset={-400}
          duration="100%"
        >
          {(progress) => (
            <>
              <ContentBlockItem imagePos={"left"}>
                <ContentBlockImageWrap>
                  <AnimImageWrapper
                    reveal={progress > 0 && true}
                    translateFrom={"translateY(-60px)"}
                    translateTo={"translateY(0)"}
                    background={"white"}
                  >
                    <ContentBlockImage progress={progress}>
                      <ImageForeground
                        className="content-block__foreground"
                        src={`${process.env.PUBLIC_URL}/ve-pathways.jpg`}
                      ></ImageForeground>
                    </ContentBlockImage>
                  </AnimImageWrapper>
                </ContentBlockImageWrap>
                <ContentBlockTextWrap>
                  <AnimFadeDown
                    className={[
                      "fade-down-item",
                      progress > 0 ? "fade-down-item--in" : "",
                    ]}
                  >
                    <SubHeaderText className="beta beta--home red">
                      Vocational (TAFE) Pathways
                    </SubHeaderText>
                    <p className="delta">
                      A university degree is not the only pathway into your
                      dream profession. Vocational education (TAFE) offers
                      students a range of qualifications and lower entry
                      requirements, allowing more students to skill up and build
                      exciting careers.
                    </p>
                    <p className="delta">
                      RMIT now offer a range of vocational education pathways,
                      look out for the orange labels when you start exploring
                      courses!
                    </p>
                    <StudyLevelModalButton className="large">
                      Learn more about study levels
                    </StudyLevelModalButton>
                  </AnimFadeDown>
                </ContentBlockTextWrap>
              </ContentBlockItem>
            </>
          )}
        </Scene>
      </Controller>
    </ContentBlockWrap>
  );
}

export default HomeVocational;
