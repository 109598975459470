import React, { useState } from "react";
import { Link } from "react-router-dom";

import CardListItem from "./CardListItem";
import CardListHeader from "./CardListHeader";
import { CardListDetails, StudyLevelTag } from "./CardListDetails";

function CourseListItem({ course, className }) {
  // On Link click, add clicked class
  const [linkClicked, setLinkClicked] = useState(false);

  const guaranteedPathways = course.pathways.filter(
    (pathway) => pathway.guaranteedPathway === true
  );
  const isVECourse = course.level.includes("vocational-study");
  function handleLinkClick() {
    setLinkClicked(true);
  }
  return (
    <CardListItem
      className={
        (linkClicked ? "course-item-clicked" : "") +
        " " +
        className +
        " " +
        (isVECourse ? "ve-course" : "he-course")
      }
    >
      <Link to={`/course/${course.codeUnique}`} onClick={handleLinkClick}>
        <CardListHeader>{course.title}</CardListHeader>
        <CardListDetails>
          <StudyLevelTag className="study-level-tag">
            {isVECourse ? "Vocational (TAFE)" : "Higher Education"}
          </StudyLevelTag>
          <p>
            {course.pathways.length} pathway
            {course.pathways.length > 1 ? " options" : " option"}{" "}
            <span className="card-list-item--gp">
              {guaranteedPathways.length > 0 &&
                "(" +
                  guaranteedPathways.length +
                  " Guaranteed Pathway" +
                  (guaranteedPathways.length > 1 ? "s" : "") +
                  " available)"}
            </span>
          </p>
        </CardListDetails>
      </Link>
    </CardListItem>
  );
}

export default CourseListItem;
