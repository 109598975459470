import React, { useEffect } from "react";
import { useQuery, useMutation } from "react-apollo-hooks";
import gql from "graphql-tag";
import SVG from "react-inlinesvg";
import { withRouter } from "react-router-dom";

import ModalWrap from "./ModalWrap";
import ModalInner from "./ModalInner";
import ModalHeader from "./ModalHeader";
import ModalSubHeader from "./ModalSubHeader";
import ModalBanner from "./ModalBanner";
import ModalAside from "./ModalAside";
import ModalContent from "./ModalContent";
import ModalClose from "./ModalClose";
import HeaderText from "./HeaderText";
import SubHeaderText from "./SubHeaderText";
import FaqsTiles from "./FaqsTiles";
import FaqsTile from "./FaqsTile";
import FaqsByUserType from "./FaqsByUserType";
import Button from "./Button";
import ContactWrap from "./ContactWrap";
import ContactIntro from "./ContactIntro";
import ContactCards from "./ContactCards";
import ContactCard from "./ContactCard";
import ContactInnerInfo from "./ContactInnerInfo";
import ContactInnerForm from "./ContactInnerForm";
import ContactInnerLocation from "./ContactInnerLocation";

const FAQS_ACTIVE_QUERY = gql`
  query {
    faqsActive @client
  }
`;

const TOGGLE_FAQS_MUTATION = gql`
  mutation toggleFaqs($faqsActive: Boolean!) {
    toggleFaqs(faqsActive: $faqsActive) @client
  }
`;

const ACTIVE_QUESTION_IS_GP_QUERY = gql`
  query {
    activeQuestionIsGP @client
  }
`;

const ACTIVE_QUESTION_IS_GP_MUTATION = gql`
  mutation updateActiveQuestionIsGP($activeQuestionIsGP: Boolean!) {
    updateActiveQuestionIsGP(activeQuestionIsGP: $activeQuestionIsGP) @client
  }
`;

const USER_TYPE_QUERY = gql`
  query {
    userType @client
  }
`;

function FaqsModal({ location }) {
  const faqsActiveQuery = useQuery(FAQS_ACTIVE_QUERY);
  const toggleFaqs = useMutation(TOGGLE_FAQS_MUTATION);
  const userTypeQuery = useQuery(USER_TYPE_QUERY);
  const gpFaqActiveQuery = useQuery(ACTIVE_QUESTION_IS_GP_QUERY);
  const updateActiveQuestionIsGP = useMutation(ACTIVE_QUESTION_IS_GP_MUTATION);

  useEffect(() => {
    toggleFaqs({
      variables: { faqsActive: false },
    });
    updateActiveQuestionIsGP({
      variables: { activeQuestionIsGP: false },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // listen for modal change -> if modal active -> listen for keydown -> if keydown -> close modal
  const checkModalStatus = (event) => {
    if (faqsActiveQuery.data.faqsActive) {
      // if modal active
      addKeyDownListener();
    } else {
      // if modal not active
      removeKeyDownListener();
    }
  };

  const removeKeyDownListener = function () {
    setTimeout(() => {
      document.removeEventListener("keydown", checkDocumentKeyDown);
    });
  };

  const addKeyDownListener = function () {
    setTimeout(() => {
      document.addEventListener("keydown", checkDocumentKeyDown);
    });
  };

  const checkDocumentKeyDown = (event) => {
    if (event.key === "Escape" || event.key === "Esc" || event.keyCode === 27) {
      toggleFaqs({
        variables: { faqsActive: false },
      });
      updateActiveQuestionIsGP({
        variables: { activeQuestionIsGP: false },
      });
    }
  };

  checkModalStatus();

  const userTypeTiles = [
    {
      userType: "highschool",
      title: "I am a high school student",
      color: "blue",
    },
    {
      userType: "mature",
      title: "I am a mature aged student",
      color: "lavender",
    },
    {
      userType: "advisor",
      title: "I am a teacher or career advisor",
      color: "red",
    },
  ];

  return (
    <ModalWrap className={faqsActiveQuery.data.faqsActive && "active"}>
      <ModalInner>
        <ModalClose>
          <Button
            type="button"
            className="white no-border has-svg "
            onClick={() => {
              toggleFaqs({
                variables: { faqsActive: false },
              });
              updateActiveQuestionIsGP({
                variables: { activeQuestionIsGP: false },
              });
            }}
          >
            <span className="text">Close</span>
            <SVG src={process.env.PUBLIC_URL + "/icons/cross.svg"} />
          </Button>
        </ModalClose>
        <ModalHeader>
          <HeaderText>
            <span className="text no-icon">
              Need help understanding Pathways?
            </span>
          </HeaderText>
          <p>
            This Pathways finder is a guide for local students looking to
            commence study at RMIT. Applicants who have completed or are
            currently enrolled in vocational courses at RMIT should refer to the
            relevant course page as pathway requirements may differ.
          </p>
        </ModalHeader>
        <ModalBanner />
        <ModalSubHeader>
          <SubHeaderText className="beta blue">
            <span className="text">Who are you?</span>
          </SubHeaderText>
          <FaqsTiles>
            {userTypeTiles.map((tile) => (
              <FaqsTile
                key={tile.userType}
                active={userTypeQuery.data.userType}
                {...tile}
              />
            ))}
          </FaqsTiles>
        </ModalSubHeader>
        <ModalContent>
          <FaqsByUserType
            userType={userTypeQuery.data.userType}
            activeQuestionIsGP={gpFaqActiveQuery.data.activeQuestionIsGP}
          />
        </ModalContent>
        <ModalAside>
          <ContactWrap type="contact-wrap--sidebar">
            <ContactIntro>
              <h2>Contact us</h2>
              <p>
                If you have any questions about pathways please contact
                Study@RMIT. Our advisors are available with extended hours
                during the Change of Preference period.
              </p>
            </ContactIntro>
            <ContactCards type="contact-cards--column">
              <ContactCard type="card--no-border" icon="communication">
                <ContactInnerInfo />
              </ContactCard>
              <ContactCard type="card--no-border" icon="information-technology">
                <ContactInnerForm />
              </ContactCard>
              <ContactCard type="card--no-border" icon="navy-global">
                <ContactInnerLocation />
              </ContactCard>
            </ContactCards>
          </ContactWrap>
        </ModalAside>
      </ModalInner>
    </ModalWrap>
  );
}

export default withRouter(FaqsModal);
export {
  FAQS_ACTIVE_QUERY,
  TOGGLE_FAQS_MUTATION,
  ACTIVE_QUESTION_IS_GP_QUERY,
  ACTIVE_QUESTION_IS_GP_MUTATION,
};
