import ApolloClient from "apollo-boost";

const client = new ApolloClient({
  uri: process.env.REACT_APP_DATA_ENDPOINT,
  request: (operation) => {
    operation.setContext({
      fetchOptions: {
        credentials: "include",
      },
    });
  },
  clientState: {
    resolvers: {
      Mutation: {
        toggleFaqs(_, variables, { cache }) {
          const data = {
            data: {
              faqsActive: variables.faqsActive,
            },
          };
          cache.writeData(data);
          return data;
        },
        toggleSearch(_, variables, { cache }) {
          const data = {
            data: { searchActive: variables.searchActive },
          };
          cache.writeData(data);
          return data;
        },
        toggleStudyLevelModal(_, variables, { cache }) {
          const data = {
            data: {
              studyLevelModalActive: variables.studyLevelModalActive,
            },
          };
          cache.writeData(data);
          return data;
        },
        updateUserType(_, variables, { cache }) {
          const data = {
            data: { userType: variables.userType },
          };
          cache.writeData(data);
          return data;
        },
        updateActiveQuestionIsGP(_, variables, { cache }) {
          const data = {
            data: { activeQuestionIsGP: variables.activeQuestionIsGP },
          };
          cache.writeData(data);
          return data;
        },
        updateCurrentPathway(_, variables, { cache }) {
          const data = {
            data: { currentPathway: variables.currentPathway },
          };
          cache.writeData(data);
          return data;
        },
        updateCurrentCourse(_, variables, { cache }) {
          const data = {
            data: { currentCourse: variables.currentCourse },
          };
          cache.writeData(data);
          return data;
        },
        updateCurrentStudyLevel(_, variables, { cache }) {
          const data = {
            data: { currentStudyLevel: variables.currentStudyLevel },
          };
          cache.writeData(data);
          return data;
        },
        updateCurrentInterest(_, variables, { cache }) {
          const data = {
            data: { currentInterest: variables.currentInterest },
          };
          cache.writeData(data);
          return data;
        },
        setSearchNoPathway(_, variables, { cache }) {
          const data = {
            data: { searchNoPathway: variables.searchNoPathway },
          };
          cache.writeData(data);
          return data;
        },
      },
    },
    defaults: {
      faqsActive: false,
      activeQuestionIsGP: false,
      searchActive: false,
      studyLevelModalActive: false,
      userType: "highschool",
      currentPathway: "",
      currentCourse: "",
      currentInterest: "",
      currentStudyLevel: "all-courses",
      searchNoPathway: [],
    },
  },
});

export default client;
