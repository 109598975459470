import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-apollo-hooks";
import gql from "graphql-tag";

import SideNavInterestTile from "./SideNavInterestTile";

const INTERESTS_QUERY = gql`
  query Interests($interestsToExclude: [ID!]) {
    interests(orderBy: title_ASC, where: { id_not_in: $interestsToExclude }) {
      id
      title
      slug
    }
  }
`;

function InterestsWithoutRelated({
  interests,
  currentInterest,
  itrsOtherInterestLoadCallback,
}) {
  const interestsToExclude =
    interests && interests.map((interest) => interest.id);
  const { data, loading, error } = useQuery(INTERESTS_QUERY, {
    variables: { interestsToExclude },
  });

  useEffect(() => {
    // If no error, call the callback function with true, otherwise with false
    let success = false;
    if (!error && data.hasOwnProperty("interests")) {
      success = true;
    }
    itrsOtherInterestLoadCallback(success);
  });

  if (loading) return "Loading...";
  if (error) return "Error";
  return (
    <>
      {data.interests.map((interest) => (
        <SideNavInterestTile
          key={interest.id}
          interest={interest}
          currentInterest={currentInterest}
          type="flat"
          color="navy"
        />
      ))}
    </>
  );
}

InterestsWithoutRelated.propTypes = {
  interests: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    })
  ).isRequired,
  currentInterest: PropTypes.string,
};

export default InterestsWithoutRelated;
