import styled from 'styled-components';

const ContentWithSidebar = styled.div`
  grid-area: main;
  @media only screen and (min-width: 1200px) {
    display: grid;
    grid-template-columns: 1.25fr 4fr;
  }
  /* Grid fallback */
  @media only screen and (max-width: 899px) {
    padding-top: 180px;
    @supports (display:grid) {
      padding-top: 0;
    }
  }
  
`;

export default ContentWithSidebar;
