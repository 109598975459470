import styled from 'styled-components';

const ModalSubHeader = styled.div`
	grid-column: content-start / content-end;
	grid-row-start: 2;
	padding: 50px 0;
	@media only screen and (min-width: 1200px) {
		grid-column: content-start / aside-end;
	}
	@media only screen and (min-width: 1400px) {
		grid-column: content-start / content-aside;
	}
	&.short {
		padding: 40px 0;
	}
`;

export default ModalSubHeader;
