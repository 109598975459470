import React from "react";
import SVG from "react-inlinesvg";
import styled from "styled-components";
import { useMutation } from "react-apollo-hooks";
import { TOGGLE_FAQS_MUTATION, ACTIVE_QUESTION_IS_GP_MUTATION } from "./FaqsModal";

const StyledFaqsLink = styled.span`
  button {
    background-color: transparent;
    border: none;
    @media only screen and (min-width: 900px) {
      margin-top: 10px;
    }
    &:hover {
      cursor: pointer;
    }
  }
`;

export default function GuaranteedPathwaysInfoLink({ linkText }) {
  const toggleFaqs = useMutation(TOGGLE_FAQS_MUTATION, {
    variables: { faqsActive: true },
  });
  const updateActiveQuestionIsGP = useMutation(ACTIVE_QUESTION_IS_GP_MUTATION, {
    variables: { activeQuestionIsGP: true },
  });
  return (
    <StyledFaqsLink>
      <button
        type="button"
        className="delta chevron-link button-link"
        onClick={() => {toggleFaqs(); updateActiveQuestionIsGP();}}
      >
        <SVG src={`${process.env.PUBLIC_URL}/icons/arrow-right.svg`} />
        <span className="text">{linkText}</span>
      </button>
    </StyledFaqsLink>
  );
}
