import styled from "styled-components";

const RelatedInterestsGrid = styled.div`
  margin-top: 30px;
  margin-bottom: 50px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 50%));
  > * {
    background: ${(props) => props.theme.sidebarBg};
    box-shadow: none;
    border: none;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: calc(100% + 1px);
      border: 1px solid ${(props) => props.theme.highlightBlue};
      top: 0px;
      left: 0px;
      pointer-events: none;
      z-index: 0;
    }
    &:nth-child(even) {
      &:after {
        left: -1px;
      }
    }
    &.active:after,
    &:focus-within:after {
      z-index: 1;
      border: 2px solid ${(props) => props.theme.highlightBlue};
    }
  }
`;

export default RelatedInterestsGrid;
