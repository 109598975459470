import styled from "styled-components";

const NavWrap = styled.div`
  grid-area: nav;
  width: 100%;
  z-index: 26;
  @media only screen and (min-width: 900px) {
    background: ${(props) => props.theme.textDark};
    border-left: 5px solid ${(props) => props.theme.highlightBlue};
  }
  nav {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 53px;
    padding: 0 10px 0 186px;
    @media only screen and (min-width: 900px) {
      padding: 120px 0 0 0;
      justify-content: flex-start;
      flex-direction: column;
      height: auto;
    }
    .nav-item {
      color: ${(props) => props.theme.textPrimary};
      padding: 18px 10px 10px;
      display: flex;
      flex: 0 1 30px;
      width: 32px;
      font-size: 14px;
      font-family: "Museo700";
      align-items: center;
      @media only screen and (min-width: 900px) {
        flex-direction: column;
        align-items: flex-start;
        color: white;
        padding: 28px 18px 28px 13px;
        font-size: 15px;
        width: 100%;
        flex: 1 0 auto;
      }
      @media only screen and (max-width: 899px) {
        background: transparent !important;
        opacity: 1 !important;
      }
      &__text {
        display: none;
        @media only screen and (min-width: 900px) {
          display: inline-block;
        }
      }
      &__icon {
        display: inline-block;
        margin-right: 10px;
        width: 12px;
        height: 15px;
        @media only screen and (min-width: 900px) {
          margin-right: 0px;
          margin-bottom: 5px;
        }
      }
      path,
      rect {
        fill: ${(props) => props.theme.highlightLightBlue};
        @media only screen and (min-width: 900px) {
          fill: ${(props) => props.theme.highlightBlue};
        }
      }
      &--active {
        path,
        rect,
        polygon {
          fill: ${(props) => props.theme.highlightBlue};
          @media only screen and (min-width: 900px) {
            fill: white;
          }
        }
        ~ .nav-item--disabled {
          pointer-events: none;

          @media only screen and (min-width: 900px) {
            display: none;
          }
        }
      }
      &--disabled {
        path,
        rect,
        polygon {
          fill: ${(props) => props.theme.highlightLightBlue};
        }
        @media only screen and (min-width: 900px) {
          path,
          rect,
          polygon {
            fill: ${(props) => props.theme.highlightBlue};
          }
        }
      }
    }
  }
`;

export default NavWrap;
