import React from 'react';
import styled from 'styled-components';
import breakpoint from '../lib/breakpoints';
import SVG from 'react-inlinesvg';

const StyledBackButton = styled.button`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.highlightBlue};
  position: absolute;
  top: 27px;
  left: 20px;
  z-index: 30;
  cursor: pointer;
  ${breakpoint.up('m')`
	display: none;
  `};
`;

function BackButton() {
  const goBack = function() {
    window.history.back();
  };
  return (
    <StyledBackButton type="button" onClick={goBack}>
      <SVG src={process.env.PUBLIC_URL + '/icons/arrow-left.svg'} />
    </StyledBackButton>
  );
}

export default BackButton;
