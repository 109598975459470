import React from "react";

function ContactInnerLocation() {
  return (
    <div>
      <h3>Visit our city campus</h3>
      <p>
        124 La Trobe Street, <br />
        Melbourne, Vic, 3000
      </p>
    </div>
  );
}

export default ContactInnerLocation;
