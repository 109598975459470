import React from "react";
import styled from "styled-components";
import SVG from "react-inlinesvg";

const StyledCard = styled.div`
  margin-top: 40px;
  background-color: white;
  @media only screen and (min-width: 800px) {
    flex: 1 0 1px;
  }
  h3 {
    font-family: "HelveticaNeue-Bold", Helvetica, Arial, sans-serif;
    font-size: 16px;
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 0px;
  }

  > .isvg svg {
    width: 50px;
    height: auto;
    @media only screen and (min-width: 900px) {
      width: 60px;
    }
  }

  &.card--border {
    @media only screen and (min-width: 900px) {
      box-shadow: 0 2px 7px 0 #ebebeb;
      padding: 30px;
      margin: 0 10px;
    }
    @media only screen and (min-width: 1400px) and (max-width: 1499px) {
      padding: 30px 20px;
    }
  }
  &.card--no-border {
    @media only screen and (min-width: 900px) {
      margin: 40px 0 0;
    }
    @media only screen and (min-width: 800px) and (max-width: 1199px) {
      padding: 0 20px;
    }
  }
  &.card--home {
    padding: 30px;
    margin: 0;
    flex: 1 0 1px;
    @media only screen and (min-width: 700px) {
      margin: 0 10px;
      box-shadow: 0 2px 7px 0 #ebebeb;
    }
    @media only screen and (min-width: 700px) and (max-width: 799px) {
      padding: 30px 20px;
    }
  }
`;
function Card({ children, type, icon, classes }) {
  return (
    <StyledCard className={[classes, type]}>
      <SVG src={`${process.env.PUBLIC_URL}/icons/icon-${icon}.svg`} />
      {children}
    </StyledCard>
  );
}

export default Card;
