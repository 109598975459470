/**
 * This class handles animations of interests page.
 *
 */
import anime from "animejs";

export default class interestsAnimation {
  constructor(animationService) {
    this.animationService = animationService;
    this.interestsPageId = "interests-page";
  }

  _hideInterestsPage() {
    this.animationService._hideItemById(this.interestsPageId);
  }

  _showInterestsPage() {
    this.animationService._showItemById(this.interestsPageId);
  }

  _getInterestsPageTiles() {
    return document.getElementsByClassName("interests-page-content-tile");
  }

  _initInterestsPageEnterAnimation(pageTransition = false) {
    // Set indexes based on their position in the animation queue.
    this.interestsPageFirstEnterAnimIndex = 0;
    this.interestsPageTilesEnterAnimIndex = 1;

    this.interestsPageEnterAnimQueue = [
      {
        animObject: anime.timeline({
          easing: "cubicBezier(0.3, 0, 0.3, 1)",
        }),
        addAnimation: this._addInterestsPageFirstAnimation.bind(this),
        pageTransition: pageTransition,
        // Dom is ready for the first animation
        domReady: true,
        began: false,
        added: false,
      },
      {
        animObject: anime.timeline({
          easing: "cubicBezier(0.3, 0, 0.3, 1)",
        }),
        addAnimation: this._addInterestsPageTilesAnimation.bind(this),
        domReady: false,
        began: false,
        added: false,
      },
    ];
  }

  runInterestsPageEnterAnimation(pageTransition = false) {
    // If it's not a page transition, init the animation object because it hasen't been already.
    if (!pageTransition) {
      this._initInterestsPageEnterAnimation();
    } else {
      anime.set("#interests-page-footer", { opacity: 1 });
      this._showInterestsPage();
    }
    this.animationService._tryToAddAnimation(
      this.interestsPageEnterAnimQueue,
      this.interestsPageFirstEnterAnimIndex
    );
  }

  addInterestTilesToInterestsPageEnterAnimation(success) {
    if (!success) {
      return;
    }
    // Exit if the animation has already been added
    if (
      this.interestsPageEnterAnimQueue[this.interestsPageTilesEnterAnimIndex]
        .added
    ) {
      return;
    }
    // The dom is ready
    this.interestsPageEnterAnimQueue[
      this.interestsPageTilesEnterAnimIndex
    ].domReady = true;
    // If an exit animation is running, exit
    if (this.animationService.exitAnimationRunning) {
      return;
    }
    this.animationService._tryToAddAnimation(
      this.interestsPageEnterAnimQueue,
      this.interestsPageTilesEnterAnimIndex
    );
  }

  _addInterestsPageTilesAnimation() {
    let beAddedAt =
      this.interestsPageEnterAnimQueue[this.interestsPageFirstEnterAnimIndex]
        .animObject.duration - 200;
    // If it's not early to add the animation
    if (
      this.interestsPageEnterAnimQueue[this.interestsPageFirstEnterAnimIndex]
        .animObject.currentTime >= beAddedAt
    ) {
      // Give the animation a small delay so it's visible
      this._createInterestTilesEnterAnimation(200);
      return;
    }
    // It's early to add the animation, set a timeout
    setTimeout(() => {
      this._createInterestTilesEnterAnimation();
    }, beAddedAt - this.interestsPageEnterAnimQueue[this.interestsPageFirstEnterAnimIndex].animObject.currentTime);
  }

  _getGroupedRows(tiles) {
    let tileRows = {};
    // for (let til of tiles) {
    //     let top = til.getClientRects()[0].top;
    //     if (!tileRows.hasOwnProperty(top)) {
    //         tileRows[top] = [];
    //     }
    //     tileRows[top].push(til);
    // }
    for (let i = 0; i < tiles.length; i++) {
      let top = tiles[i].getClientRects()[0].top;
      if (!tileRows.hasOwnProperty(top)) {
        tileRows[top] = [];
      }
      tileRows[top].push(tiles[i]);
    }
    return tileRows;
  }

  _createInterestTilesEnterAnimation(InitialDelay = 0) {
    let tiles = this._getInterestsPageTiles();
    // Group tiles in rows to be animataed one after another
    let tileRows = this._getGroupedRows(tiles);
    let offset = "0";
    for (let key in tileRows) {
      let inputObject = {
        targets: tileRows[key],
        translateY: [-25, 0],
        opacity: [0, 1],
        duration: 300,
        delay: InitialDelay,
      };
      // If first item, add complete callback
      if (offset === "0") {
        inputObject.complete = (anim) => {
          // If an exit animation is running, exit
          if (this.animationService.exitAnimationRunning) {
            return;
          }
          this.interestsPageEnterAnimQueue[
            this.interestsPageTilesEnterAnimIndex
          ].began = true;
          this.animationService._tryToAddAnimation(
            this.interestsPageEnterAnimQueue,
            this.interestsPageTilesEnterAnimIndex + 1
          );
        };
      }
      this.interestsPageEnterAnimQueue[
        this.interestsPageTilesEnterAnimIndex
      ].animObject.add(inputObject, offset);
      offset = "-=200";
      InitialDelay = 0;
    }
    // If it's a page transition, exit
    if (
      this.interestsPageEnterAnimQueue[this.interestsPageFirstEnterAnimIndex]
        .pageTransition
    ) {
      //anime.set('#interests-page-footer', {opacity: 1});
      return;
    }
    // First load, animate footer
    this.interestsPageEnterAnimQueue[
      this.interestsPageTilesEnterAnimIndex
    ].animObject.add(
      {
        targets: "#interests-page-footer",
        opacity: [0, 1],
        translateY: [25, 0],
        duration: 300,
      },
      "-=200"
    );
  }

  runInterestsPageExitAnimation(match) {
    // Set this flag, so no entering animation is added anymore
    this.animationService.exitAnimationRunning = true;
    // Init enter animation object
    this.animationService.animationsByPath[match.path].initEnterAnimation(true);
    // Hide entering page
    this.animationService.animationsByPath[match.path].hidePage();
    this.interestsPageExitAnime = anime.timeline({
      easing: "cubicBezier(0.3, 0, 0.3, 1)",
    });
    let tiles = this._getInterestsPageTiles();
    let tileRows = this._getGroupedRows(tiles);
    // Get reversed array of keys
    let tileRowKeys = Object.keys(tileRows).reverse();

    // interest tiles
    let offset = "0";
    tileRowKeys.forEach((key) => {
      this.interestsPageExitAnime.add(
        {
          targets: tileRows[key],
          translateY: [0, -25],
          opacity: [1, 0],
          duration: 300,
        },
        offset
      );
      offset = "-=200";
    });
    this.interestsPageExitAnime
      // P inside content container
      .add(
        {
          targets: "#interests-page-content-p",
          translateY: [0, -25],
          opacity: [1, 0],
          duration: 300,
        },
        "-=200"
      )
      // Page header
      .add({
        targets: "#interests-page-header-text",
        translateY: [0, -25],
        opacity: [1, 0],
        duration: 300,
      })
      // Nav bar - unhighlight the active item
      .add({
        targets: "#exit-vertical-nav-bar-interests",
        backgroundColor: this.animationService.textDark,
        color: "#fff",
        duration: 300,
      });

    // Run the new page's enter animation when exit is finished
    this.interestsPageExitAnime.finished.then(() => {
      this.animationService.exitAnimationRunning = false;
      this.animationService.animationsByPath[match.path].runEnterAnimation(
        true
      );
    });
  }

  endInterestsPageAnimation(node, done) {
    // If animation object doesn't exist, call done and exit
    if (!this.interestsPageExitAnime) {
      done();
      return;
    }
    // Call done when exit animation is finished
    this.interestsPageExitAnime.finished.then(() => {
      done();
    });
  }

  _addInterestsPageFirstAnimation() {
    if (
      this.interestsPageEnterAnimQueue[this.interestsPageFirstEnterAnimIndex]
        .pageTransition
    ) {
      this._createInterestsPageFirstAnimationOnTransition();
      return;
    }
    this._createInterestsPageFirstAnimation();
  }

  _createInterestsPageFirstAnimationOnTransition() {
    this.interestsPageEnterAnimQueue[
      this.interestsPageFirstEnterAnimIndex
    ].animObject
      // Nav bar - highlight the active item
      .add({
        targets: "#vertical-nav-bar-interests",
        opacity: 1,
        duration: 300,
        complete: (anim) => {
          // If an exit animation is running, exit
          if (this.animationService.exitAnimationRunning) {
            return;
          }
          this.interestsPageEnterAnimQueue[
            this.interestsPageFirstEnterAnimIndex
          ].began = true;
          this.animationService._tryToAddAnimation(
            this.interestsPageEnterAnimQueue,
            this.interestsPageFirstEnterAnimIndex + 1
          );
        },
      })
      .add(
        {
          targets: ".nav-item--active",
          backgroundColor: this.animationService.themeHighlightBlue,
          color: this.animationService.textDark,
          duration: 300,
        },
        "-=200"
      )
      // Page header at the same time as Nav bar highlight
      .add(
        {
          targets: "#interests-page-header-text",
          translateY: [-25, 0],
          opacity: [0, 1],
          duration: 300,
        },
        "-=300"
      )
      // P inside content container
      .add(
        {
          targets: "#interests-page-content-p",
          translateY: [-25, 0],
          opacity: [0, 1],
          duration: 300,
        },
        "-=200"
      );
  }

  _createInterestsPageFirstAnimation() {
    this.interestsPageEnterAnimQueue[
      this.interestsPageFirstEnterAnimIndex
    ].animObject
      // Nav bar - comes in
      .add({
        targets: "#vertical-nav-bar",
        translateX: ["-200%", "0%"],
        duration: 500,
        delay: 500,
        complete: (anim) => {
          // If an exit animation is running, exit
          if (this.animationService.exitAnimationRunning) {
            return;
          }
          this.interestsPageEnterAnimQueue[
            this.interestsPageFirstEnterAnimIndex
          ].began = true;
          this.animationService._tryToAddAnimation(
            this.interestsPageEnterAnimQueue,
            this.interestsPageFirstEnterAnimIndex + 1
          );
        },
      })
      // Nav bar - interests item comes in
      .add({
        targets: "#vertical-nav-bar-interests",
        opacity: 1,
        duration: 300,
      })
      // Nav bar - highlight the active item
      .add({
        targets: ".nav-item--active",
        backgroundColor: this.animationService.themeHighlightBlue,
        color: this.animationService.textDark,
        duration: 300,
      })
      // Page header and faq, search buttons. At the same time as Nav bar highlight
      .add(
        {
          targets: "#interests-page-header-text, #page-button-wrap",
          translateY: [-25, 0],
          opacity: [0, 1],
          duration: 300,
        },
        "-=300"
      )
      // content container
      .add(
        {
          targets: "#interests-page-content-card",
          scaleY: [0, 1],
          transformOrigin: "0% 0% 0%",
          duration: 400,
        },
        "-=200"
      )
      // P inside content container
      .add({
        targets: "#interests-page-content-p",
        translateY: [-25, 0],
        opacity: [0, 1],
        duration: 300,
      });
  }
}
