import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-apollo-hooks";
import gql from "graphql-tag";
import SVG from "react-inlinesvg";
import { Helmet } from "react-helmet";

import ContentWithSidebar from "../components/ContentWithSidebar";
import Sidebar from "../components/Sidebar";
import RelatedInterests from "../components/RelatedInterests";
import InterestsWithoutRelated from "../components/InterestsWithoutRelated";
import Main from "../components/Main";
import HeaderText from "../components/HeaderText";
import SectionBreak from "../components/SectionBreak";
import SubHeaderText from "../components/SubHeaderText";
import RelatedInterestsGrid from "../components/styles/RelatedInterestsGrid";
import InterestsGrid from "../components/styles/InterestsGrid";
import ContentCard from "../components/ContentCard";
import ContentCardInnerMax from "../components/ContentCardInnerMax";
import CoursesByInterest from "../components/CoursesByInterest";
import ContactWrap from "../components/ContactWrap";
import ContactIntro from "../components/ContactIntro";
import ContactCards from "../components/ContactCards";
import ContactCard from "../components/ContactCard";
import ContactInnerInfo from "../components/ContactInnerInfo";
import ContactInnerForm from "../components/ContactInnerForm";
import ContactInnerLocation from "../components/ContactInnerLocation";
import usePrevious from "../lib/usePrevious";

const INTEREST_QUERY = gql`
  query INTEREST_QUERY($slug: String!) {
    interest(where: { slug: $slug }) {
      id
      title
      slug
      relatedInterests(orderBy: title_ASC, first: 4) {
        id
        title
        slug
      }
    }
  }
`;

function InterestPage({
  match,
  itrsDataLoadCallback,
  itrsOtherInterestLoadCallback,
  itrsCourseLoadCallback,
}) {
  const { data, loading, error } = useQuery(INTEREST_QUERY, {
    variables: { slug: match.params.slug },
  });

  const [urlChanged, setUrlChanged] = useState(false);
  const prevUrl = usePrevious(match.url);
  useEffect(() => {
    // If URL is changed, set the urlChanged flag and return. The flag changes the animation-related ids in the dom so entering animations don't run
    if (prevUrl && prevUrl !== match.url) {
      setUrlChanged(true);
      return;
    }
    // If no error, call the callback function with true, otherwise with false
    let success = false;
    if (!error && data.hasOwnProperty("interest")) {
      success = true;
    }
    itrsDataLoadCallback(success);
  }, [prevUrl, match.url, error, data, itrsDataLoadCallback]);

  if (error) return "Error";
  return (
    <>
      {!loading && (
        <Helmet>
          <title>{data.interest.title} | RMIT Pathways</title>
          <meta
            name="description"
            content={`Select the ${data.interest.title} course you'd like to study from the options below, each course has a different journey to reach your chosen degree.`}
          />
        </Helmet>
      )}
      <ContentWithSidebar className="content-wrap">
        <Sidebar id="itrs-page-sidebar">
          <SubHeaderText
            id="itrs-page-sidebar-top-header"
            className="beta blue"
          >
            Related interest areas
          </SubHeaderText>
          <RelatedInterestsGrid id="itrs-page-related">
            {!loading && (
              <RelatedInterests interests={data.interest.relatedInterests} />
            )}
          </RelatedInterestsGrid>
          <SubHeaderText
            id="itrs-page-sidebar-other-header"
            className="beta blue"
          >
            Other interest areas
          </SubHeaderText>
          <InterestsGrid id="itrs-page-other">
            {!loading && (
              <InterestsWithoutRelated
                currentInterest={data.interest.title}
                interests={data.interest.relatedInterests}
                itrsOtherInterestLoadCallback={itrsOtherInterestLoadCallback}
              />
            )}
          </InterestsGrid>
        </Sidebar>
        <Main id="itrs-page">
          {loading && <HeaderText className="loading">...</HeaderText>}
          {!loading && (
            <HeaderText>
              <span
                id={
                  urlChanged
                    ? "exit-itrs-page-header-icon"
                    : "itrs-page-header-icon"
                }
                className="icon"
              >
                <SVG
                  className="lg"
                  src={`${process.env.PUBLIC_URL}/icons/icon-${data.interest.slug}.svg`}
                />
              </span>
              <span
                id={
                  urlChanged
                    ? "exit-itrs-page-header-text"
                    : "itrs-page-header-text"
                }
                className="text"
              >
                {`${data.interest.title} course options`}
              </span>
            </HeaderText>
          )}
          {/* 
        <PageButtonWrap>
          <FaqsButton />
          <SearchButton />
        </PageButtonWrap>
        */}
          <SectionBreak>
            <span>
              <SVG
                src={`${process.env.PUBLIC_URL}/icons/icon-step-2-nav-blue.svg`}
              />
            </span>
          </SectionBreak>
          <ContentCard>
            <ContentCardInnerMax>
              <CoursesByInterest
                interestSlug={match.params.slug}
                itrsCourseLoadCallback={itrsCourseLoadCallback}
                match={match}
              />
            </ContentCardInnerMax>
            <ContactWrap
              type="contact-wrap--footer"
              id="itrs-page-contact-container"
            >
              <ContactIntro icon="group-shapes">
                <h2>Contact us</h2>
                <p>
                  If you have any questions about pathways please contact
                  Study@RMIT. Our advisors are available with extended hours
                  during the Change of Preference period.
                </p>
              </ContactIntro>
              <ContactCards type="contact-cards--row">
                <ContactCard type="card--border" icon="communication">
                  <ContactInnerInfo />
                </ContactCard>
                <ContactCard type="card--border" icon="information-technology">
                  <ContactInnerForm />
                </ContactCard>
                <ContactCard type="card--border" icon="navy-global">
                  <ContactInnerLocation />
                </ContactCard>
              </ContactCards>
            </ContactWrap>
          </ContentCard>
        </Main>
      </ContentWithSidebar>
    </>
  );
}

InterestPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      slug: PropTypes.string.isRequired,
    }),
    //}).isRequired
  }),
};

export default InterestPage;
