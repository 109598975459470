import React from "react";
import styled from "styled-components";

const FooterNavWrap = styled.nav`
  width: 100%;
  .footer-nav {
    &__flex {
      display: flex;
      margin: 20px -10px 20px;
      width: 100%;
      justify-content: center;
      flex-wrap: wrap;
      @media only screen and (min-width: 1200px) {
        margin: 20px -20px 20px;
        justify-content: flex-start;
      }
      a {
        position: relative;
        padding: 5px 10px;
        color: white;
        font-family: "Museo500";
        font-size: 16px;
        text-decoration: underline;
        &:hover,
        &:focus {
          opacity: 0.8;
        }
        @media only screen and (min-width: 1200px) {
          padding: 0 20px;

          &:not(:last-child):after {
            content: "";
            height: 100%;
            width: 1px;
            border-left: 1px solid ${(props) => props.theme.highlightRed};
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            color: ${(props) => props.theme.highlightRed};
          }
        }
      }
    }
    &__disclaimer {
      p {
        color: white;
        font-size: 14px;
        text-align: center;
        margin-bottom: 0;
        @media only screen and (min-width: 1200px) {
          text-align: left;
        }
      }
    }
  }
`;

function FooterNav() {
  const date = new Date();
  return (
    <FooterNavWrap>
      <div className="footer-nav__flex">
        <a href="https://www.rmit.edu.au/utilities/disclaimer">Disclaimer</a>
        <a href="https://www.rmit.edu.au/utilities/terms">
          Terms &amp; Conditions
        </a>
        <a href="https://www.rmit.edu.au/utilities/privacy">Privacy</a>
        <a href="https://www.rmit.edu.au/utilities/accessibility">
          Accessibility
        </a>
        <a href="https://www.rmit.edu.au/utilities/website-feedback">
          Website feedback
        </a>
        <a href="https://www.rmit.edu.au/">RMIT Website</a>
      </div>
      <div className="footer-nav__disclaimer">
        <p>
          Copyright © {date.getFullYear()} RMIT University ABN 49 781 030 034 |
          CRICOS provider number: 00122A | RTO Code: 3046 | Open Universities
          Australia
        </p>
      </div>
    </FooterNavWrap>
  );
}
export default FooterNav;
