import React from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";

import ScrollToTop from "./ScrollToTop";
import Theme from "../Theme";
import FaqsModal from "./FaqsModal";
import SearchModal from "./SearchModal";
import StudyLevelModal from "./StudyLevelModal";
import PageWrap from "./PageWrap";
import HomePage from "../pages/HomePage";
import Error404Page from "../pages/Error404Page";
import PathwayStepsRoot from "./PathwayStepsRoot";

export default class AppRouter extends React.Component {
  constructor(props) {
    super(props);
    this.pathwayStepsPaths = [
      "/interests",
      "/study-level",
      "/interest/:slug",
      "/course/:codeUnique",
      "/pathway/:slug",
    ];
  }

  render() {
    return (
      <Router>
        <ScrollToTop>
          <Theme>
            <FaqsModal />
            <SearchModal />
            <StudyLevelModal />
            <PageWrap error={Route}>
              <Switch>
                <Route path="/" exact component={HomePage} />
                <Route
                  path={this.pathwayStepsPaths}
                  component={PathwayStepsRoot}
                />
                <Route path="*" component={Error404Page} />
              </Switch>
            </PageWrap>
          </Theme>
        </ScrollToTop>
      </Router>
    );
  }
}
