import styled from "styled-components";

const ModalGridBanner = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: [first] 3vw [content-start] minmax(auto, 1070px) [content-end] 3vw [end];
  @media only screen and (min-width: 1200px) {
    grid-template-columns:
      [first] 3vw [content-start] minmax(auto, 2fr) [content-aside] minmax(
        auto,
        1fr
      )
      [aside-end] 3vw [end];
    grid-gap: 0 1.5vw;
  }
  @media only screen and (min-width: 1400px) {
    grid-template-columns:
      [first] minmax(auto, 6vw) [content-start] minmax(auto, 1070px)
      [content-aside] minmax(auto, 550px) [aside-end] minmax(auto, 1.5vw) [end];
    grid-gap: 0 3vw;
  }
  grid-column: first / end;
  position: relative;
  grid-row-start: 2;
  &:before {
    content: "";
    width: 100%;
    height: 100%;
    background: ${(props) => props.theme.sidebarBg};
    position: absolute;
    top: 0;
    right: 0;
    @media only screen and (min-width: 1400px) {
      width: calc(100% - 3vw);
    }
  }
`;

export default ModalGridBanner;
