import React from "react";

function ContactInnerInfo() {
  return (
    <div>
      <h3>Contact hours - AEST</h3>
      <p>Monday to Thursday: 9am - 5pm</p>
      <p>Friday: 10am - 5pm</p>
      <p>(Excluding Public Holidays)</p>
    </div>
  );
}

export default ContactInnerInfo;
