import styled from "styled-components";

export const ContentBlockWrap = styled.div`
  position: relative;
  margin: 40px 20px 50px;
  @media only screen and (min-width: 700px) {
    margin: 100px 50px 0;
  }
  @media only screen and (min-width: 900px) {
    width: 80%;
    max-width: 1000px;
    margin: 100px auto 0;
  }
`;
export const StyledContentBlockItem = styled.div`
  width: 100%;
  display: grid;
  grid-template-areas:
    "image"
    "text";
  gap: 40px;
  padding: 0;
  @media only screen and (min-width: 700px) {
    grid-template-columns: minmax(40%, 1fr) minmax(40%, 1fr);
    ${(props) =>
      props.imagePos === "left"
        ? 'grid-template-areas: "image text";'
        : 'grid-template-areas: "text image";'}
    /* gap: 0px 50px; */
    padding: 0 0 100px 0;
  }
  @media only screen and (min-width: 900px) {
    gap: 0 50px;
  }
  @media only screen and (min-width: 1200px) {
    gap: 0 100px;
    padding: 0 0 150px 0;
  }
`;

export const ContentBlockImageWrap = styled.div`
  grid-area: image;
  position: relative;
  &:before {
    content: "";
    display: block;
    position: relative;
    @media only screen and (min-width: 700px) {
      padding-top: 140%;
    }
  }
  > div {
    @media only screen and (min-width: 700px) {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      overflow: hidden;
    }
  }
`;
export const StyledContentBlockImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
  &.anim-in {
    .content-block__foreground {
      transform: translateY(0px);
      transition: transform 400ms cubic-bezier(0.3, 0, 0.3, 1);
    }
  }
`;
export const ImageForeground = styled.img`
  width: 100%;
  height: auto;
  margin-top: auto;
  margin-bottom: 0;
  vertical-align: bottom;
  z-index: 1;
  transition: transform 400ms cubic-bezier(0.3, 0, 0.3, 1);
`;

export const ContentBlockTextWrap = styled.div`
  grid-area: text;
  position: relative;
  > div {
    height: 100%;
  }

  h2 {
    margin-bottom: 15px;
    padding: 0 0;
    margin: 0.5em 0 0.5em 0;
    &:before {
      top: -1em;
    }
  }
  p {
    font-family: "HelveticaNeue-Medium", Helvetica, Arial, sans-serif;
    font-weight: 300;
    line-height: 1.4;
  }
  .chevron-link {
    @media only screen and (min-width: 900px) {
      margin-top: 10px;
    }
  }
`;

export const AnimFadeDown = styled.div`
  &.fade-down-item {
    opacity: 0;
    transform: translateY(-25px);
    transition: all 400ms 150ms cubic-bezier(0.3, 0, 0.3, 1);
    &--in {
      opacity: 1;
      transform: translateY(0px);
    }
  }
`;

export const ContentBlockImage = ({ progress, children }) => {
  return (
    <StyledContentBlockImage className={progress > 0 && "anim-in"}>
      {children}
    </StyledContentBlockImage>
  );
};

export const ContentBlockItem = ({ imagePos, children }) => {
  return (
    <StyledContentBlockItem imagePos={imagePos}>
      {children}
    </StyledContentBlockItem>
  );
};
