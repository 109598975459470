import React from "react";
import styled from "styled-components";

const StyledCardListDetails = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
  flex-direction: column;
  @media screen and (min-width: 1200px) {
    flex-direction: row;
    align-items: center;
  }
`;

export const StudyLevelTag = styled.span`
  padding: 4px 12px;
  border-radius: 40px;
  font-size: 14px;
  margin-bottom: 8px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  position: relative;
  min-width: max-content;
  text-align: center;
  justify-content: center;
  @media screen and (min-width: 1200px) {
    margin-bottom: 0;
    margin-right: 12px;
  }
`;

export const CardListDetails = ({ children }) => {
  return <StyledCardListDetails>{children}</StyledCardListDetails>;
};
