import styled from "styled-components";

const HomeHeroWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 60px 6vw 80px;
  position: relative;
  justify-content: center;
  @media only screen and (min-width: 600px) {
    padding: 10vh 6vw 15vh;
  }
  @media only screen and (min-width: 1200px) {
    padding: 10vh 6vw 10vh;
  }
  &.hero__wrap--error {
    min-height: 70vh;
    padding: 100px 6vw 80px;
    h3 {
      padding-left: 40px;
      padding-right: 40px;
    }
    @media only screen and (min-width: 600px) {
      padding: 10vh 6vw 15vh;
    }
    @media only screen and (min-width: 900px) {
      min-height: 100vh;
    }
    @media only screen and (min-width: 1200px) {
      padding: 10vh 6vw 10vh;
    }
  }
  &:before {
    content: "";
    height: 0%;
    display: inline-block;
    background-image: linear-gradient(
      180deg,
      #ebfaff 0%,
      rgb(211, 244, 255, 0) 84%
    );
    width: 100%;
    position: absolute;
    top: -100px;
    right: 0;
    z-index: -10;
    transition: all 1000ms 600ms ease-in-out;
  }
  > * {
    margin: 0 auto;
    text-align: center;
    z-index: 1;
  }
  h1 {
    font-family: "Museo700", "Century Gothic", CenturyGothic, AppleGothic, Arial,
      Helvetica, sans-serif;
    line-height: 1.2;
    max-width: 320px;
    font-size: 28px;
    margin: 0 auto;
    @media only screen and (min-width: 600px) {
      line-height: 1;
      font-size: 42px;
      max-width: 540px;
    }
    @media only screen and (min-width: 900px) {
      line-height: 1;
      font-size: 70px;
      max-width: 740px;
    }
  }
  h2 {
    max-width: 740px;
    margin-top:15px;
    margin-left: auto;
    margin-right: auto;

    @media only screen and (min-width: 900px) {
      margin-top: 3vw;
      font-size: 26px;
      line-height: 32px;
    }
  }

  h3 {
    margin-top: 15px;
    margin-bottom: 30px;
    color: ${(props) => props.theme.textPrimary};
    font-family: "HelveticaNeue-Medium", Helvetica, Arial, sans-serif;
    font-weight: 300;
    @media only screen and (min-width: 900px) {
      margin-top: 1.5vw;
      margin-bottom: 3vw;
    }
  }
  p {
    margin-top: 15px;
    @media only screen and (min-width: 600px) {
      margin-top: 1.5vw;
    }
  }
  .scroll {
    margin-top: 40px;
    @media only screen and (min-width: 600px) {
      margin-top: 50px;
    }
    .text {
      margin-top: 0px;
      font-size: 12px;
    }
  }
`;

export default HomeHeroWrap;
