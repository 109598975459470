import React, { useState } from "react";
import SVG from "react-inlinesvg";
import styled from "styled-components";
import GuaranteedPathwaysInfoLink from "./GuaranteedPathwaysInfoLink.js";

const StyledNotificationWrapper = styled.div`
  width: 100%;
  padding: 0 25px;
  background-color: rgba(0, 0, 84, 0.05);
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  @media only screen and (min-width: 900px) {
    padding: 0 25px;
  }
`;
const StyledCloseNotification = styled.button`
  position: absolute;
  top: 0px;
  right: 0px;
  background: transparent;
  border: none;
  padding: 25px;
  color: ${(props) => props.theme.highlightRed};
  cursor: pointer;
  &:hover,
  &:focus {
    color: ${(props) => props.theme.highlightRedActive};
  }
`;
const StyledNotificationLabel = styled.div`
  margin-top: 25px;
  margin-right: 25px;
  width: 220px;
  display: flex;
  flex: 1 0 100%;
  align-items: center;
  background: ${(props) => props.theme.highlightRed};
  width: 100%;
  border-radius: 15px;
  height: 50px;
  max-width: 223px;
  color: white;
  @media only screen and (min-width: 900px) {
    flex: 0 1 220px;
    margin-top: 25px;
  }
  span {
    width: 100%;
    height: 100%;
    color: white;
  }
`;

const StyledNotificationLabelOne = styled(StyledNotificationLabel)`
  @media only screen and (min-width: 900px) {
    position: absolute;
    bottom: 25px;
    right: 25px;
    margin-right: 0;
  }
`;
const StyledNotificationText = styled.div`
  padding: 15px 0 25px;
  flex: 1 0 100%;
  h4 {
    margin-top: 0;
    margin-bottom: 10px;
    line-height: 1.4;
  }
  p {
    margin-bottom: 0px;
  }
  .chevron-link {
    margin-top: 10px;
    text-align: left;
    .isvg {
      top: 0px;
      transform: none;
    }
  }
`;
const StyledNotificationTextHalf = styled(StyledNotificationText)`
  @media only screen and (min-width: 900px) {
    padding: 25px 50px 25px 0;
    flex: 1 0 200px;
  }
`;
const StyledNotificationTextFull = styled(StyledNotificationText)`
  @media only screen and (min-width: 900px) {
    padding: 25px 0;
    flex: 1 0 200px;
    padding-right: 260px;
  }
`;

export function GuaranteedPathwaysNotificationSimple({ id }) {
  const [notificationHidden, setNotificationHidden] = useState(false);
  return (
    !notificationHidden && (
      <StyledNotificationWrapper id={id}>
        <StyledNotificationLabel>
          <SVG src={process.env.PUBLIC_URL + "/icons/icon-gp.svg"} />
        </StyledNotificationLabel>

        <StyledNotificationTextHalf>
          <p>Look for this symbol to choose a Guaranteed Pathway</p>
          <p>
            <GuaranteedPathwaysInfoLink linkText="Learn more about Guaranteed Pathways" />
          </p>
        </StyledNotificationTextHalf>
        <StyledCloseNotification onClick={() => setNotificationHidden(true)}>
          <SVG src={process.env.PUBLIC_URL + "/icons/icon-close.svg"} />
        </StyledCloseNotification>
      </StyledNotificationWrapper>
    )
  );
}
export function GuaranteedPathwaysNotification({ id }) {
  const [notificationHidden, setNotificationHidden] = useState(false);
  return (
    !notificationHidden && (
      <StyledNotificationWrapper id={id}>
        <StyledNotificationLabelOne>
          <SVG src={process.env.PUBLIC_URL + "/icons/icon-gp.svg"} />
        </StyledNotificationLabelOne>

        <StyledNotificationTextFull>
          <h4>
            What is the difference between Guaranteed Pathways and Other
            Pathways?
          </h4>
          <p>
            Guaranteed Pathways allow you to secure your place in your goal
            degree when you enrol in the initial level of study, while other
            pathways require you to apply to your goal degree after successfully
            completing your initial qualification.
          </p>
          <p>
            <GuaranteedPathwaysInfoLink linkText="Learn more about Guaranteed Pathways" />
          </p>
        </StyledNotificationTextFull>
        <StyledCloseNotification onClick={() => setNotificationHidden(true)}>
          <SVG src={process.env.PUBLIC_URL + "/icons/icon-close.svg"} />
        </StyledCloseNotification>
      </StyledNotificationWrapper>
    )
  );
}
