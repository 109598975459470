import React from 'react';
import { Link } from 'react-router-dom';
import { Controller, Scene } from 'react-scrollmagic';
import Button from './Button';
import Error404Wrap from './styles/HomeHeroWrap';
import Error404Inner from './styles/HomeHeroInner';
import styled, {keyframes} from 'styled-components';

const button404 = keyframes`
  0%  { 
    transform: scale(0.95,1.05) translateY(-1000px); 
    opacity: 0;
    }
  42% { 
    transform: scale(0.95,1.05) translateY(0);  
    opacity: 1; 
    }
  45% { 
    transform: scale(1.1,0.9) translateY(0) ;  
    opacity: 1; 
    }
  52% { 
    transform: scale(1,1) translateY(-60px) ;  
    opacity: 1; 
  }
  57% { 
    transform: scale(1,1) translateY(-80px) ;  
    opacity: 1; 
    }
  67% { 
    transform: scale(1,1) translateY(-100px) ;  
    opacity: 1; 
  }
  77% { 
    transform: scale(1,1) translateY(-80px) ;  
    opacity: 1; 
  }
  82% { 
    transform: scale(1,1) translateY(-60px) ;  
    opacity: 1; 
  }
  87% { 
    transform: scale(1.1,0.9) translateY(0px) ;  
    opacity: 1; 
    }
  92% { 
    transform: scale(1,1.1) translateY(0px) ;  
    opacity: 1; 
    }
  100% { 
    transform: scale(1,1) translateY(0);  
    opacity: 1; 
    }
`;
const grow404 = keyframes`
  0%  { 
    width: 50px;
    }
  100% { 
    width: 280px;
    }
`;

const StyledError404Inner = styled(Error404Inner)`
	h3 {
		max-width: 700px;
		line-height: 1.4;
	}
	&.fade-down__items--in .anim__button {
		animation-name: ${button404}, ${grow404};
		animation-duration: 1200ms, 550ms;
        animation-delay: 550ms, 1600ms;
        animation-timing-function: linear, ease-out;
        animation-fill-mode: forwards, forwards;
		span {
			width: 230px;
		}
		max-width: none;
	}
`

function Error404 () {
	return (
		<Error404Wrap id="trigger__error" className="hero__wrap hero__wrap--error">
			<Controller>
				<Scene
					triggerElement={'#trigger__error'}
					reverse={false}
					triggerHook={0}
					offset="-100"
					duration="100%"
				>
					{(progress, event) => (
						<StyledError404Inner
							className={[
								'fade-down__items',
								progress > 0 ? 'fade-down__items--in' : ''
							]}
						>
							<h1 className="alpha alpha--404 fade-down__item fade-down__item--1">404</h1>
							<h2 className="beta fade-down__item fade-down__item--2">Page not found</h2>
							<h3 className="delta fade-down__item fade-down__item--3">
							Sorry, we can't find the page you're looking for. You may have mistyped the web address or a link could be broken.</h3>
							<Link to="/">
								<Button className="red anim__button">
									<span className="text">Return to Pathways homepage</span>
								</Button>
							</Link>
							<p className="para fade-down__item fade-down__item--4">
								or{' '}
								<Link to="/interests">select an interest area to explore our courses </Link>
							</p>
						</StyledError404Inner>
					)}
				</Scene>
			</Controller>
		</Error404Wrap>
	);
}

export default Error404;
