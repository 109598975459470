import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import gql from "graphql-tag";
import styled from "styled-components";

const FAQS_ACTIVE_QUERY = gql`
  query {
    faqsActive @client
  }
`;

const SEARCH_ACTIVE_QUERY = gql`
  query {
    searchActive @client
  }
`;

const STUDY_LEVEL_MODAL_ACTIVE_QUERY = gql`
  query {
    studyLevelModalActive @client
  }
`;

const StyledPageWrap = styled.div`
  &.internal-page {
    display: grid;
    grid-template-rows: 180px auto;
    grid-gap: 50px;
    grid-template-areas:
      "nav"
      "main"
      "footer";
    min-height: 100vh;
    @media only screen and (min-width: 700px) {
      grid-template-rows: 53px auto;
      grid-gap: 50px;
    }
    @media only screen and (min-width: 900px) {
      grid-template-columns: 95px auto;
      grid-template-rows: 80px auto;
      grid-gap: 0;
      grid-template-areas:
        "nav main"
        "nav main"
        "nav footer";
    }
  }
  &.modal-active {
    position: fixed;
    width: 100%;
  }
`;

function PageWrap({ location, children }) {
  const faqsActiveQuery = useQuery(FAQS_ACTIVE_QUERY);
  const searchActiveQuery = useQuery(SEARCH_ACTIVE_QUERY);
  const studyLevelModalActiveQuery = useQuery(STUDY_LEVEL_MODAL_ACTIVE_QUERY);
  return (
    <StyledPageWrap
      className={`${
        location.pathname !== "/"
          ? "page-wrap internal-page"
          : "page-wrap homepage"
      }
      ${
        faqsActiveQuery.data.faqsActive ||
        searchActiveQuery.data.searchActive ||
        studyLevelModalActiveQuery.data.studyLevelModalActive
          ? "modal-active"
          : ""
      }
      `}
    >
      {children}
    </StyledPageWrap>
  );
}

export default withRouter(PageWrap);
