import React from 'react';
import styled from 'styled-components';

const StyledSidebar = styled.aside`
  min-height: 100vh;
  background: ${props => props.theme.sidebarBg};
  padding: 210px 20px;
  display: none;

  @media only screen and (min-width: 1200px) {
    display: inline-block;
    padding: 210px 25px;
  }
`;

const SidebarInner = styled.div`
  width: 270px;
  margin: 0 auto;
`;
function Sidebar({ children, id }) {
  return (
    <StyledSidebar id={id}>
      <SidebarInner>{children}</SidebarInner>
    </StyledSidebar>
  );
}

export default Sidebar;
