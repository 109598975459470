import React from "react";
import PropTypes from "prop-types";

import SideNavInterestTile from "./SideNavInterestTile";

function RelatedInterests({ interests }) {
  return (
    <>
      {interests &&
        interests.map((interest) => (
          <SideNavInterestTile
            key={interest.id}
            interest={interest}
            type="flat"
            color="blue"
          />
        ))}
    </>
  );
}

RelatedInterests.propTypes = {
  interests: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    })
  ).isRequired,
};

export default RelatedInterests;
